import React, { useState, useEffect, useContext } from "react";
import { Navbar } from "react-bootstrap";
import { useQuery } from "../../hooks";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { ContextData } from '../../components/appsession';
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import { DropContext } from "../../pages/planner/planner";
import ProgressBar from "react-bootstrap/ProgressBar";
import "@progress/kendo-theme-default/dist/all.css";
import { sendPushNotification, updateSelectedPlanningProfileOfUser } from "../../services/userService";
import { Link } from "@mui/material";
import { toast } from "react-toastify";
import { getTrailerWashWorkOrdersforplanningboard } from "../../services/tankWashService";
import filterOperators from '../../utils/filterOperators';


const TankwashPage = (props) => {
  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);

  
  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const context = useContext(DropContext);

  // const { selectedPlanningProfile } = useContext(DropContext);

  const [columnorder, setcolumnorder] = useState([]);
  const [columnorderfordetail, setcolumnorderfordetail] = useState([]);
  const query = useQuery();
  const terminalid = query.get("terminal_id");
  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [orders, setorders] = useState([]);
  const [sortData, setSortData] = useState([{ field: "wash_id", dir: "asc" }]);
  const _export = React.useRef(null);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 25,
    sort: [
      {
        field: "",
        dir: "asc",
      },
    ],
  });
  // const [dataResult, setDataResult] = React.useState(
  //   process(orders, dataState)
  // );
  const [dataResult, setDataResult] = useState();


  const exportExport = async () => {
    try {
      // let payload = { terminal_id: context.selectedPlanningProfile.terminal_id }
      let payload = { terminal_id: terminalid }
      const search = await getTrailerWashWorkOrdersforplanningboard(payload);
      if (_export.current !== null) {
        _export.current.save(search);
      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('There is a problem in exporting trailers tab data.', { autoClose: false } );
      }
    }
  };


  let columns = [
    { id: 0, name: "expanded", title: "" },
    { id: 1, name: "wash_location_id", title: "Wash Location Id", width: "280px" },
    { id: 2, name: "wash_id", title: "Wash Id", width: "280px" },
    { id: 3, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 4, name: "commodity_id", title: "Commodity Id", width: "280px" },
    { id: 5, name: "int_wash_code", title: "Int Wash Code", width: "280px" },
    { id: 6, name: "ext_wash_code", title: "Ext Wash Code", width: "280px" },
    { id: 7, name: "needed_date_utc", title: "Needed Date", width: "280px" },
    { id: 8, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    { id: 9, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
    { id: 10, name: "company_id", title: "Company Id", width: "280px" },
    { id: 11, name: "charge_type", title: "Charge Type", width: "280px" },
    { id: 12, name: "customer_id", title: "Customer Id", width: "280px" },
    { id: 13, name: "driver_id", title: "Driver Id", width: "280px" },
    { id: 14, name: "entered_user_id", title: "Entered User Id", width: "280px" },
    { id: 15, name: "equip_type", title: "Equip Type", width: "280px" },
    { id: 16, name: "ext_wash_amount", title: "Ext Wash Amount", width: "280px" },
    { id: 17, name: "ext_wash_amount_c", title: "Ext_Wash_Amount C", width: "280px" },
    { id: 18, name: "ext_wash_amount_d", title: "Ext wash Amount D", width: "280px" },
    { id: 19, name: "ext_wash_amount_n", title: "Ext wash Amount N", width: "280px" },
    { id: 20, name: "ext_wash_amount_r", title: "Ext wash Amount R", width: "280px" },
    { id: 21, name: "hazmat_code", title: "Hazmat Code", width: "280px" },
    { id: 22, name: "heel", title: "Heel", width: "280px" },
    { id: 23, name: "heel_unit_type", title: "Heel Unit Type", width: "280px" },
    { id: 24, name: "int_wash_amount", title: "Int Wash Amount", width: "280px" },
    { id: 25, name: "int_wash_amount_c", title: "Int Wash Amount C", width: "280px" },
    { id: 26, name: "int_wash_amount_d", title: "Int Wash Amount D", width: "280px" },
    { id: 27, name: "int_wash_amount_n", title: "Int Wash Amount N", width: "280px" },
    { id: 28, name: "int_wash_amount_r", title: "Int Wash Amount R", width: "280px" },
    { id: 29, name: "intra_co_seg_code", title: "Intra Co Seg Code", width: "280px" },
    { id: 30, name: "invoice_po_date", title: "Invoice Po Date", width: "280px" },
    { id: 31, name: "invoice_po_num", title: "Invoice Po Num", width: "280px" },
    { id: 32, name: "movement_id", title: "Movement Id", width: "280px" },
    { id: 33, name: "order_id", title: "Order Id", width: "280px" },
    { id: 34, name: "other_equip", title: "Other Equip", width: "280px" },
    { id: 35, name: "ready_to_xfer", title: "Ready To Xfer", width: "280px" },
    { id: 36, name: "seg_alloc_code", title: "Seg Alloc Code", width: "280px" },
    { id: 37, name: "tractor_id", title: "Tractor Id", width: "280px" },
    { id: 38, name: "vendor_id", title: "Vendor Id", width: "280px" },
    { id: 39, name: "void", title: "Void", width: "280px" },
    { id: 40, name: "voucher_id", title: "Voucher Id", width: "280px" },
    { id: 41, name: "wash_date_utc", title: "Wash Date", width: "280px" },
    { id: 42, name: "wash_location_id", title: "Wash Location Id", width: "280px" },
    { id: 43, name: "washed_for", title: "Washed For", width: "280px" },
    { id: 44, name: "tank_wash_assigned", title: "Tank Wash Assigned", width: "280px" },
    { id: 45, name: "trailer_wash_assigned", title: "Trailer Wash Assigned", width: "280px" },
    { id: 46, name: "commodity_desc2", title: "Commodity Desc2", width: "280px" },
    { id: 47, name: "text", title: "Text", width: "280px" },
    { id: 48, name: "bay", title: "Bay", width: "280px" },
    { id: 49, name: "source", title: "Source", width: "280px" },
    { id: 50, name: "resource", title: "Resource", width: "280px" },
    { id: 51, name: "history", title: "History", width: "280px" },
    { id: 52, name: "start_time_utc", title: "Start Time Utc", width: "280px" },
    { id: 53, name: "end_time_utc", title: "End Time Utc", width: "280px" },
  ];

  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  const handleClear = () => {
    setDataState({
      ...dataState,
      filter: {
        logic: "and",
        filters: [],
      },
      skip: 0,
      take: 25,
    });

    const clearedData = process(orders, {
      ...dataState,
      filter: { logic: "and", filters: [] },
    });
    setDataResult(clearedData);
  
    setSortData([]);
  };

  useEffect(() => {
    const dataResultLocalStorage = JSON.parse(localStorage.getItem('dataResult'));
    setDataResult(dataResultLocalStorage);
    // setDataResult(process(orders, dataResultLocalStorage));

    if (userData?.userpreferences?.tankwashtabsequence && userData?.userpreferences?.tankwashtabsequence.length > 0) {
      const filteredColumns = userData?.userpreferences?.tankwashtabsequence.filter((col) => col.name !== "Action");
      setcolumnorder(filteredColumns);
      setcolumnorderfordetail(filteredColumns);
    }
    else {
      setcolumnorder(columns);
      setcolumnorderfordetail(columns);
    };

    // handlecallTankwash();
  }, [])

  const handlecallTankwash = async () => {S
    try {
      // settabSelected("tankwash");
      let payload = { terminal_id: selectedPlanningProfile.terminal_id }
      let res = await getTrailerWashWorkOrdersforplanningboard(payload);
      // setAllTankwash(res);
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
        toast.error('Something went wrong while calling tankwash', { autoClose: false } );
      }
    }
  }

  const columnReorderChange = async function (event) {
    const newTankwash = [];
    event.target._columns.forEach((it) => {
      if (it.field !== "expanded" && it.field !== 'Action') {
        newTankwash.push({
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width,
        });
      }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    if (userData.userpreferences) {
      // If userpreferences has already tankwashtabsequence data and we are changing sequence.
      if (userData.userpreferences.tankwashtabsequence) {
        payload.userpreferences.tankwashtabsequence = newTankwash;
      } else {
        // If userpreferences has already some data, but not tankwashtabsequence data
        payload.userpreferences.tankwashtabsequence = newTankwash;
      }
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = userData.userpreferences.trailertabsequence;
      }
      // note: might have to do same for orders here
      if (userData.userpreferences.currentlySelectedPlanningProfile) {
        payload.userpreferences.currentlySelectedPlanningProfile = userData.userpreferences.currentlySelectedPlanningProfile;
      }
    } else {
      // If userpreferences has not any data.
      payload.userpreferences.tankwashtabsequence = newTankwash;
    }

    try {
      setcolumnorderfordetail(newTankwash);
      const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
      await getUserInfoMSGraph(userUniqueId);
    } catch (err) {
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  return (
    <div id="wrapper">
     <Navbar expand="lg" variant="light" bg="light" className="navbar-custom">
        <div className="container-fluid">	
          <div className="logo_logout_wrapper">	
            <div className="logo-box df">	
              <img	
                className={"back_icon hide_left_icon"}	
              />	
              <p className="logo_font">	
                <Link to="/planner">BULKMATIC TRANSPORT</Link>	
              </p>	
            </div>	
            <div className="signoutsection">	
              <button	
                type="button"	
                onClick={() => window.close()}	
                className="btn_signout"	
              >	
                <i className="fa fa-sign-out log_out_icon"></i>Close Window
              </button>	
            </div>	
          </div>	

          <div className="clearfix"></div>	
        </div>	
      </Navbar>	

      <div className="row df  adjustrow mt_30">	
        <div className="col-xl-12">	
          <div className="card special_bg">	
            <div className="card-body">	
              <div className="row top_adjust">	
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>	
                  <div className="col-md-2">	
                    <h2 className="text-light">Tankwash</h2>	
                  </div>	
                  <div className="tab_inside_section_top_right">	
                  </div>	
                </div>	

              </div>	
            </div>	
          </div>	
        </div>	
      </div>
      <div className="row adjustrow1">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body ">
              <div className="table-responsive">
                {/* {!isDataLoaded && ( */}
                  {/* <div className="loading-overlay active" style={{ fontSize: "18px", top: "70px", height: "80%" }}>
                    <i className="fa fa-spinner fa-spin spinner-icon"></i>Loading...
                  </div> */}
                {/* )} */}
                <div className="tab_inside_section">
                  <div className="tab_inside_section_top">
                    <div className="tab_inside_section_top_left">
                    </div>
                    <div className="tab_inside_section_top_right">
                      <div className='ordertabdropdown'>
                        <button
                          title="Export Excel"
                          className='btn_white_sm ml_10'
                          onClick={exportExport}
                        >
                          Export to Excel
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn_white_sm ml_10 '
                          onClick={handleClear}
                        >
                          Clear
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn_white_sm ml_10'
                          // onClick={handleRefresh}
                        >
                          Refresh
                        </button>

                      </div>
                    </div>
                  </div>
                  <div className="tab_content">
                    <LocalizationProvider language={currentLocale.language}>
                      <IntlProvider locale={currentLocale.locale}>
                        <ExcelExport
                          ref={_export}
                        >
                          {
                            columnorderfordetail?.map((it) =>
                              <ExcelExportColumn
                                key={it.id}
                                field={it.name}
                                title={it.title}
                                width="200px"
                              />
                            )
                          }
                        </ExcelExport>
                        <Grid
                          // detail={DetailComponent}
                          filter={dataState.filter}
                          filterable={true}
                          groupable={true}
                          reorderable={true}
                          filterOperators={filterOperators}
                          expandField="expanded"
                          sort={dataState.sort}
                          sortable={true}
                          pageable={{
                            pageSizes: [25, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10,
                          }}

                          resizable={true}
                          skip={dataState.skip}
                          take={dataState.take}
                          data={dataResult}
                          onDataStateChange={dataStateChange}
                          onColumnReorder={columnReorderChange}
                        >
                          {/* {
                            columns.map((it) => {
                              if (it.name == 'Trailer_ID') {
                                return <GridColumn
                                  key={it.id}
                                  field={it.name}
                                  title={it.title}
                                  width="200px"
                                  cell={(e) => {
                                    return (
                                      <td>
                                        <a href={`${window.location.origin + '/trailers' + '?' + 'trailerId' + '=' + e.dataItem[e.field]}`} target='_blank' style={{ textDecoration: "underline" }}>{e.dataItem[e.field]}</a>
                                      </td>
                                    );
                                  }}
                                />
                              } else {
                                return <GridColumn
                                  key={it.id}
                                  field={it.name}
                                  title={it.title}
                                  width="200px"
                                />
                              }
                            }
                            )
                          } */}
                          <GridToolbar></GridToolbar>
                          {/* {columnorder.slice(1).map((it) => ( */}
                          {columnorder.map((it) => (
                            <GridColumn
                              key={it.id}
                              field={it.name}
                              title={it.title}
                              width={it.width}
                            />
                          ))}
                        </Grid>
                      </IntlProvider>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        // show={showTerminalModal}
        // onHide={() => terminalModalHideHandler()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter margin-top-bottom" className='text-bold'>
            Change Terminal Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='meterial_autocomplete'>
            <p >Select Terminal:</p>
            <Autocomplete
              id="combo-box-demo"
              options={allTerminals}
              getOptionLabel={(option) => `${option.full_terminal_name}`}
              value={selectedTerminal}
              onChange={handleTerminalChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose a Terminal..."
                  variant="outlined"
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn_blue btn-blue">Change</Button>
          <Button className="btn_blue btn-blue">Cancel</Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default TankwashPage;
