import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import axios from "axios";
// import BlukmatikLogo from "../../bulkmatic-planning-frontend/src/assets/images/Bulkmatic Solutions Logo.png";
import BlukmatikLogo from "../src/assets/images/Bulkmatic Solutions Logo.png";

import "./styles/App.css";
import AppRoute from "./AppRoute.js";
import AppSession from "./components/appsession";
import { BaseConfig } from "./baseConfig";
import { loginRequest } from "./authConfig";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";

// let userAccessToken = localStorage.getItem("accesstoken");
// axios.defaults.headers.common["Authorization"] = `Bearer ${userAccessToken}`;
// axios.defaults.headers.common["Authorization"] = `Bearer ${BaseConfig.ApiKey}`;
/**
 * If a user is authenticated the Bulkmatic Main Page is rendered.
 * Otherwise a message indicating it is starting the user session.
 */

export default function App(props) {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [openLoadModal,setOpenLoadModal] = useState(false)

  useEffect(() => {
    const handleReload=(event)=>{
      if (event.key === 'appVersion' || props.isReloadRequire) {
        setOpenLoadModal(true);
        setTimeout(() => {
          setOpenLoadModal(false);
          props.setIsReloadRequire(false);
          window.location.reload();
        }, 5000);
      }
    }
    
    window.addEventListener('storage', handleReload);

    return () => {
      window.removeEventListener('storage', handleReload);
    };

  }, [props.isReloadRequire])

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None)
      instance.loginRedirect(loginRequest).catch((e) => {
      });
    return () => {};
  }, [inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
          <ToastContainer position='top-right' autoClose={2000} theme='colored' />
        <AppSession>
          <AppRoute />  
        </AppSession>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className='blukmatik_login_section'>
          <div className='blukmatik_login_card'>
            <p>
              <img
                src={BlukmatikLogo}
                alt=''
                className='blukmatik_logo_adjust'
              />
            </p>
            <div className='login_button_section'>
              <button
                className='login_button'
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>

      <Modal
        show={openLoadModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ textAlign: "center", paddingTop: "60px", fontSize: "20px", height: "200px" }}>
          <p>App has been updated. Refreshing to load the latest changes.</p>
        </Modal.Body>
      </Modal>

    </>
  );
}
