import React, { useEffect, useState, useContext, useRef } from "react";
import { DateTime } from "luxon";
import BaselineShiping from "../../assets/images/baseline_local_shipping.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import { BaseConfig } from "../.././baseConfig"
import Config from './../../Config.json';
import SendIcon from '@mui/icons-material/Send';
import ForumIcon from '@mui/icons-material/Forum';
import ListItemText from '@mui/material/ListItemText';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getActivelocations } from "../../services/locationService";
import { ContextData } from "../../components/appsession";
import { getUser } from "../../services/userService";
import RvHookupIcon from '@mui/icons-material/RvHookup';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AuditLogModalComponent from "../../components/driverPay/auditLogModal";
import { process } from "@progress/kendo-data-query";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { DropContext } from "../../pages/planner/planner";
import { Autocomplete } from "@mui/lab";
import { TextField, Chip } from "@mui/material";
import { getAllDrivers } from "../../services/driverService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import Skeleton from '@mui/material/Skeleton';
import { createDriverMessage, getDriverMessage, updateDriverMessageseen } from "../../services/messageService";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";
import Checkbox from '@mui/material/Checkbox';
import {
  getTerminalbylatlong,
  getImapctView,
  getAllTerminals,
  removemultiplemovements,
  updatemultiplemovements,
  updateETA,
  createSplit,
  createBobtailOrEmptyMovements,
  createUnSplit,
  editMovements,
  editStops,
  removeTrailer,
  deleteMovementFromPlan,
  getDriverHosClock,
  addOrderComments,
  getNearByLocation,
  addNewLocation,
  sendXmitToLoadmaster,
  checkForUnsplit,
  checkForActual,
  getDurationAndDistance
} from "../../services/planningBoardService";

import PlannerBoardTooltip, {
  SamsaraPBToolTip,
  OrderTooltip,
  OrderPBToolTip,
  Order2PBToolTip,
  TrailerPBToolTip,
  ShiftToolTip,
  DriverToolTip
} from "./plannerboardtooltip";

import {
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { gettrailerstabbydate } from "../../services/trailerService";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import WarningModal from "../warningModal";
import { getAllDataByGenericAPI } from "../../services/genericService";
import { isTimeView } from "@mui/x-date-pickers/internals/utils/time-utils";
import TrimbleMapsForReturn from "./TrimbleMapsForReturn";
import { getVehicleLocationByVehicleId } from "../../services/tractorService";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { getTractorByTerminalID } from "../../services/terminalService";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

// Initial values
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",

  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",

    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",

    }}
    {...props}
  />
));

const PlannerBoardDriversection = (props) => {
  const [columnorder, setcolumnorder] = useState([])
  const [userData] = useContext(ContextData);
  let columns = [
    { id: 0, name: "expanded", title: " " },
    { id: 1, name: "terminal_fullname", title: "Terminal", width: "280px" },
    { id: 2, name: "Order_ID", title: "Order ID", width: "200px" },
    { id: 3, name: "Order_Status", title: "Order Status", width: "200px" },
    { id: 4, name: "Move_ID", title: "Move ID", width: "200px" },
    { id: 21, name: "pcmiler_distance", title: "Distance", width: "200px" },

    { id: 5, name: "Move_Status", title: "Move Status", width: "200px" },
    { id: 6, name: "pcmilerduration", title: 'Duration', width: '200px' },

    { id: 7, name: "Customer", title: "Customer", width: "280px" },
    { id: 8, name: "BOL", title: "BOL", width: "200px" },
    { id: 9, name: "Consignee_Ref", title: "Consignee Ref", width: "200px" },
    { id: 10, name: "PO_Lot", title: "PO Lot", width: "280px" },
    {
      id: 11,
      name: "Commodity_Group",
      title: "Commodity Group",
      width: "280px",
    },
    { id: 12, name: "Commodity", title: "Commodity", width: "280px" },
    { id: 13, name: "Product", title: "Product", width: "280px" },
    { id: 14, name: "HazMat", title: "HazMat", width: "200px" },
    { id: 15, name: "HazMat_Code", title: "HazMat Code", width: "200px" },
    { id: 16, name: "Ordered_Wgt", title: "Ordered Weight", width: "200px" },
    { id: 17, name: "ActualWgt", title: "Actual Weight", width: "200px" },
    { id: 18, name: "Driver_ID", title: "Driver ID", width: "200px" },
    { id: 19, name: "Trailer_ID", title: "Trailer ID", width: "200px" },
    { id: 20, name: "Tractor_ID", title: "Tractor ID", width: "200px" },
    { id: 22, name: "Sent_To_Driver", title: "Sent to Driver", width: "200px" },
    { id: 23, name: "Tankwash_WO", title: "Tankwash WO", width: "200px" },

    { id: 24, name: "Driver_Load", title: "Driver Load", width: "200px" },
    { id: 25, name: "Pickup_Type", title: "Pickup Type", width: "200px" },
    { id: 26, name: "Pickup_Name", title: "Pickup Location", width: "280px" },
    { id: 27, name: "Pickup_Address", title: "Pickup Address", width: "280px" },

    { id: 28, name: "Pickup_City", title: "Pickup City", width: "280px" },
    { id: 29, name: "Pickup_State", title: "Pickup State", width: "280px" },
    { id: 30, name: "Pickup_Zip", title: "Pickup Zip", width: "200px" },

    {
      id: 31,
      name: "Pickup_Timezone",
      title: "Pickup Timezone",
      width: "280px",
    },
    { id: 32, name: "Driver_Unload", title: "Driver Unload", width: "200px" },
    { id: 33, name: "Delivery_Type", title: "Delivery Type", width: "200px" },
    { id: 34, name: "Delivery_Name", title: "Delivery Location", width: "280px" },
    {
      id: 35,
      name: "Delivery_Address",
      title: "Delivery Address",
      width: "280px",
    },
    { id: 36, name: "Delivery_City", title: "Delivery City", width: "280px" },
    { id: 37, name: "Delivery_State", title: "Delivery State", width: "280px" },
    {
      id: 38,
      name: "sched_arrive_late_utc  ",
      title: "Late Pickup Appt",
      width: "280px",
    },
    { id: 39, name: "Delivery_Zip", title: "Delivery Zip", width: "200px" },
    {
      id: 40,
      name: "Delivery_Timezone",
      title: "Delivery Timezone",
      width: "280px",
    },
    { id: 41, name: "Drop_Flag", title: "Drop Flag", width: "200px" },
    {
      id: 42,
      name: "Early_Pickup_Appt",
      title: "Early Pickup Appt",
      width: "280px",
    },

    {
      id: 43,
      name: "Late_Pickup_Appt",
      title: "Late Pickup Appt",
      width: "280px",
    },
    {
      id: 44,
      name: "Pickup_Planned_Arrive",
      title: "Pickup Planned Arrive",
      width: "280px",
    },
    {
      id: 45,
      name: "Pickup_Planned_Depart",
      title: "Pickup Planned Depart",
      width: "280px",
    },

    { id: 46, name: "Pickup_ETA", title: "Pickup ETA", width: "280px" },
    {
      id: 47,
      name: "Pickup_Actual_Arrive",
      title: "Pickup Actual Arrive",
      width: "280px",
    },
    {
      id: 48,
      name: "Pickup_Actual_Depart",
      title: "Pickup Actual Depart",
      width: "280px",
    },

    {
      id: 49,
      name: "Early_Delivery_Appt",
      title: "Early Delivery Appt",
      width: "280px",
    },
    {
      id: 50,
      name: "Late_Delivery_Appt",
      title: "Late Delivery Appt",
      width: "280px",
    },
    {
      id: 51,
      name: "Delivery_Planned_Arrive",
      title: "Delivery Planned Arrive",
      width: "280px",
    },
    {
      id: 52,
      name: "Delivery_Planned_Depart",
      title: "Delivery Planned Depart",
      width: "280px",
    },
    { id: 53, name: "Delivery_ETA", title: "Delivery ETA", width: "280px" },
    {
      id: 54,
      name: "Delivery_Actual_Arrive",
      title: "Delivery Actual Arrive",
      width: "280px",
    },
    {
      id: 55,
      name: "Delivery_Actual_Depart",
      title: "Delivery Actual Depart",
      width: "280px",
    },
    { id: 56, name: "On_Hold", title: "On Hold", width: "280px" },
    { id: 57, name: "On_Hold_Reason", title: "On Hold Reason", width: "280px" },
    { id: 58, name: "planningstatus", title: "Planning Status", width: "200px" },

  ];
  const movementType = [{ Type: "BT", movement: "Bobtail" }, { Type: "E", movement: "Empty" }];
  const context = useContext(DropContext);
  const {
    selectedTimezoneDate,
    hourArr,
    dragset,
    plannerDriverData,
    allDrivers,
    selectedPlanningProfile,
    isEditPermission,
    setSuccessResponseMssg,
    plannerTimezone,
    realTimeofPlanningBoard,
    searchDriverData
  } = props;

  const [driverData, setDriverData] = useState([]);
  const [driverState, setDriverState] = useState([]);
  const [locationViewModal, setlocationViewModal] = useState(false);
  const [selectedLocationLatitude, setSelectedLocationLatitude] = useState("");
  const [selectedLocationLongitude, setSelectedLocationLongitude] = useState("");
  const [locationsDataFromMap, setLocationsDataFromMap] = useState([]);
  const [impactViewModal, setImpactViewModal] = useState(false);
  const [UserDataUPdate, setUserDataUPdate] = useState([]);
  const [splitviewmodal, setsplitviewmodal] = useState(false);
  const [allimpact, setallimpact] = useState([]);
  const [terminalname, setterminalname] = useState([]);
  const [selectedDriverOnMovement, setSelectedDriverOnMovement] = useState([]);
  const [terminalData, setterminalData] = useState([]);
  const [defaultterminal, setdefaultterminal] = useState([]);
  const [terminalHandelardata, setterminalHandelardata] = useState([]);
  const [driverHandelardata, setdriverHandelardata] = useState([]);
  const [movementlocationname, setmovementlocationName] = useState([]);
  const [durationsettime, setdurationsettime] = useState([]);
  const [page, setPage] = useState(context.driverPageNumber);
  const [driverviewmodal, setdriverviewmodal] = useState(false);
  const [driverdropdownData, setdriverdropdownData] = useState([]);
  const [locationcodeData, setlocationcodeData] = useState([]);
  const [defaultdriver, setdefaultdriver] = useState([]);
  const [driverIddropdown, setdriverIddropdown] = useState([]);
  const [orderData, setorderData] = useState([]);
  const [searchLocationData, setSearchLocationData] = useState("");
  const [selectedDistance, setSelectedDistance] = useState(5);
  const [startTime, setStartTime] = useState();
  const [destiLocation, setDestiLocation] = useState("");
  const [selectedTrailer, setSelectedTrailer] = useState({});
  const [driverMessage, setDriverMessage] = useState("");
  const [commodityGroupId, setCommodityGroupId] = useState("");
  const [isFindTrailerButtonVisible, setIsFindTrailerButtonVisible] = useState(null);
  const [showCommentsOfBillingDept, setShowCommentsOfBillingDept] = useState(null);
  const [commentsData, setcommentsData] = useState(null);
  const [commentsMssg, setcommentsMsg] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [commentOfBilligDeptOpen, setcommentOfBilligDeptOpen] = useState(false);
  const [allTrailers, setAllTrailers] = useState([]);
  const [splitButtonClick, setSplitButtonClick] = useState(false)
  // const [unAuthButton, setUnAuthButton]=useState(false)
  const [authButtonData, setAuthButtonData] = useState()
  const [showAuthorizeButton, setShowAuthorizeButton] = useState(false);
  const [showEmptyMoveButton, setShowEmptyMoveButton] = useState(null);
  const dayToMillis = 86400000;
  const [tractorData, setTractorData] = useState([]);
  const [selectedTractor, setSelectedTractor] = useState('');
  const [defaultTractor, setDefaultTractor] = useState('');

  // Kendo states for impact views
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 5,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(allimpact, dataState));
  const [clickedDriver, setClickedDriver] = useState("");
  const miles = [10, 15, 20];
  const [openMovementDialog, setOpenMovementDialog] = useState(false);
  const [openTrailerDialog, setOpenTrailerDialog] = useState(false);
  const [openXmitDialog, setOpenXmitDialog] = useState(false);
  const [removeTrailerBlock, setRemoveTrailerBlock] = useState({});
  const [removeMovementBlock, setRemoveMovementBlock] = useState({});
  const [driverID, setDriverID] = useState("");

  // fordroptrailer chnage locatioin
  const [isDropTrailerTrimbleVisible, setIsDropTrailerTrimbleVisible] = useState(false);
  const [isDropTrailerTrimbleVisibleSS, setIsDropTrailerTrimbleVisibleSS] = useState(false);
  const [isAddMovTrimbleVisible, setIsAddMovTrimbleVisible] = useState(false);
  const [dropTrailerData, setDropTrailerData] = useState({});
  const [dropTraileranddrophookData, setDropTraileranddrophookData] = useState({});
  const [destChangeDHandDT, setDestChangeDHandDT] = useState(false);
  const [modalShowDropTrailer, setmodalShowDropTrailer] = useState(false);
  const [selectedTrailerOnDropTrailer, setSelectedTrailerOnDropTrailer] = useState(null);
  const [movementforlocmapForDropTrailer, setmovementforlocmapDropTrailer] = useState([]);
  const [startTimeDropTrailer, setStartTimeDropTrailer] = useState();
  const [EndTimeDropTrailer, setEndTimeDropTrailer] = useState();
  const [destinationViewModal, setdestinationViewModal] = useState(false);
  const [movementforlocmap, setmovementforlocmap] = useState([]);
  const [alllocationdata, setalllocationdata] = useState([]);
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [selectedMovementData, setSelectedMovementData] = useState({});
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  // Picky State
  // const [trailerRemoveOptions, setTrailerRemoveOptions] = useState([]);
  // const [selectedOrderForTrailer, setSelectedOrderForTrailer] = useState([]);
  const [destinationadd, setdestinationadd] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [addMovements, setAddMovements] = useState(null);
  const [selectedMoveType, setSelectedMoveType] = useState(null);
  const [pickupdestinationlong, setpickupdestinationlong] = useState();
  const [deldestinationlat, setdeldestinationlat] = useState();
  const [deldestinationlong, setdeldestinationlong] = useState();
  const [movementformodal, setmovementformodal] = useState(false);
  const [movementdata, setmovementdata] = useState({});
  const [orderremovemodal, setorderremovemodal] = useState(false);
  const [driverdetails, setdriverdetails] = useState({});
  const [orderdetails, setorderdetails] = useState({});

  // Buttons states
  const [editActualSSandES, setEditActualSSandES] = useState(null);
  const [editActualsExceptSSandES, setEditActualsExceptSSandES] = useState(false);
  const [addEmptyMovement, setAddEmptyMovement] = useState(null);
  const [showButtons, setShowButtons] = useState(null);

  const [changeMoveTypeOnEmptyMov, setChangeMoveTypeOnEmptyMov] = useState(null);
  const [changeMoveTypeOnBobtailMov, setChangeMoveTypeOnBobtailMov] = useState(null);
  const [isSplitButton, setIsSplitButton] = useState(null);

  // states for unsplit modals
  const [unsplitModaldel, setunsplitmodaldel] = useState(null);
  const [unsplitdataDel, setUnsplitDatadel] = useState([]);
  const [unsplitModalpu, setunsplitmodalpu] = useState(null);
  const [unsplitdatapu, setUnsplitDatapu] = useState([]);
  const [loadedduration, setloadedduration] = useState(false);

  const [actualeditData, setactualeditData] = useState(false);
  const [actualeditdatamodal, setactualeditdatamodal] = useState({});
  const [selectedactualDate, setselectedactualDate] = useState();
  const [selecteddepartDate, setselecteddepartDate] = useState();
  const [stopsviewmodal, setstopsviewmodal] = useState(false);
  const [actualeditstopsdata, setactualeditstopsdata] = useState({});
  const [actualarrival, setactualarrival] = useState();
  const [returnActualArrival, setReturnActualArrival] = useState(null);
  const [actualdepart, setactualdepart] = useState();
  const [driverDOM, setDriverDOM] = useState(<></>);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [xmitDriver, setXmitDriver] = useState({});
  const [dataLoaded, setDataLoaded] = useState(true);
  const [driverChatMessageOpen, setDriverChatMessageOpen] = useState(false);
  const [driverToSendMessage, setDriverToSendMessage] = useState(null);
  const [driverEnteredMessage, setDriverEnteredMessage] = useState("");
  const [orders, setorders] = useState([]);

  // Audit log states
  const [clickedMovOrStopData, setClickedMovOrStopData] = useState({ type: "", movement: {} });
  const [isTrailerHistoryNeeded, setIsTrailerHistoryNeeded] = useState(false);

  //pickup and delivery stops data for Stops
  const [isModalOpen, setIsModalOpen] = useState(false, null);
  const [driverid, setdriverid] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longuitude, setlonguitude] = useState("");
  const [splitConfirmBtn, setSplitConfirmbtn] = useState(false);
  const [driverCurrentLocation, setDriverCurrentLocation] = useState([]);

  const stop_and_movs_buttons = {
    display: "inline-block",
    marginRight: "10px"
  };

  // adding popup content where driver load unload flag option edited 
  const [selectedOption, setSelectedOption] = useState('');
  const [actualeditmovedata, setactualeditmovedata] = useState({});
  const [stopClickFlag, setStopClickFlag] = useState(false);

  const [showReturnBtn, setShowReturnBtn] = useState({ isVisible: false, movOrStop: "" });
  const [showReturnModal, setShowReturnModal] = useState({ isVisible: false, movOrStop: "" });
  const [loadunloadflag, setLoadUnloadflag] = useState(false);
  const [movementsEditModal, setMOvementsEditModal] = useState(false);
  const [editStopChangeflag, setEditStopChangeFlag] = useState(true);
  const [movementChangeTypeText, setMovementChangeTypeText] = useState("");
  const [extraMoveModal, setExtraMoveModal] = useState(false);
  const [extraMoveDetails, setExtramoveDetails] = useState(null);
  const [driverToolTipModal, setDriverToolTipModal] = useState(false);
  const [changedestconfirmbtn, setChangedestconfirmbtn] = useState(false);
  const [changemovetypeconfirmbtn, setChangemovetypeconfirmbtn] = useState(false);
  const [addmovconfirmbtn, setaddmovconfirmbtn] = useState(false);
  const [editstopconfirmbtn, seteditstopconfirmbtn] = useState(false);
  const [deletmovconfirmbtn, setdeletmovconfirmbtn] = useState(false);
  const [removeTrailerconfirmbtn, setremoverTrailerconfirmbtn] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(null);
  const [xmitOrdersData, setXmitOrdersData] = useState([]);
  const [isDriverVisibleOnMap, setIsDriverVisibleOnMap] = useState(false);
  const [driverCurrentLatLong, setDriverCurrentLatLong] = useState({});
  const [stopDurationChange, setStopDurationChange] = useState(null);
  const [stopDurationChangeValue, setStopDurationChangeValue] = useState(null);
  const [showAuthorizedModal, setAuthorizedShowModal] = useState(false)
  const [actualbutton, setactualbutton] = useState(false);
  const [checkTrailerFlag, setCheckTrailerFlag] = useState(false);
  const [showValueOfActualsArrive, setShowValueOfActualsArrive] = useState(false)
  const [showValueOfActualsDepart, setShowValueOfActualsDepart] = useState(false)
  const [showButtonLoader, setshowButtonLoader] = useState(false);
  const [openDriverLocationModal, setDriverLocationModal] = useState(false);
  const [driverLocationCord, setDriverLocationCord] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    if (isDragging) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(()=>{
    if(driverState?.length>0){
    let params=getDecodedURI(location.search);
     if(params?.driverId && params?.driverMessageModal){
        let driverObj=driverState.filter((el)=>el.driverId==params?.driverId);
        driverObj?.length>0 && driverChatModalHandler(driverObj[0])
      }
    }
   },[driverState])


  const renderTooltip = (movement) => {
    if (isHovered && !isDragging) {
      return (
        <PlannerBoardTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTooltip>
      );
    } else if (isDragging) {
      return (
        <div className='tooltip_row_block'>{movement.type}</div>
      );
    } else {
      return (
        <PlannerBoardTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTooltip>
      );
    }
  };

  const handleLoadingBarOnDriver = (driverId, isLoad) => {
    setDriverState(driverState.map((el) => {
      if (el.driverId === driverId)
        return {
          ...el,
          isloading: isLoad,
        };
      return el;
    }));
  }

  useEffect(() => {
    if (UserDataUPdate[0]?.userpreferences) {
      setcolumnorder(UserDataUPdate[0]?.userpreferences.orderstabsequence)
    }
    else {
      if (UserDataUPdate[0]?.userpreferences?.orderstabsequence) {
        setcolumnorder(UserDataUPdate[0]?.userpreferences?.orderstabsequence)
      } else {
        setcolumnorder(columns)
      }
    }
  }, [UserDataUPdate[0]?.userpreferences?.orderstabsequence, UserDataUPdate[0]?.userpreferences]);

  useEffect(() => {
    var arrayOfObj = [];
    let newOrderArr = allimpact;

    if (newOrderArr && Object.keys(newOrderArr)) {
      arrayOfObj = Object.keys(newOrderArr).map((i) => newOrderArr[i]);
      let newDataValue = [];
      for (let index = 0; index < arrayOfObj.length; index++) {
        const temp = arrayOfObj[index];
        const duration = temp.pcmilerduration;

        if (!duration || isNaN(duration)) {
          temp.pcmilerduration = "";
        } else {
          const durationFormatted = Duration.fromObject({ hours: duration }).toFormat("hh:mm:ss");
          temp.pcmilerduration = durationFormatted;
        }

        if (
          temp.Delivery_Actual_Arrive === null ||
          isNaN(temp.Delivery_Actual_Arrive)
        ) {
          temp.Delivery_Actual_Arrive;
        } else {
          const millis_Delivery_Actual_Arrive = parseInt(
            temp.Delivery_Actual_Arrive * 1000
          );

          const Timezone_Delivery_Actual_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Actual_Arrive = DateTime.fromMillis(
            millis_Delivery_Actual_Arrive,
            {
              zone: Timezone_Delivery_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Actual_Depart === null ||
          isNaN(temp.Delivery_Actual_Depart)
        ) {
          temp.Delivery_Actual_Depart;
        } else {
          const millis_Delivery_Actual_Depart = parseInt(
            temp.Delivery_Actual_Depart * 1000
          );
          const Timezone_Delivery_Actual_Depart = temp.Delivery_Timezone;

          temp.Delivery_Actual_Depart = DateTime.fromMillis(
            millis_Delivery_Actual_Depart,
            {
              zone: Timezone_Delivery_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Arrive === null ||
          isNaN(temp.Delivery_Planned_Arrive)
        ) {
          temp.Delivery_Planned_Arrive;
        } else {
          const millis_Delivery_Planned_Arrive = parseInt(
            temp.Delivery_Planned_Arrive * 1000
          );
          const Timezone_Delivery_Planned_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Planned_Arrive = DateTime.fromMillis(
            millis_Delivery_Planned_Arrive,
            {
              zone: Timezone_Delivery_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Depart === null ||
          isNaN(temp.Delivery_Planned_Depart)
        ) {
          temp.Delivery_Planned_Depart;
        } else {
          const millis_Delivery_Planned_Depart = parseInt(
            temp.Delivery_Planned_Depart * 1000
          );
          const Timezone_Delivery_Planned_Depart = temp.Delivery_Timezone;

          temp.Delivery_Planned_Depart = DateTime.fromMillis(
            millis_Delivery_Planned_Depart,
            {
              zone: Timezone_Delivery_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Early_Delivery_Appt === null ||
          isNaN(temp.Early_Delivery_Appt)
        ) {
          temp.Early_Delivery_Appt;
        } else {
          const millis_Early_Delivery_Appt = parseInt(
            temp.Early_Delivery_Appt * 1000
          );
          const Timezone_Early_Delivery_Appt = temp.Delivery_Timezone;

          temp.Early_Delivery_Appt = DateTime.fromMillis(
            millis_Early_Delivery_Appt,
            {
              zone: Timezone_Early_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Early_Pickup_Appt === null || isNaN(temp.Early_Pickup_Appt)) {
          temp.Early_Pickup_Appt;
        } else {
          const millis_Early_Pickup_Appt = parseInt(
            temp.Early_Pickup_Appt * 1000
          );
          const Timezone_Early_Pickup_Appt = temp.Delivery_Timezone;

          temp.Early_Pickup_Appt = DateTime.fromMillis(
            millis_Early_Pickup_Appt,
            {
              zone: Timezone_Early_Pickup_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Late_Delivery_Appt === null ||
          isNaN(temp.Late_Delivery_Appt)
        ) {
          temp.Late_Delivery_Appt;
        } else {
          const millis_Late_Delivery_Appt = parseInt(
            temp.Late_Delivery_Appt * 1000
          );
          const Timezone_Late_Delivery_Appt = temp.Delivery_Timezone;

          temp.Late_Delivery_Appt = DateTime.fromMillis(
            millis_Late_Delivery_Appt,
            {
              zone: Timezone_Late_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Late_Pickup_Appt === null || isNaN(temp.Late_Pickup_Appt)) {
          temp.Late_Pickup_Appt;
        } else {
          const millis_movementpickupactual_departure = parseInt(
            temp.movementpickupactual_departure * 1000
          );
          const Timezone_movementpickupactual_departure =
            temp.movementpickuptimezone;
          const millis_Late_Pickup_Appt = parseInt(
            temp.Late_Pickup_Appt * 1000
          );
          const Timezone_Late_Pickup_Appt = temp.Pickup_Timezone;

          temp.movementpickupactual_departure = DateTime.fromMillis(
            millis_movementpickupactual_departure,
            {
              zone: Timezone_movementpickupactual_departure,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);

          temp.Late_Pickup_Appt = DateTime.fromMillis(millis_Late_Pickup_Appt, {
            zone: Timezone_Late_Pickup_Appt,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.movementpickupsched_arrive_early === null ||
          isNaN(temp.movementpickupsched_arrive_early)
        ) {
          temp.movementpickupsched_arrive_early;
        } else {
          const millis_movementpickupsched_arrive_early = parseInt(
            temp.movementpickupsched_arrive_early * 1000
          );
          const Timezone_movementpickupsched_arrive_early =
            temp.movementpickuptimezone;

          temp.movementpickupsched_arrive_early = DateTime.fromMillis(
            millis_movementpickupsched_arrive_early,
            {
              zone: Timezone_movementpickupsched_arrive_early,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Arrive === null ||
          isNaN(temp.Pickup_Actual_Arrive)
        ) {
          temp.Pickup_Actual_Arrive;
        } else {
          const millis_Pickup_Actual_Arrive = parseInt(
            temp.Pickup_Actual_Arrive * 1000
          );
          const Timezone_Pickup_Actual_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Actual_Arrive = DateTime.fromMillis(
            millis_Pickup_Actual_Arrive,
            {
              zone: Timezone_Pickup_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Depart === null ||
          isNaN(temp.Pickup_Actual_Depart)
        ) {
          temp.Pickup_Actual_Depart;
        } else {
          const millis_Pickup_Actual_Depart = parseInt(
            temp.Pickup_Actual_Depart * 1000
          );
          const Timezone_Pickup_Actual_Depart = temp.Pickup_Timezone;

          temp.Pickup_Actual_Depart = DateTime.fromMillis(
            millis_Pickup_Actual_Depart,
            {
              zone: Timezone_Pickup_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Pickup_ETA === null || isNaN(temp.Pickup_ETA)) {
          temp.Pickup_ETA;
        } else {
          const millis_Pickup_ETA = parseInt(temp.Pickup_ETA * 1000);
          const Timezone_Pickup_ETA = temp.Pickup_Timezone;

          temp.Pickup_ETA = DateTime.fromMillis(millis_Pickup_ETA, {
            zone: Timezone_Pickup_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (temp.Delivery_ETA === null || isNaN(temp.Delivery_ETA)) {
          temp.Delivery_ETA;
        } else {
          const millis_Delivery_ETA = parseInt(temp.Delivery_ETA * 1000);
          const Timezone_Delivery_ETA = temp.Delivery_Timezone;

          temp.Delivery_ETA = DateTime.fromMillis(millis_Delivery_ETA, {
            zone: Timezone_Delivery_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Arrive === null ||
          isNaN(temp.Pickup_Planned_Arrive)
        ) {
          temp.Pickup_Planned_Arrive;
        } else {
          const millis_Pickup_Planned_Arrive = parseInt(
            temp.Pickup_Planned_Arrive * 1000
          );
          const Timezone_Pickup_Planned_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Planned_Arrive = DateTime.fromMillis(
            millis_Pickup_Planned_Arrive,
            {
              zone: Timezone_Pickup_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Depart === null ||
          isNaN(temp.Pickup_Planned_Depart)
        ) {
          temp.Pickup_Planned_Depart;
        } else {
          const millis_Pickup_Planned_Depart = parseInt(
            temp.Pickup_Planned_Depart * 1000
          );
          const Timezone_Pickup_Planned_Depart = temp.Pickup_Timezone;

          temp.Pickup_Planned_Depart = DateTime.fromMillis(
            millis_Pickup_Planned_Depart,
            {
              zone: Timezone_Pickup_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        newDataValue.push(temp);
      }
      setorders(newDataValue);
      // setIsDataLoaded(true);
    }
  }, [allimpact]);

  const handleCloseDialogXmit = async (sendOrNot, xmitDriver) => {
    handleLoadingBarOnDriver(xmitDriver.driverId, true);

    try {
      let planIds = [];
      xmitOrdersData?.forEach((el) => {
        if (el.checked) {
          planIds.push(el.planId)
        }
      })
      let payLoad = {}
      if (planIds.length > 0) {

        try {
          if (planIds.length > 0) {
            payLoad.plan_ids = planIds;

            if (selectedTractor) {
              if (defaultTractor !== selectedTractor) {
                payLoad.tractor_id = selectedTractor
              }
            } else {
              toast.error("Please select a tractor", { autoClose: false });
              handleLoadingBarOnDriver(xmitDriver.driverId, false);
              return;
            }
            let response = await sendXmitToLoadmaster(payLoad);
            if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
              handleLoadingBarOnDriver(xmitDriver.driverId, false);
              errorMessageDisplay(response?.data?.errors);
              return;
            }
            setSuccessResponseMssg("Plan Sent to Driver.");
          }
        }
        catch (err) {
          console.log(err)
          handleLoadingBarOnDriver(xmitDriver.driverId, false);
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          } else {
            toast.error('Failed to handle close dialogue ', { autoClose: false });
          }
        }
        context.refreshDataFromAPI(context.payloadForRefreshData);
      } else {
        toast.error("No order selected to send xmit", { autoClose: false });
        handleLoadingBarOnDriver(xmitDriver.driverId, false);
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Unable to send xmit', { autoClose: false });
      }
      handleLoadingBarOnDriver(xmitDriver.driverId, false);
    }
    setOpenXmitDialog(false);
    setXmitDriver({});


  };
  const formatDate = (epoch_date, tzone) => {

    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CDT" || tzone === "CST" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return ""
    }

  }
  const checkForPlannedOrActual = (mov, stopType, type) => {
    if (context.plannedAcctualToggle == 'center' && stopType == 'pickup' && type == 'arrival') {
      return mov?.movementData?.pickup?.eta_arrival_utc
    } else if (context.plannedAcctualToggle == 'left' && stopType == 'pickup' && type == 'arrival') {
      return mov?.movementData?.pickup?.planned_arrive_utc
    } else if (context.plannedAcctualToggle == 'center' && stopType == 'pickup' && type == 'depart') {
      return mov?.movementData?.pickup?.eta_departure_utc
    } else if (context.plannedAcctualToggle == 'left' && stopType == 'pickup' && type == 'depart') {
      return mov?.movementData?.pickup?.planned_depart_utc
    } else if (context.plannedAcctualToggle == 'center' && stopType == 'delivery' && type == 'arrival') {
      return mov?.movementData?.delivery?.eta_arrival_utc
    } else if (context.plannedAcctualToggle == 'left' && stopType == 'delivery' && type == 'arrival') {
      return mov?.movementData?.delivery?.planned_arrive_utc
    } else if (context.plannedAcctualToggle == 'center' && stopType == 'delivery' && type == 'depart') {
      return mov?.movementData?.delivery?.eta_departure_utc
    } else if (context.plannedAcctualToggle == 'left' && stopType == 'delivery' && type == 'depart') {
      return mov?.movementData?.delivery?.planned_depart_utc
    }
  }
  const sendToXmit = async (driver) => {
    console.log("driver", driver)

    await fetchData()
    let movements = [...new Set(driver?.movements?.filter((mov) => mov?.stopData === null &&
      (checkForPlannedOrActual(mov, "pickup", "arrival") >= selectedTimezoneDate || checkForPlannedOrActual(mov, "pickup", "depart") >= selectedTimezoneDate)
      && (checkForPlannedOrActual(mov, "pickup", "arrival") <= (selectedTimezoneDate + dayToMillis) || checkForPlannedOrActual(mov, "pickup", "depart") <= (selectedTimezoneDate + dayToMillis)
      ))?.filter((mov) => mov?.movementData?.pickup?.stop_type == 'SS' || mov?.movementData?.delivery?.stop_type == 'ES'))]?.sort((a, b) => Number(a?.planned_arrive_utc) - Number(b?.planned_arrive_utc));
    let arr = []
    for (let i = 0; i < movements.length; i++) {
      if (movements[i]?.movementData?.pickup?.stop_type == 'SS' && movements[i + 1]?.movementData?.delivery?.stop_type == 'ES') {
        let obj = {};
        let movementId = [...new Set(driver?.movements?.filter((mov) => mov.stopData === null &&
          (checkForPlannedOrActual(mov, "pickup", "arrival") >= selectedTimezoneDate || checkForPlannedOrActual(mov, "pickup", "depart") >= selectedTimezoneDate)
          && (checkForPlannedOrActual(mov, "pickup", "arrival") <= (selectedTimezoneDate + dayToMillis) || checkForPlannedOrActual(mov, "pickup", "depart") <= (selectedTimezoneDate + dayToMillis)
          )))]?.map((mov) => mov?.movementData?.load_id)
        obj.startTime = checkForPlannedOrActual(movements[i], "pickup", "arrival");
        obj.endTime = checkForPlannedOrActual(movements[i + 1], "delivery", "depart");
        obj.orderid = movements[i]?.orderId2;
        obj.checked = movements[i]?.movementData?.xmitted2driver_utc == null ? true : false;
        obj.pickuptz = movements[i]?.movementData?.pickup?.pickup_timezone;
        obj.deliverytz = movements[i + 1]?.movementData?.delivery?.delivery_timezone;
        obj.planId = movements[i]?.movementData?.pickup?.plan_id
        arr.push(obj)
      } else if (movements[i]?.movementData?.pickup?.stop_type == 'SS' && movements[i + 1] == undefined && !movements[i + 1]) {
        let obj = {};
        let movementId = [...new Set(driver?.movements?.filter((mov) => mov?.stopData === null && mov?.movementData?.pickup?.plan_id === movements[i]?.movementData?.pickup?.plan_id &&
          (checkForPlannedOrActual(mov, "pickup", "arrival") >= selectedTimezoneDate || checkForPlannedOrActual(mov, "pickup", "depart") >= selectedTimezoneDate)
          && (checkForPlannedOrActual(mov, "pickup", "arrival") <= (selectedTimezoneDate + dayToMillis + dayToMillis) || checkForPlannedOrActual(mov, "pickup", "depart") <= (selectedTimezoneDate + dayToMillis + dayToMillis)
          )))]?.sort((a, b) => Number(a.planned_arrive_utc) - Number(b.planned_arrive_utc));
        obj.startTime = checkForPlannedOrActual(movementId[0], "pickup", "arrival");
        obj.endTime = checkForPlannedOrActual(movementId[movementId.length - 1], "delivery", "depart")
        obj.orderid = movements[i]?.orderId2;
        obj.checked = movementId[0]?.movementData?.xmitted2driver_utc == null ? true : false;
        obj.pickuptz == movementId[0]?.movementData?.pickup?.pickup_timezone;
        obj.deliverytz = movementId[movementId.length - 1]?.movementData?.delivery?.delivery_timezone;
        obj.planId = movements[0]?.movementData?.pickup?.plan_id
        arr.push(obj)
      } else if (i == 0 && movements[0]?.movementData?.delivery?.stop_type == 'ES') {
        let obj = {};
        let movementId = [...new Set(driver?.movements?.filter((mov) => mov?.stopData === null && mov?.movementData?.pickup?.plan_id === movements[i]?.movementData?.pickup?.plan_id &&
          (checkForPlannedOrActual(mov, "pickup", "arrival") >= (selectedTimezoneDate - dayToMillis) || checkForPlannedOrActual(mov, "pickup", "depart") >= (selectedTimezoneDate - dayToMillis))
          && (checkForPlannedOrActual(mov, "pickup", "arrival") <= (selectedTimezoneDate + dayToMillis) || checkForPlannedOrActual(mov, "pickup", "depart") <= (selectedTimezoneDate + dayToMillis)
          )))]?.sort((a, b) => Number(a?.planned_arrive_utc) - Number(b?.planned_arrive_utc));
        obj.startTime = checkForPlannedOrActual(movementId[0], "pickup", "arrival");
        obj.endTime = checkForPlannedOrActual(movementId[movementId.length - 1], "delivery", "depart")
        obj.orderid = movements[i]?.orderId2;
        obj.checked = movementId[0]?.movementData?.xmitted2driver_utc == null ? true : false;
        obj.pickuptz == movementId[0]?.movementData?.pickup?.pickup_timezone;
        obj.deliverytz = movementId[movementId.length - 1]?.movementData?.delivery?.delivery_timezone;
        obj.planId = movements[0]?.movementData?.pickup?.plan_id
        arr.push(obj)
      }
    }
    setXmitOrdersData(arr);
    setXmitDriver(driver);
    setOpenXmitDialog(true);
    setSelectedTractor(driver?.current_tractor);
    setDefaultTractor(driver?.current_tractor)
  };

  const handleChangeCheckbox = (data) => {
    let orderarray = xmitOrdersData?.map((el) => {
      if (el.startTime == data.startTime && el.endTime == data.endTime && el.orderid == data.orderid) {
        let obj = { ...el }
        obj.checked = !el.checked;
        return obj
      } else {
        return el
      }
    })
    setXmitOrdersData(orderarray)
  }

  const driverMessageUpdate = async () => {
    if (driverChatMessageOpen === true) {
      await updateDriverMessageseen(driverToSendMessage?.driverId);
    }
  }

  useEffect(() => {
    driverMessageUpdate()
  }, [driverChatMessageOpen]);

  useEffect(() => {
    if (Object.keys(context?.driverMessageDetails).length > 0) {
      driverChatModalHandler(context?.driverMessageDetails);
    }
  }, [context?.driverMessageDetails])


  const resetURLAfterClosingMessageModal = () => {
    let params = getDecodedURI(location.search);
    delete params.driverId
    delete params.driverMessageModal
    navigate({ pathname: location.pathname, search: `planningProfile=${params.planningProfile}` });
    context?.setDriverMessageDetails({});
  }

  const driverChatModalHandler = async (driver) => {
    try {
      const response = await getDriverMessage(driver?.driverId);
      let params = getDecodedURI(location.search);
      if (!params.driverId || params.driverId !== driver?.driverId) {
        params.driverId = driver?.driverId;
        params.driverMessageModal = true;
        navigate({ pathname: location.pathname, search: `${getEncodedURI(params)}` });
      }
      setDriverMessage(response);
      setDriverToSendMessage(driver);
      setDriverChatMessageOpen(true);
      setDriverEnteredMessage("");
    }
    catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to get driver message', { autoClose: false });
      }
    }
  };

  const sendDriverChatMessageHandler = async () => {
    try {
      let payload = {
        driverId: driverToSendMessage?.driverId,
        messageText: driverEnteredMessage,
        source: "BPA",
        timeZone: plannerTimezone
      }
      await createDriverMessage(payload);
      const response = await getDriverMessage(driverToSendMessage?.driverId);
      setDriverMessage(response);
      setDriverEnteredMessage("");

      // Resetting the url
      resetURLAfterClosingMessageModal();
      toast.success("Message Sent!", "Success")
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Message could not be sent!', { autoClose: false });
      }
    }
  };

  // THIS WILL BE CALLED AFTER PRESSING DELETE BUTTON FOR MOVEMENT REMOVING
  const handleCloseDialogMovement = (saveOrNot) => {
    setOpenMovementDialog(false);
    if (saveOrNot === "save") {
      deleteOrdersplanningBoard(removeMovementBlock, driverID);
    }
    setRemoveMovementBlock({});
    setDriverID("");
  };

  // THIS FUNCTION WILL BE CALLED AFTER PRESSING CLOSE ICON FOR TRAILER REMOVING
  const handleOpenDialogTrailer = (trailer, driverID, driver) => {
    setSelectedDriverOnMovement(driver);
    setRemoveTrailerBlock(trailer);
    setDriverID(driverID);
    setOpenTrailerDialog(true);
  };

  const removetrailerfrommovement = async (payloadWarningModal, warningDropdownValue) => {
    const allMovs = [];
    let planId = null;
    handleLoadingBarOnDriver(driverID, true);
    setremoverTrailerconfirmbtn(true);

    try {
      const allMovsBtwTrailerStartToEnd = selectedDriverOnMovement.movements.filter((mov) => removeTrailerBlock.moveId?.includes(mov.movementData.load_id) && mov.stopData==null);

      if (allMovsBtwTrailerStartToEnd.length > 0) {
        planId = allMovsBtwTrailerStartToEnd[0].movementData.pickup.plan_id;
      } else {
        toast.error("No movements found associated with the trailer.", { autoClose: false });
        return
      }

      const payload = {
        movement_id: [... removeTrailerBlock.moveId]
      };

      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }

      // Calling remove trailer API
      const response = await removeTrailer(driverID, planId, payload);

      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} warningMasterData={response?.data?.options ?? []} parentCallback={removetrailerfrommovement} payload={payload} errormssg={str} /></div>) });
        setOpenTrailerDialog(false);
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Trailer unassigned successfully.");
      }

      setremoverTrailerconfirmbtn(false);
      setOpenTrailerDialog(false);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      console.log(err)
      setremoverTrailerconfirmbtn(false);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Trailer cannot be unassigned. Please try again.', { autoClose: false });
      }
    }
  };

  const resetMapRelatedData = function () {
    setSelectedDistance(5);
    setSearchLocationData("");
    setSelectedLocationLongitude("");
    setSelectedLocationLatitude("");
  };

  const handleAddMovementViewModalDropTrailer = () => {
    setIsDropTrailerTrimbleVisible(false);
    setIsDropTrailerTrimbleVisibleSS(false);
    setStartTimeDropTrailer();
    resetMapRelatedData();
    setLocationsDataFromMap([]);
  };

  // FOR CLOSING ADD MOVEMENT MODAL
  const handleAddMovementViewModal = () => {
    handleLoadingBarOnDriver(selectedDriverData.driverId, false);
    setSelectedDriverData({});
    setIsAddMovTrimbleVisible(false);
    resetMapRelatedData();
  };

  useEffect(() => setDriverData(plannerDriverData), [plannerDriverData]);

  useEffect(() => {
    if (searchDriverData) {
      setDriverState(searchDriverData);
    }
  }, [page, searchDriverData]);

  // This function is for Impact view
  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  //for tractor in dropdown
  const fetchData = async () => {
    try {

      const terminalId = selectedPlanningProfile.terminal_id;
      const data = await getTractorByTerminalID(terminalId);

      setTractorData(data);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Error fetching tractor data, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  useEffect(() => {
    setDataResult(process(allimpact, dataState));
  }, [allimpact]);
  const [routeData, setrouteData] = useState([]);

  const currentDate = DateTime.fromObject({ hour: 0 }).toMillis();

  const getAllTrailers = async () => {
    const trailerResponse = await gettrailerstabbydate([selectedPlanningProfile?.terminal_id]);
    setAllTrailers(trailerResponse);
  };

  const findDriverLocation = async (driver) => {
    try {
      if (driver?.samsara_id) {
        const res = await getDriverHosClock(driver.samsara_id);
        if (res) {
          driver.hosclock = res;
          if (res?.currentVehicle?.name) {
            const driverLocation = await getVehicleLocationByVehicleId({ vehicleId: res?.currentVehicle?.name });
            setDriverCurrentLocation(driverLocation.data);
          }
        } else {
          toast.error("Failed to find driver location", { autoClose: false });
        }
      } else {
        toast.error("Driver's Samsara ID is null.", { autoClose: false });
      }
    }
    catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to find driver location', { autoClose: false });
      }
    }
  };

  // This function is running on clicking right button on movements
  const handleRightClickOnMovements = async (
    event,
    type,
    driverid,
    order,
    driver,
    movement
  ) => {
    event.preventDefault();
    setunsplitmodaldel(null);
    setunsplitmodalpu(null);
    setEditStopChangeFlag(true);
    setDestChangeDHandDT(false);
    setAddEmptyMovement(null);
    handlerightClose4()
    setAuthorizedShowModal(false);
    setCheckTrailerFlag(false);
    ss_es_modalclosehandelar();
    setStopDurationChange(null)

    if (showButtons) {
      setShowButtons(null);
      setshowButtonLoader(false)
    } else {
      // Storing selected driver object
      setshowButtonLoader(true)
      setClickedDriver(driverid);
      setSelectedDriverData(driver);
      setSelectedMovementData(movement);

      // It is showing all buttons 
      setShowButtons(event.currentTarget);

      // Split button will be shown on right clicking on Loaded movement
      if (
        type === "L" && movement.movementData.pickup.actual_departure_utc === 0
      ) {
        setIsSplitButton(true);
        let routeDataforsplit = { movementData: {} }
        if (movement.movementData.pickup.lm_location_id == "LAYOVER" || movement.movementData.delivery.lm_location_id == "LAYOVER") {
          if (movement.movementData.pickup.lm_location_id == "LAYOVER") {
            let driverMovements = driver.movements?.filter((mov) => (mov.stopData == null && mov.movementData.order.lm_order_id == movement.movementData.order.lm_order_id && ['PU', 'SP']?.includes(mov.movementData.pickup.stop_type)))
            routeDataforsplit.movementData.delivery = movement.movementData.delivery;
            routeDataforsplit.movementData.pickup = driverMovements?.length > 0 ? driverMovements[0].movementData.pickup : {};
            routeDataforsplit.movementData.load_id = movement.movementData.load_id;
            setrouteData(routeDataforsplit)
          } else {
            let driverMovements = driver.movements?.filter((mov) => (mov.stopData == null && mov.movementData.order.lm_order_id == movement.movementData.order.lm_order_id && ['DEL', 'SD']?.includes(mov.movementData.delivery.stop_type)))
            routeDataforsplit.movementData.pickup = movement.movementData.pickup;
            routeDataforsplit.movementData.delivery = driverMovements?.length > 0 ? driverMovements[0].movementData.delivery : {};
            routeDataforsplit.movementData.load_id = movement.movementData.load_id;
            setrouteData(routeDataforsplit)
          }
        } else {
          setrouteData(movement);
        }
        setdriverIddropdown(driverid);
        let hookstarttimeduration1 = movement.movementData.pickup.planned_depart_utc;
        let unhookingendtimeduration1 = movement.movementData.delivery.planned_arrive_utc;
        let duration = [];
        duration.push(unhookingendtimeduration1 - hookstarttimeduration1);
        setdurationsettime(duration);
      }

      let stopEditCheck 
      if(["PU", "SP", "DEL", "SD", "HT", "DT", "DH", "RET"].includes(type)){
      try {
        let payLoad={
          driver_id:movement.driverId,
          plannedDate:DateTime.fromMillis(movement.stopData.planned_arrive_utc).setZone(movement.stopData.pickup_timezone).toISO()
        }
         stopEditCheck = await checkForActual(movement.stopData.lm_stop_id,payLoad);
        if (stopEditCheck?.data?.canEdit) {
          setStopClickFlag(true);
        }
      } catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to handle right click on movements', { autoClose: false } );

        }
      }
    }
      if (["PU", "SP", "DEL", "SD"].includes(type)) {
        setLoadUnloadflag(true);
      }
      if (["PU", "DEL"].includes(type)) {
        setStopDurationChange(true)
        setStopDurationChangeValue((movement.stopData.planned_depart_utc - movement.stopData.planned_arrive_utc) / 60000)
      }

      // Storing clicked mov or stop data for Audit Log
      if (type === "BT" || type === "L" || type === "E") {
        setClickedMovOrStopData({ type: type, movement: movement });
      } else {
        if (movement.movementData.pickup.stop_type === type) {
          setClickedMovOrStopData({ type: type, movement: movement.movementData.pickup });
        } else {
          setClickedMovOrStopData({ type: type, movement: movement.movementData.delivery });
        }
      };

      if (type === "L" || type === "E" || type === "BT") {
        setShowAuthorizeButton(true)
        setAuthButtonData(movement)
      }
      const getloadedmovementsforSD = driver.movements.filter((mvts) => mvts.stopData == null && mvts.movementData.delivery?.lm_stop_id == movement.stopData?.lm_stop_id);
      const getloadedmovementsforSp = driver.movements.filter((mvts) => mvts.stopData == null && mvts.movementData.pickup?.lm_stop_id == movement.stopData?.lm_stop_id);

      // Only Unsplit Delivery button will be shown
      if (type === "SD" && movement.movementData.pickup.actual_departure_utc == 0) {
        setunsplitmodaldel(true);
        setUnsplitDatadel(movement);
      };


      // Edit Actuals and Unsplit Pickup both button will be shown
      if (type === "SP" && stopEditCheck?.data?.canAdd) {
        let loadedmovementduration = getloadedmovementsforSp[0].movementData.delivery.planned_arrive_utc - getloadedmovementsforSp[0].movementData.pickup.planned_depart_utc;

        if (loadedmovementduration > 10) {
          setloadedduration(true);
        }
        setEditActualsExceptSSandES(true);
        setactualeditstopsdata(movement);
        setUnsplitDatapu(movement);
      };

      // Edit Actuals and Unsplit Delivery both button will be shown
      if (type === "SD" && stopEditCheck?.data?.canAdd) {
        let loadedmovementduration = getloadedmovementsforSD[0].movementData.delivery.planned_arrive_utc - getloadedmovementsforSD[0].movementData.pickup.planned_depart_utc;

        if (loadedmovementduration > 10) {
          setloadedduration(true);
        }
        setEditActualsExceptSSandES(true);
        setactualeditstopsdata(movement);
        setUnsplitDatadel(movement);
      };

      // Change movement type button will be shown
      if (
        (type === "E")
      ) {
        if (movement.movementData.pickup.actual_departure_utc == 0) {
          if (movement.movementData.pickup.stop_type === "DEL" && movement.movementData.pickup.driver_load_unload.trim("") === "Y") {
            setChangeMoveTypeOnEmptyMov(false);
          }
          else {
            setChangeMoveTypeOnEmptyMov(true);
          }
        }
        setpickupdestinationlong(movement.movementData.pickup);
        setdeldestinationlat(movement.movementData.delivery);
        setdeldestinationlong(movement.movementData.delivery);
        setrouteData(movement);
      };

      // Change move type button will be shown
      if (type === "BT") {
        if (movement.movementData.pickup.actual_departure_utc == 0) {
          setChangeMoveTypeOnBobtailMov(true);
        }
        setpickupdestinationlong(movement.movementData.pickup.longitude);
        setdeldestinationlat(movement.movementData.delivery.latitude);
        setdeldestinationlong(movement.movementData.delivery.longitude);

        const allResponse = await getActivelocations();
        setalllocationdata(allResponse);
      };

      //change destination address for DT AND DH
      if ((type === "DT" || type === "DH") && movement.movementData.pickup.actual_arrival_utc === 0) {
        const movedataofDT = driver.movements.filter((mvts) => mvts.stopData == null && mvts.movementData.delivery?.lm_stop_id == movement.stopData?.lm_stop_id);
        setDestChangeDHandDT(true);
        setDropTraileranddrophookData(movement);
        setpickupdestinationlong(movedataofDT[0]?.movementData?.pickup);
        setdeldestinationlat(movedataofDT[0]?.movementData?.delivery);
        setdeldestinationlong(movedataofDT[0]?.movementData?.delivery);
        setrouteData(movedataofDT[0]);
      };
      // Return button will be shown on right clicking on Loaded movement
      if (type === "L" && movement.movementData.pickup.actual_departure_utc > 0 && movement.movementData.delivery.actual_arrival_utc === 0 && movement.movementData.delivery.stop_type == "DEL") {
        setShowReturnBtn({ isVisible: true, movOrStop: "mov" });
        await findDriverLocation(driver);
      }



      // Edit Actuals except SS and ES, if previous stops have actual
      if (stopEditCheck?.data?.canAdd) {
        setactualeditstopsdata(movement);
        let isTrailerAssigned=driver?.movements?.filter((el)=>el.movementData.pickup.stop_type == 'PU' && el.stopData==null && el.movementData.order_id2 == movement.movementData.order_id2)
        if (type == 'PU' && isTrailerAssigned.length>0 && !isTrailerAssigned[0].movementData.trailer_id) {
          setCheckTrailerFlag(true)
        }
        setEditActualsExceptSSandES(true);
      };

      // Add empty movement will be shown
      if (["DT","DH","HT"].includes(type) && movement.movementData.delivery.actual_arrival_utc === 0) {
        setAddEmptyMovement(true);
        setactualeditstopsdata(movement);
        setDropTrailerData(movement);
        setSelectedLocationLatitude(movement?.movementData?.pickup?.latitude);
        setSelectedLocationLongitude(movement?.movementData?.pickup?.longitude);
        await getAllTrailers();
      };


      // Only Unsplit Pickup button will be shown
      if (type === "SP" && movement.movementData.pickup.actual_departure_utc == 0) {
        try {
          let unsplitRes = await checkForUnsplit({
            move_sequence: movement.movementData.move_sequence,
            order_id: movement.movementData.order_id2
          });
          if (unsplitRes) {
            setUnsplitDatapu(movement);
            setunsplitmodalpu(true);
            setEditActualsExceptSSandES(false);
          } else {
            setEditActualsExceptSSandES(true);
          }
        }
        catch (err) {
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          } else {
            toast.error('Failed to handle right click on movements', { autoClose: false });
          }
        }
      };

      // For showing Return button on right clicking on DEL stop
      if (["DEL", "SD"].includes(type) && movement.stopData.actual_arrival_utc > 0 && movement.stopData.actual_departure_utc === 0) {
        setShowReturnBtn({ isVisible: true, movOrStop: "stop" });
        let delActualArrive = DateTime.fromMillis(parseInt(movement?.stopData?.actual_arrival_utc)).setZone(movement.stopData.pickup_timezone ? movement.stopData.pickup_timezone : movement.stopData.delivery_timezone)
        setReturnActualArrival(delActualArrive);
        await findDriverLocation(driver);
      }
      setshowButtonLoader(false)

    }
  };

  // This function is running on clicking Return button for DEL stop
  const returnBtnHandler = (i) => {
    // Here Code for showing modal and all will be here
    setShowButtons(null);
    setshowButtonLoader(false)
    setShowReturnModal({ isVisible: true, movOrStop: showReturnBtn?.movOrStop });
  };

  const Stopsmodal_open = async (i, actualeditstopsdata) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setSelectedOption(actualeditmovedata?.movementData?.pickup?.driver_load_unload?.trim());
    if (editActualsExceptSSandES) {
      let pu_timestamp = null;
      if (
        ![0, null, undefined, ""].includes(
          actualeditstopsdata?.stopData?.actual_arrival_utc
        )
      ) {
        pu_timestamp = DateTime.fromMillis(
          parseInt(actualeditstopsdata?.stopData?.actual_arrival_utc)
        )
          .setZone(
            actualeditstopsdata.stopData.pickup_timezone
              ? actualeditstopsdata.stopData.pickup_timezone
              : actualeditstopsdata.stopData.delivery_timezone
          )

        setactualarrival(pu_timestamp);
        setShowValueOfActualsArrive(true);
      } else {
        pu_timestamp = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setactualarrival(pu_timestamp);
        setShowValueOfActualsArrive(false);
      }
      let pu_timestamp1 = null;

      if (
        ![0, null, undefined, ""].includes(
          actualeditstopsdata?.stopData?.actual_departure_utc
        )
      ) {
        pu_timestamp1 = DateTime.fromMillis(
          parseInt(actualeditstopsdata?.stopData?.actual_departure_utc)
        )
          .setZone(
            actualeditstopsdata.stopData.pickup_timezone
              ? actualeditstopsdata.stopData.pickup_timezone
              : actualeditstopsdata.stopData.delivery_timezone
          )

        setactualdepart(pu_timestamp1);
        setShowValueOfActualsDepart(true)
      } else {
        pu_timestamp1 = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setactualdepart(pu_timestamp1);
        setShowValueOfActualsDepart(false)
      }
      if (checkTrailerFlag && actualeditstopsdata?.stopData?.stop_type == 'PU') {
        setactualdepart(null);
      }
    }
    setstopsviewmodal(true);
  };

  const handleactual_stops_DateChange = (date) => {
    if (actualarrival) {
      setactualarrival(date);
    } else {
      setactualarrival(date?.setZone(actualeditstopsdata.stopData.pickup_timezone ? actualeditstopsdata.stopData.pickup_timezone : actualeditstopsdata.stopData.delivery_timezone, { keepLocalTime: true }));
    }
    setEditStopChangeFlag(false);
    setShowValueOfActualsArrive(true);
  };

  const handledepart_stops_DateChange = (date) => {
    if (actualdepart) {
      setactualdepart(date);
    } else {
      setactualdepart(date?.setZone(actualeditstopsdata.stopData.pickup_timezone ? actualeditstopsdata.stopData.pickup_timezone : actualeditstopsdata.stopData.delivery_timezone, { keepLocalTime: true }));
    }
    setEditStopChangeFlag(false);
    setShowValueOfActualsDepart(true)
  };

  const stopclosehandeler = () => {
    setactualarrival();
    setShowValueOfActualsArrive(false);
    setShowValueOfActualsDepart(false);
    setactualdepart();
    setstopsviewmodal(false);
    setSelectedOption('');
    setStopDurationChange(null);
    setStopDurationChangeValue(null);
    setEditActualsExceptSSandES(false);
    setStopClickFlag(false);
    setLoadUnloadflag(false);
    setAddEmptyMovement(null);
    seteditstopconfirmbtn(false);
    setShowAuthorizeButton(false);
    setShowEmptyMoveButton(null);
    setCheckTrailerFlag(false)

  };

  const stop_ChangeClick = async (payloadWarningModal, warningDropdownValue) => {
    if (!isEditPermission) {
      return toast.error("Permission Error: Cannot update stops", { autoClose: false });
    }
    seteditstopconfirmbtn(true)

    let payload = {};

    if (selectedOption && loadunloadflag) {
      let originalLoadUnload = actualeditmovedata?.stopData?.driver_load_unload?.trim();
      if (originalLoadUnload !== selectedOption) {
        payload.load_unload_flag = selectedOption
      }
    }
    if (stopDurationChange && stopDurationChangeValue) {
      let originalDuration = (actualeditmovedata.stopData.planned_depart_utc - actualeditmovedata.stopData.planned_arrive_utc) / 60000
      if (originalDuration !== stopDurationChangeValue) {
        payload.stop_duration = stopDurationChangeValue
      }
    }
    if (editActualsExceptSSandES) {
      let allmovementoncurrentdate = driverState?.filter((driver) => driver.driverId == actualeditstopsdata.driverId)[0]?.movements?.filter((mvts) => mvts.startTime > selectedTimezoneDate && mvts.stopData == null)
      let nextStopInfo = allmovementoncurrentdate?.filter((mvts) => mvts.movementData.pickup.lm_stop_id == actualeditstopsdata.stopData.lm_stop_id);
      if (nextStopInfo?.length > 0 && (nextStopInfo[0]?.movementData.delivery.actual_departure_utc !== 0 || nextStopInfo[0]?.movementData.delivery.actual_arrival_utc !== 0)) {
        seteditstopconfirmbtn(false)
        return toast.error("To edit or remove the actuals of the current stop, you must first remove the actuals of the next stop.", { autoClose: false });
      }
      let previousStopInfo = allmovementoncurrentdate.filter((mvts) => mvts.movementData.delivery.lm_stop_id == actualeditstopsdata.stopData.lm_stop_id);
      if (previousStopInfo && previousStopInfo[0] && previousStopInfo[0].movementData.pickup.actual_departure_utc) {
        let actualInMillies = actualarrival?.toMillis()
        if (actualarrival && showValueOfActualsArrive && previousStopInfo[0].movementData.pickup.actual_departure_utc > actualInMillies) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival must be greater then previous stops depart", { autoClose: false });
        }
      }
      let originalActualArrival = actualeditmovedata?.stopData?.actual_arrival_utc ?? null;
      let originalActualDepart = actualeditmovedata?.stopData?.actual_departure_utc ?? null;
      if (actualarrival && actualdepart && showValueOfActualsArrive && showValueOfActualsDepart && actualdepart < actualarrival) {
        seteditstopconfirmbtn(false)
        return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
      } else if (actualarrival == null && showValueOfActualsDepart && actualdepart !== null) {
        seteditstopconfirmbtn(false)
        return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
      }
      let actualArrivalMillies = actualarrival?.toMillis();
      if (showValueOfActualsArrive) {
        if (originalActualArrival > 0) {
          if (actualArrivalMillies !== originalActualArrival) {
            payload.actual_arrival_datetime = actualarrival ? actualarrival?.toISO() : null;
          }
        } else {
          payload.actual_arrival_datetime = actualarrival ? actualarrival?.toISO() : null;
        }
      }
      let actualDepartMillies = actualdepart?.toMillis()
      if (showValueOfActualsDepart) {
        if (originalActualDepart > 0) {
          if (actualDepartMillies !== originalActualDepart) {
            payload.actual_departure_datetime = actualdepart ? actualdepart?.toISO() : null;
          }
        } else {
          payload.actual_departure_datetime = actualdepart ? actualdepart?.toISO() : null;
        }
      }
    }
    if (warningDropdownValue) {
      payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
    }
    try {
      let response = await editStops(payload, actualeditmovedata?.stopData?.lm_stop_id);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={stop_ChangeClick} errormssg={str} /></div>) });
        seteditstopconfirmbtn(false)
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Stop Data Updated successfully.");
      }
      setWarningModalOpen(null);
      seteditstopconfirmbtn(false)
    }
    catch (err) {
      console.log(err)
      seteditstopconfirmbtn(false);
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to stop change click', { autoClose: false });
      }
    }

    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    context.refreshDataFromAPI(context.payloadForRefreshData);
    setEditActualsExceptSSandES(false);
    setSelectedOption("");
    setStopDurationChange(null);
    setStopDurationChangeValue(null);
    setactualarrival(null);
    setShowValueOfActualsArrive(false);
    setShowValueOfActualsDepart(false)
    setactualdepart(null)
    setStopClickFlag(false);
    setShowAuthorizeButton(false);
    setLoadUnloadflag(false);
    setstopsviewmodal(false);
  };

  // using for SS OR ES stops tooltip//
  const handlerightClose4 = () => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setEditActualSSandES(null);
    setChangeMoveTypeOnBobtailMov(null);
    setAddEmptyMovement(null);
    setunsplitmodaldel(null);
    setunsplitmodalpu(null);
    setChangeMoveTypeOnEmptyMov(null)
    setIsSplitButton(null);
    setStopClickFlag(false);
    setShowAuthorizeButton(false);
    setShowEmptyMoveButton(null);
    setLoadUnloadflag(false);
    setDestChangeDHandDT(false);
    setShowReturnBtn(false);
    setCheckTrailerFlag(false)

  };

  // droptrailermodalhandler
  const openDroptrailerModal = async (location) => {
    let location_data = location
    try {
      if (location?.label == 'nearbysearch') {
        let response_location = await addNewLocation(location);
        if (response_location?.data?.status == 'failure' && response_location?.data?.errors && response_location?.data?.errors?.length > 0) {
          errorMessageDisplay(response_location?.data?.errors);
          return
        } else if (response_location?.data?.status == 'failure') {
          errorMessageDisplay([{ message: "Something Went Wrong While Adding New Location" }]);
          return
        } else {
          location_data = response_location?.data?.data;
        }
      }
      if (!location_data?.code) {
        toast.error("Something Went Wrong, Location ID Not Found For Selectd Location");
        return;
      }

      let payload = {
        lat1: dropTrailerData.latitude ,
        long1: dropTrailerData.longitude,
        lat2: location_data.latitude,
        long2: location_data.longitude,
      };

      const duration_res= await getDurationAndDistance(payload);
      if (duration_res?.data?.status == 'failure' && duration_res?.data?.errors && duration_res?.data?.errors?.length > 0) {
        errorMessageDisplay(duration_res?.data?.errors);
        return
      } else if (duration_res?.data?.status == 'failure') {
        errorMessageDisplay([{ message: "Failed to add move" }]);
        return
      }

      setmovementforlocmapDropTrailer(location_data);
      if (["DT","DH","HT"].includes(dropTrailerData.type)) {
        setStartTimeDropTrailer(movementTime(dropTrailerData.stopData.planned_depart_utc, context.plannerTimezone));
        setEndTimeDropTrailer(movementTime((dropTrailerData.stopData.planned_depart_utc + (Number(duration_res.data.data.duration)*60*1000)), context.plannerTimezone));
      }
      else if (dropTrailerData.type === "SS") {
        setStartTimeDropTrailer(movementTime(dropTrailerData.planned_depart_utc, context.plannerTimezone));
        setEndTimeDropTrailer(movementTime((dropTrailerData.planned_depart_utc + (Number(duration_res.data.data.duration)*60*1000)), context.plannerTimezone));
      }
      setmodalShowDropTrailer(true);
      setIsDropTrailerTrimbleVisible(false);
      setIsDropTrailerTrimbleVisibleSS(false);
      setLocationsDataFromMap([]);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to open drop trailer modal', { autoClose: false });
      }
    }
  };

  // After clicking on Add Empty Movement button
  const movementModalOpenforDT = () => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setAddEmptyMovement(null);
    setIsDropTrailerTrimbleVisible(true);
  };
  // After clicking on Add Empty Movement button on SS
  const movementModalOpenforSS = () => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setShowEmptyMoveButton(null);
    setIsDropTrailerTrimbleVisibleSS(true);
  };

  const resetStateValuesforDropTrailer = async () => {
    await context.refreshDataFromAPI(context.payloadForRefreshData);
    // reset all the states
    onModalCloseDropTrailer();
    setIsDropTrailerTrimbleVisible(false);
    setIsDropTrailerTrimbleVisibleSS(false);
    setDropTrailerData({});
    setStartTimeDropTrailer();
    setLocationsDataFromMap([]);
  };

  // After clicking on create button for Drop Trailer 
  const dropTrailerHandler = async (payloadWarningModal, warningDropdownValue) => {
    if(!selectedMoveType?.Type){
      return toast.error("please select movement type",{autoClose:false})
    }
    handleLoadingBarOnDriver(selectedDriverOnMovement.driverId, true);

    try {
      setaddmovconfirmbtn(true);
      const allMovementsOfSelectedDriver = selectedDriverData.movements;

      let lastEndShiftOfDriver = {};
      let planId = "";

      // Finding if any pre scheduled orders or not.
      if (allMovementsOfSelectedDriver.length > 0) {
        // All end shifts of driver
        const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && mov.movementData.delivery.lm_location_id !== "LAYOVER").sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

        // Getting exact last end shift
        lastEndShiftOfDriver = allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1];
        planId = lastEndShiftOfDriver.movementData.delivery.plan_id;
      };

      let payload = {};
      if (["DT","DH","HT"].includes(dropTrailerData.type)) {

        payload.plan_id = planId,
          payload.movement_type = selectedMoveType?.Type === "E" ? "empty" : "bobtail",
          payload.trailer_id = selectedTrailerOnDropTrailer?.Trailer_ID || "",
          payload.destination_location_id = movementforlocmapForDropTrailer?.code || "",
          payload.stop_id = dropTrailerData.movementData.pickup.lm_stop_id

      } else if (dropTrailerData.type === "SS") {

        payload.plan_id = planId,
          payload.movement_type = selectedMoveType?.Type === "E" ? "empty" : "bobtail",
          payload.trailer_id = selectedTrailerOnDropTrailer?.Trailer_ID || "",
          payload.destination_location_id = movementforlocmapForDropTrailer?.code || "",
          payload.stop_id = dropTrailerData.lm_stop_id

      }

      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }

      const data = await createBobtailOrEmptyMovements(selectedDriverData.driverId, payload);
      if (data?.data?.status == 'warning') {
        let str = data?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={data?.data?.options ?? []} parentCallback={dropTrailerHandler} errormssg={str} /></div>) });
        setaddmovconfirmbtn(false);
        return
      } else if (data?.data?.status == 'failure' && data?.data?.errors && data?.data?.errors?.length > 0) {
        errorMessageDisplay(data?.data?.errors);
      } else {
        setSuccessResponseMssg("Movement created successfully.");
      }
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      setSelectedMoveType(null)
      // Reset state values
      await resetStateValuesforDropTrailer();
      await context.refreshDataFromAPI(context.payloadForRefreshData);

    } catch (err) {
      console.log(err)
      setaddmovconfirmbtn(false);
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to drop trailer', { autoClose: false });
      }
      handleLoadingBarOnDriver(selectedDriverOnMovement.driverId, false);
    };
  };

  // Create a ref to hold the child component instance
  const auditLogComponentRef = useRef();
  const modalBodyRef = useRef(null);

  const authorizeButtonHandler = async () => {
    setAuthorizedShowModal(true);
    setShowButtons(null);
    setshowButtonLoader(false)

  }

  const handleOk = async () => {
    setAuthorizedShowModal(false);

    try {
      let payload = {
        load_id: authButtonData.movementData.load_id,
        authorized: !authButtonData.movementData.authorized,
      }
      const response = await updatemultiplemovements([payload]);
      await context.refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
      handleLoadingBarOnDriver(authButtonData.driverId, true);
      setSuccessResponseMssg("Button value changed successfully.");

    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to submit', { autoClose: false });
      }
    }
  }


  const handleCloseAuthorizeMovement = () => {
    setAuthorizedShowModal(false);
    setShowAuthorizeButton(false);

  };

  const auditLogModalOpenHandler = (props) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    const payload = {
      primary_key_value: clickedMovOrStopData?.movement?.id,
      table_name: (clickedMovOrStopData.type === "L" || clickedMovOrStopData.type === "E" || clickedMovOrStopData.type === "BT" ? "movements" : "stops")
    };

    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(payload.primary_key_value, payload.table_name);
  };

  const onModalCloseDropTrailer = () => {
    setmodalShowDropTrailer(false);
    setIsDropTrailerTrimbleVisible(false);
    setIsDropTrailerTrimbleVisibleSS(false);
    setLocationsDataFromMap([]);
    setSelectedTrailerOnDropTrailer();
    resetMapRelatedData();
    setaddmovconfirmbtn(false)
  };

  const handleDropTrailerDropTrailer = (e, value) => {
    if (value) {
      setSelectedTrailerOnDropTrailer(value);
    }
  };

  // This function will be called on clicking right button on Start and End Shift
  const handleRightClickOnShifts = async (e, shift, driver) => {
    e.preventDefault();
    ss_es_modalclosehandelar();
    handlerightClose4();
    setShowEmptyMoveButton(null);


    if (showButtons) {
      setShowButtons(null);
      setshowButtonLoader(false)
    } else {
      setshowButtonLoader(true);
      setShowButtons(e.currentTarget);
      let stopEditCheck
      try {
        let payLoad={
          driver_id:shift.movementData.driver_id,
          plannedDate:DateTime.fromMillis(shift.movementData.pickup.planned_arrive_utc).setZone(shift.movementData.pickup.pickup_timezone).toISO()
        }
          stopEditCheck = await checkForActual(shift.movementData.pickup.lm_stop_id,payLoad);

      }
      catch(err){
        console.log(err);
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to handle right clicks', { autoClose: false } );
        }
      }

      if (shift.shiftType === "start") {
        if (stopEditCheck?.data?.canEdit && stopEditCheck?.data?.canAdd) {
          setEditActualSSandES(true);
        }
        if(stopEditCheck?.data?.canEdit && !Object.keys(stopEditCheck?.data)?.includes('canAdd')){
          setEditActualSSandES(true);
        }
        setClickedMovOrStopData({ type: shift, movement: shift.movementData.pickup });
        let isTrailerAssigned=driver?.movements?.filter((el)=>el.movementData.pickup.lm_stop_id == shift.movementData.pickup.lm_stop_id && ['E','L'].includes(el.movementData.movement_type) && el.stopData==null)
        if (isTrailerAssigned.length>0 && !isTrailerAssigned[0].movementData.trailer_id) {
          setCheckTrailerFlag(true)
        }
        if (shift?.movementData?.pickup?.actual_departure_utc == 0) {
          setShowEmptyMoveButton(true);
          setactualeditstopsdata(shift?.movementData?.pickup);
          setDropTrailerData(shift?.movementData?.pickup);
          setSelectedLocationLatitude(shift?.movementData?.pickup?.latitude);
          setSelectedLocationLongitude(shift?.movementData?.pickup?.longitude);

          (async () => {
            await getAllTrailers()
          })();
        }

      } else {
        setClickedMovOrStopData({ type: shift, movement: shift.movementData.delivery });
      }
      setSelectedDriverData(driver);

      let stop_data_shift = shift.movementData.delivery.planned_arrive_utc
      let filterstop_shift_Array = driver.movements?.filter((e) => e.movementData.pickup.planned_arrive_utc < stop_data_shift)
      let last_stops_shiftdata_other = filterstop_shift_Array.find(o => o.movementData.pickup.planned_depart_utc === Math.max(...filterstop_shift_Array.map(i => i.movementData.pickup.planned_depart_utc)));

      //In Layover Start shift Actual time will not set if last shift has no actual data
      if ((shift.movementData.pickup.location.code === "LAYOVER" || shift.movementData.delivery.location.code === "LAYOVER") && last_stops_shiftdata_other.movementData.pickup.actual_arrival_utc === 0 && last_stops_shiftdata_other.movementData.pickup.actual_departure_utc === 0) {
        setactualbutton(true)
      };

      setactualeditdatamodal(shift);
      if (shift.shiftType === "end" && last_stops_shiftdata_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_shiftdata_other.movementData.pickup.actual_departure_utc != 0) {
        setEditActualSSandES(true);
      }

      setshowButtonLoader(false)
    }
  };

  const actualeditModal = async (i, shift) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    let timestamp = null;
    if (shift.shiftType === "start") {
      if (![0, null, undefined, ""].includes(shift.movementData.pickup.actual_arrival_utc)) {
        timestamp = DateTime.fromMillis(parseInt(shift.movementData.pickup.actual_arrival_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone);
        setselectedactualDate(timestamp);
        setShowValueOfActualsArrive(true);
      } else {
        timestamp = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setselectedactualDate(timestamp);
        setShowValueOfActualsArrive(false);
      }
      let timestamp2 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.pickup.actual_departure_utc)) {
        timestamp2 = DateTime.fromMillis(parseInt(shift.movementData.pickup.actual_departure_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone);
        setselecteddepartDate(timestamp2);
        setShowValueOfActualsDepart(true);
      } else {
        timestamp2 = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setselecteddepartDate(timestamp2);
        setShowValueOfActualsDepart(false);
      }
    } else if (shift.shiftType === "end") {
      let timestamp1 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.delivery.actual_arrival_utc)) {
        timestamp1 = DateTime.fromMillis(parseInt(shift.movementData.delivery.actual_arrival_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone);

        setselectedactualDate(timestamp1);
        setShowValueOfActualsArrive(true)
      } else {
        timestamp1 = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setselectedactualDate(timestamp1);
        setShowValueOfActualsArrive(false)
      }
      let timestamp3 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.delivery.actual_departure_utc)) {
        timestamp3 = DateTime.fromMillis(parseInt(shift.movementData.delivery.actual_departure_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone);

        setselecteddepartDate(timestamp3);
        setShowValueOfActualsDepart(true)
      } else {
        timestamp3 = DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone)
        setselecteddepartDate(timestamp3);
        setShowValueOfActualsDepart(false)
      }
    }

    setactualeditData(true)
    setEditActualSSandES(null);
  };

  const handleactualDateChange = (date) => {
    if (selectedactualDate) {
      setselectedactualDate(date);
    } else {
      setselectedactualDate(date?.setZone(actualeditdatamodal.movementData.pickup.pickup_timezone ? actualeditdatamodal.movementData.pickup.pickup_timezone : actualeditdatamodal.movementData.delivery.delivery_timezone, { keepLocalTime: true }));
    }
    setShowValueOfActualsArrive(true);
    setEditStopChangeFlag(false)
  };

  const handledepartDateChange = (date) => {
    if (selecteddepartDate) {
      setselecteddepartDate(date);
    } else {
      setselecteddepartDate(date?.setZone(actualeditdatamodal.movementData.pickup.pickup_timezone ? actualeditdatamodal.movementData.pickup.pickup_timezone : actualeditdatamodal.movementData.delivery.delivery_timezone, { keepLocalTime: true }));
    }
    setShowValueOfActualsDepart(true);
    setEditStopChangeFlag(false)

  };

  const ss_es_modalclosehandelar = () => {
    setselecteddepartDate()
    setselectedactualDate()
    setactualeditData(false);
    setEditStopChangeFlag(true);
    seteditstopconfirmbtn(false);
    setactualbutton(false);
    setShowValueOfActualsArrive(false);
    setShowValueOfActualsDepart(false);
    setCheckTrailerFlag(false)
    setStopDurationChange(null)
    setStopDurationChangeValue(null)
  }

  const saveDate = async (payloadWarningModal, warningDropdownValue) => {
    if (!isEditPermission) {
      return toast.error("Sorry You Dont Have Permission To Edit Stops", { autoClose: false });
    }
    let payload = {};
    seteditstopconfirmbtn(true);
    let originalActualArrival
    let originalActualDepart
    let lmStopId;
    let allmovementoncurrentdate = driverState.filter((driver) => driver.driverId == actualeditdatamodal?.movementData?.driver_id)[0].movements.filter((mvts) => mvts.startTime > selectedTimezoneDate && mvts.stopData == null)
    if (actualeditdatamodal.shiftType === "end") {
      lmStopId = actualeditdatamodal?.movementData?.delivery?.lm_stop_id;
      originalActualArrival = actualeditdatamodal?.movementData?.delivery?.actual_arrival_utc ?? null;
      originalActualDepart = actualeditdatamodal?.movementData?.delivery?.actual_departure_utc ?? null;
      let previousStopInfo = allmovementoncurrentdate.filter((mvts) => mvts.movementData.delivery.lm_stop_id == actualeditdatamodal?.movementData?.delivery?.lm_stop_id);
      if (previousStopInfo[0].movementData.pickup.actual_departure_utc) {
        let actualInMillies = selectedactualDate?.toMillis();
        if (selectedactualDate && showValueOfActualsArrive && previousStopInfo[0]?.movementData.pickup.actual_departure_utc > actualInMillies) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival must be greater then previous stops depart", { autoClose: false });
        }
      }
    } else {
      let nextStopInfo = allmovementoncurrentdate?.filter((mvts) => mvts.movementData.pickup.lm_stop_id == actualeditdatamodal?.movementData?.pickup?.lm_stop_id);
      if (nextStopInfo?.length > 0 && (nextStopInfo[0]?.movementData.delivery.actual_departure_utc !== 0 || nextStopInfo[0]?.movementData.delivery.actual_arrival_utc !== 0)) {
        seteditstopconfirmbtn(false)
        return toast.error("To edit or remove the actuals for the current stop, you must first remove the actuals for the next stop.", { autoClose: false });
      }
      lmStopId = actualeditdatamodal?.movementData?.pickup?.lm_stop_id;
      originalActualArrival = actualeditdatamodal?.movementData?.pickup?.actual_arrival_utc ?? null;
      originalActualDepart = actualeditdatamodal?.movementData?.pickup?.actual_departure_utc ?? null;
    }

    if (selectedactualDate && selecteddepartDate && showValueOfActualsArrive && showValueOfActualsDepart && selecteddepartDate < selectedactualDate) {
      seteditstopconfirmbtn(false)
      return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
    } else if (selectedactualDate == null && showValueOfActualsDepart && selecteddepartDate !== null) {
      seteditstopconfirmbtn(false)
      return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
    }
    let actualArrivalMillies = selectedactualDate?.toMillis();
    if (showValueOfActualsArrive) {
      if (originalActualArrival > 0) {
        if (actualArrivalMillies !== originalActualArrival) {
          payload.actual_arrival_datetime = selectedactualDate ? selectedactualDate?.toISO() : null;
        }
      } else {
        payload.actual_arrival_datetime = selectedactualDate ? selectedactualDate?.toISO() : null;
      }
    }
    let actualDepartMillies = selecteddepartDate?.toMillis()
    if (showValueOfActualsDepart) {
      if (originalActualDepart > 0) {
        if (actualDepartMillies !== originalActualDepart) {
          payload.actual_departure_datetime = selecteddepartDate ? selecteddepartDate?.toISO() : null;
        }
      } else {
        payload.actual_departure_datetime = selecteddepartDate ? selecteddepartDate?.toISO() : null;
      }
    }

    if (warningDropdownValue) {
      payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
    }

    try {
      let response = await editStops(payload, lmStopId);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={saveDate} errormssg={str} /></div>) });
        seteditstopconfirmbtn(false)
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Stop Data Updated Successfully.");
      }
      setWarningModalOpen(null);
      seteditstopconfirmbtn(false)
    }
    catch (err) {
      console.log(err)
      seteditstopconfirmbtn(false)
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While Updating Stops, Please Refresh Your Screen', { autoClose: false });
      }
    }
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    context.refreshDataFromAPI(context.payloadForRefreshData);
    setactualeditData(false);
    setselectedactualDate(null);
    setselecteddepartDate(null);
    setShowValueOfActualsArrive(false);
    setShowValueOfActualsDepart(false);
  }

  // movement for E //
  const Movementformodaldata = async (i, terminalname) => {
    setShowButtons(null)
    if (actualeditmovedata?.type?.trim() == "E") {
      setMovementChangeTypeText("Empty To Bobtail")
    } else if (actualeditmovedata?.type?.trim() == "BT") {
      setMovementChangeTypeText("Bobtail To Empty")
    }
    setmovementformodal(true);
    setmovementdata(terminalname);
  };

  const movement_E_ChangeClick = async (payloadWarningModal, warningDropdownValue) => {
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    setChangemovetypeconfirmbtn(true)
    const payload = {
      type: actualeditmovedata?.movementData?.movement_type === 'E' ? "bobtail" : "Empty",
    };
    if (warningDropdownValue) {
      payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
    }

    try {
      let response = await editMovements(payload, actualeditmovedata?.movementData?.load_id);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={movement_E_ChangeClick} errormssg={str} /></div>) });
        setChangemovetypeconfirmbtn(false)
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
        handleLoadingBarOnDriver(selectedDriverData.driverId, false);
      } else {
        setSuccessResponseMssg("Movement Type changed successfully");
      }
      setWarningModalOpen(null);
      setChangemovetypeconfirmbtn(false)
      await context.refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
      setmovementformodal(false);
      setMOvementsEditModal(false);
      setMovementChangeTypeText("");
      setChangeMoveTypeOnBobtailMov(false);
      setChangeMoveTypeOnEmptyMov(false);
    }
    catch (err) {
      console.log(err)
      handleLoadingBarOnDriver(selectedDriverData.driverId, false);
      setWarningModalOpen(null);
      setChangemovetypeconfirmbtn(false)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While Updating Movements, Please Refresh Your Screen', { autoClose: false });
      }
    }

  };

  // unsplit handler
  const unsplitdelhandler = async (payloadWarningModal, warningDropdownValue) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setStopClickFlag(false);
    let final_driver_pb_data = null;
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    try {
      let driverMovements = driverState.filter(it2 => it2.driverId === clickedDriver)[0]?.movements;
      let movements = driverMovements.filter((el) => el.stopData === null);
      let loadedMovement = movements?.filter((el) => ((el.movementData.pickup.lm_stop_id === unsplitdataDel?.stopData?.lm_stop_id) || (el.movementData.delivery.lm_stop_id === unsplitdataDel?.stopData?.lm_stop_id)) && el.movementData.movement_type === 'L');

      const payload = {
        movement_id: loadedMovement[0]?.movementData?.load_id,
        unsplitType: unsplitdataDel?.movementData?.pickup?.type
      };
      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }

      let response = await createUnSplit(payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={unsplitdelhandler} errormssg={str} /></div>) });
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Unsplit Done Successfully");
      }
      setWarningModalOpen(null);
      setunsplitmodaldel(null);
      setUnsplitDatadel([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      setunsplitmodaldel(null);
      setWarningModalOpen(null);
      setUnsplitDatadel([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setDriverState(prevDriverState => prevDriverState.map(it2 => {
        if (it2.driverId === final_driver_pb_data?.driverId || it2.driverId === unsplitdataDel?.driverId) {
          return {
            ...it2,
            isloading: false, // Set isLoading back to false
          };
        }
        return it2;
      }));
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While unsplit, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  const unsplitpuhandler = async (payloadWarningModal, warningDropdownValue) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setunsplitmodalpu(null);
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    setStopClickFlag(false);
    let final_driver_pb_data = null;
    try {

      let driverMovements = driverState.filter(it2 => it2.driverId === clickedDriver)[0]?.movements;
      let movements = driverMovements.filter((el) => el.stopData === null);
      let loadedMovement = movements?.filter((el) => ((el.movementData.pickup.lm_stop_id === unsplitdatapu?.stopData?.lm_stop_id) || (el.movementData.delivery.lm_stop_id === unsplitdatapu?.stopData?.lm_stop_id)) && el.movementData.movement_type === 'L');

      const payload = {
        movement_id: loadedMovement[0]?.movementData?.load_id,
        unsplitType: unsplitdatapu?.movementData?.pickup?.type
      }
      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }

      let response = await createUnSplit(payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={unsplitpuhandler} errormssg={str} /></div>) });
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        ;
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Unsplit Done Successfully");
      }
      setWarningModalOpen(null);
      setunsplitmodalpu(null);
      setUnsplitDatapu([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      handleLoadingBarOnDriver(unsplitdatapu.driverId, false);
    } catch (err) {
      setunsplitmodalpu(null);
      setWarningModalOpen(null);
      setUnsplitDatapu([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setDriverState(prevDriverState => prevDriverState.map(it2 => {
        if (it2.driverId === final_driver_pb_data?.driverId || it2.driverId === unsplitdatapu?.driverId) {
          return {
            ...it2,
            isloading: false, // Set isLoading back to false
          };
        }
        return it2;
      }));
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While unsplit, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  // Destination Handler //
  const destinationviewModalopen = async () => {
    setShowButtons(null);
    setshowButtonLoader(false)
    let payload = {
      longitude: deldestinationlong?.longitude,
      latitude: deldestinationlat?.latitude,
      searchtext: searchLocationData,
      distance: selectedDistance
    };
    setdestinationViewModal(true);
    try {
      setIsLocationLoading(true);
      const latlanglocation = await getTerminalbylatlong(payload);
      if (latlanglocation) {
        setIsLocationLoading(false);
        setLocationsDataFromMap(latlanglocation);
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('There is a problem in retrieving the location data, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  const destinationchangeClick = async (latlonlocationdata, warningDropdownValue) => {
    let location_data = latlonlocationdata;
    setChangedestconfirmbtn(true);
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    if (destChangeDHandDT) {
      try {
        if (latlonlocationdata?.label == 'nearbysearch') {
          let response_location = await addNewLocation(latlonlocationdata);
          if (response_location?.data?.status == 'failure' && response_location?.data?.errors && response_location?.data?.errors?.length > 0) {
            errorMessageDisplay(response_location?.data?.errors);
            setChangedestconfirmbtn(false)
            return
          } else if (response_location?.data?.status == 'failure') {
            errorMessageDisplay([{ message: "Something Went Wrong While Adding New Location" }]);
            setChangedestconfirmbtn(false);
            return
          } else {
            location_data = response_location?.data?.data;
          }
        }
        if (!location_data?.code) {
          toast.error("Something Went Wrong, Location ID Not Found For Selectd Location");
          setChangedestconfirmbtn(false);
          setWarningModalOpen(null);
          setSplitConfirmbtn(false)
          return;
        }
        let payload = {
          location_id: location_data.code
        }
        if (warningDropdownValue) {
          payload['option'] = latlonlocationdata?.option?.length > 0 ? [...latlonlocationdata?.option, warningDropdownValue] : [warningDropdownValue]
        }

        let response = await editStops(payload, dropTraileranddrophookData?.stopData?.lm_stop_id);
        setChangedestconfirmbtn(false);
        if (response?.data?.status == 'warning') {
          let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
          setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} warningMasterData={response?.data?.options ?? []} payload={{ ...location_data, ...payload }} parentCallback={destinationchangeClick} errormssg={str} /></div>) });
          return
        } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
          errorMessageDisplay(response?.data?.errors);
        } else {
          setSuccessResponseMssg("Stop Data Updated successfully.");
        }
        setWarningModalOpen(null);
        setDestChangeDHandDT(false);
        setdestinationViewModal(false);
        setDropTraileranddrophookData(null);
      }
      catch (err) {
        console.log(err)
        setChangedestconfirmbtn(false);
        setWarningModalOpen(null);
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        } else {
          toast.error('Something Went Wrong While Updating Stops, Please Refresh Your Screen', { autoClose: false });
        }
      }
    }
    await context.refreshDataFromAPI({
      "date": selectedTimezoneDate,
      "terminals": [selectedPlanningProfile.terminal_id]
    });
  };

  const splitviewclick = async (location) => {
    setSplitButtonClick(true)
    try {
      if (location?.label == 'nearbysearch') {
        let response = await addNewLocation(location);
        if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
          errorMessageDisplay(response?.data?.errors);
          setSplitButtonClick(false)
          return
        } else if (response?.data?.status == 'failure') {
          errorMessageDisplay([{ message: "Something Went Wrong While Adding New Location" }]);
          setSplitButtonClick(false);
          return
        } else {
          setlocationcodeData(response?.data?.data);
        }
      } else {
        setlocationcodeData(location);
      }
      setorderData(terminalname.lm_order_id);
      setdriverIddropdown(driverIddropdown);

      const terminallocationdata = await getAllTerminals();
      setterminalData(terminallocationdata);

      let terminaldefaultdata = terminallocationdata.filter(
        (item) => item.terminal_id === terminalname.movementData.terminal_id
      );
      setterminalHandelardata((item) => ({
        ...item,
        terminal_id: terminalname.movementData.terminal_id,
      }));
      setdefaultterminal(terminaldefaultdata);
      setsplitviewmodal(true);
      setMOvementsEditModal(false);
      setSplitButtonClick(false)
    }
    catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to handle split view click', { autoClose: false });
      }
    }
  };

  const handeDriverViewPagination = async (event, value) => {
    context.setDriverPageNumber(value);
    setPage(value);
  };

  const movementTime = (epoch_date, tzone) => {
    return DateTime.fromMillis(parseInt(epoch_date))
      .setZone(tzone)
      .toFormat("yyyy-MM-dd HH:mm")
      .toString();
  };

  const impactviewclick = async (e, data, data1) => {
    const usrData = await getUser(userData.id);
    setUserDataUPdate(usrData);

    if (data1.movementData.pickup.eta_arrival_utc > data1.movementData.pickup.planned_arrive_utc) {
      if (data == "PU" || "SP") {
        setDataLoaded(false)
        setImpactViewModal(true);
        const impactViewData = getImapctView(
          data1.movementData.pickup.planned_arrive_utc,
          data1.driverId,
          data1.trailer_id
        )
          .then((res) => {
            const impactdata = res.data.filter(obj => obj.Move_ID !== data1.movementData.load_id);
            setallimpact(impactdata);
            setDataLoaded(true)
          })
          .catch((err) => {
            setDataLoaded(false)
          });
      }

    }

    if (data1.movementData.delivery.eta_arrival_utc > data1.movementData.delivery.planned_arrive_utc) {
      if (data == "DEL" || "SD") {
        setDataLoaded(false)
        setImpactViewModal(true);
        const impactViewData = getImapctView(
          data1.movementData.delivery.planned_arrive_utc,
          data1.driverId,
          data1.trailer_id
        )
          .then((res) => {
            const impactdata = res.data.filter(obj => obj.Move_ID !== data1.movementData.load_id);
            setallimpact(impactdata);
            setDataLoaded(true)
            // setImpactViewModal(true);
          })
          .catch((err) => {
            setDataLoaded(false)
          });
      }
    }
  };

  const Blockmodalopen = async (
    terminalname,
    driverIddropdown,
    movementlocationname
  ) => {
    setShowButtons(null);
    setshowButtonLoader(false)
    setterminalname(terminalname);
    setorderData(terminalname);
    setmovementlocationName(movementlocationname);
    setLocationsDataFromMap([]);

    // driver api call for driver dropdown outside modal //
    let filterData = {
      terminalId: [terminalname.terminal_id],
    };
    const driverdropdowndata = await getAllDrivers(filterData);
    let driverdefaultdata = driverdropdowndata.filter(
      (e) => e.driver_id === driverIddropdown
    );
    setdriverHandelardata((item) => ({
      ...item,
      driver_id: driverIddropdown,
    }));
    setdefaultdriver(driverdefaultdata);
    setdriverdropdownData(driverdropdowndata);

    setlocationViewModal(true);
  };

  // Trimble map utility function
  async function showTrimbleMap(isMapVisible, BaseConfig, intialLatLong1, intialLatLong2, type) {

    if (isMapVisible) {
      TrimbleMaps.APIKey = `${Config.dev.trimbleMapKey}`;

      const map = new TrimbleMaps.Map({
        container: "map",
        style: TrimbleMaps.Common.Style.TRANSPORTATION,
        center: new TrimbleMaps.LngLat(
          intialLatLong1.longitude,
          intialLatLong1.latitude
        ),
        // Approximate zoom level to cover a 10-mile radius
        zoom: 9,
      });

      // It will be dynamically generated
      let myRoute;
      if (type === "dropTrailer" || type === "addMovement" || type === "SS") {
        myRoute = new TrimbleMaps.Route({
          container: "map",
          routeId: "myRoute",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          stops: [
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
          ],
        });
      } else {
        myRoute = new TrimbleMaps.Route({
          container: "map",
          routeId: "myRoute",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          stops: [
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
            new TrimbleMaps.LngLat(
              intialLatLong2.longitude,
              intialLatLong2.latitude
            ),
          ],
        });
      }
     
      // It will be dynamically generated
      let marker;
      if (type === "dropTrailer" || type === "addMovement" || type === "locationViewModal" || type === "SS") {
        marker = new TrimbleMaps.Marker({
          draggable: true,
        }).setLngLat([
          intialLatLong1.longitude,
          intialLatLong1.latitude
        ]).addTo(map);
      } else {
        marker = new TrimbleMaps.Marker({
          draggable: true,
        }).setLngLat([
          intialLatLong2.longitude,
          intialLatLong2.latitude
        ]).addTo(map);
      }

      map.addControl(new TrimbleMaps.NavigationControl());
      const scale = new TrimbleMaps.ScaleControl({
        maxWidth: 80,
        unit: "imperial",
      });
     
      if (type === "locationViewModal"){
        map.on("load", function () {
          myRoute.addTo(map);
        });
        }else{
            map.setZoom(9); 
        }

      map.addControl(scale);
      
      marker.on("dragend", async function (e) {
        const lngLat = e.target.getLngLat();
        setSelectedLocationLongitude(lngLat.lng.toFixed(4));
        setSelectedLocationLatitude(lngLat.lat.toFixed(4));
        let payload = {
          longitude: lngLat.lng.toFixed(4),
          latitude: lngLat.lat.toFixed(4),
          searchtext: searchLocationData,
          distance: selectedDistance,
        };
        let payLoadTrimblemap = {
          longitude: lngLat.lng.toFixed(4),
          latitude: lngLat.lat.toFixed(4),
          radius: selectedDistance
        }

        try {
          setIsLocationLoading(true);
          const allLocationsBasedOnLatLong = await Promise.all([getTerminalbylatlong(payload), getNearByLocation(payLoadTrimblemap)]);
          let locationfromlocationTable = allLocationsBasedOnLatLong[0];
          let locationfromtrimblemap = [];
          let nearBylocations;
          if (allLocationsBasedOnLatLong[1]?.data?.status == 'success' && allLocationsBasedOnLatLong[1]?.data?.data?.length > 0) {
            allLocationsBasedOnLatLong[1]?.data?.data?.forEach((item) => {
              let obj = {};
              obj.distance = Number(item?.DistanceFromCenter?.Value)?.toFixed(2);
              obj.address1 = item?.POILocation?.Address?.StreetAddress;
              obj.city = item?.POILocation?.Address?.City;
              obj.code = null;
              obj.latitude = item?.POILocation?.Coords?.Lat;
              obj.longitude = item?.POILocation?.Coords?.Lon;
              obj.location_name = item?.POILocation?.PlaceName;
              obj.state = item?.POILocation?.Address?.State;
              obj.zip = item?.POILocation?.Address?.Zip;
              obj.label = "nearbysearch"
              if (obj.location_name && obj.address1 && obj.city) {
                if (allLocationsBasedOnLatLong[0].length > 0 && Object.keys(allLocationsBasedOnLatLong[0]?.find(({ location_name }) => location_name == obj.location_name) ?? {})?.length == 0) {
                  locationfromtrimblemap.push(obj);
                } else if (allLocationsBasedOnLatLong[0].length == 0) {
                  locationfromtrimblemap.push(obj);
                }
              }
            })
          }
          if (locationfromlocationTable.length > 0 && locationfromtrimblemap.length > 0) {
            nearBylocations = [...locationfromlocationTable, ...locationfromtrimblemap]?.sort((a, b) => Number(a.distance) - Number(b.distance))
          } else if (locationfromlocationTable.length > 0) {
            nearBylocations = [...locationfromlocationTable];
          } else if (locationfromtrimblemap.length > 0) {
            nearBylocations = [...locationfromtrimblemap]
          } else {
            nearBylocations = []
          }
          setIsLocationLoading(false);
          setLocationsDataFromMap(nearBylocations);
        } catch (err) {
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          } else {
            toast.error('There is a problem in retrieving the location data', { autoClose: false });
          }
        }
      });

      // Initial loading
      if (selectedLocationLongitude && selectedLocationLatitude) {
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance,
        };

        try {
          setIsLocationLoading(true);
          const allLocationsBasedOnLatLong = await getTerminalbylatlong(payload);
          if (allLocationsBasedOnLatLong) {
            setIsLocationLoading(false);
            setLocationsDataFromMap(allLocationsBasedOnLatLong);
          }
        } catch (err) {
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          } else {
            toast.error('There is a problem in retrieving the location data', { autoClose: false });
          }
        }
      }
    }
  };

  const showDriverLocationOnMap = (e, longitude, latitude) => {
    e.preventDefault();
    setDriverCurrentLatLong({ ...driverCurrentLatLong, longitude, latitude, });
    setIsDriverVisibleOnMap(true);
  };

  // useEffect for showing map for different scenarios  
  useEffect(() => {
    if (isDropTrailerTrimbleVisible) {
      (async () => {
        await showTrimbleMap(isDropTrailerTrimbleVisible, BaseConfig, dropTrailerData, dropTrailerData, "dropTrailer");
      })();

    } else if (isDropTrailerTrimbleVisibleSS) {
      (async () => {
        await showTrimbleMap(isDropTrailerTrimbleVisibleSS, BaseConfig, dropTrailerData, dropTrailerData, "SS");
      })();
    }
    else if (isAddMovTrimbleVisible) {
      (async () => {
        await showTrimbleMap(isAddMovTrimbleVisible, BaseConfig, selectedDriverData?.terminal, selectedDriverData?.terminal, "addMovement");
      })();
    } else if (locationViewModal) {
      (async () => {
        await showTrimbleMap(locationViewModal, BaseConfig, routeData?.movementData?.pickup, routeData?.movementData?.delivery, "locationViewModal");

      })();
    } else if (destinationViewModal) {
      (async () => {
        await showTrimbleMap(destinationViewModal, BaseConfig, pickupdestinationlong, deldestinationlong, "destinationViewModal");
      })();
    }else if (openDriverLocationModal) {
      (async () => {
        await showTrimbleMap(openDriverLocationModal, BaseConfig, driverLocationCord, driverLocationCord, "addMovement");
      })();
    }
  }, [isDropTrailerTrimbleVisible, isAddMovTrimbleVisible, locationViewModal, destinationViewModal, isDropTrailerTrimbleVisibleSS,openDriverLocationModal]);

  const terminalIdHandler = (e, terminalname) => {
    if (terminalname) {
      setterminalHandelardata((item) => ({
        ...item,
        terminal_id: terminalname.terminal_id,
      }));
    }
  };

  const driverIdHandler = (e, driverIddropdown) => {
    if (driverIddropdown) {
      setdriverHandelardata((item) => ({
        ...item,
        driver_id: driverIddropdown.driver_id,
      }));
    }
  };

  const splitAssignDriverviewclick = async (payloadWarningModal, warningDropdownValue) => {
    try {
      setSplitConfirmbtn(true)
      handleLoadingBarOnDriver(driverHandelardata.driver_id, true);
      if (!locationcodeData?.code) {
        toast.error("Something Went Wrong, Location ID Not Found For Selectd Location");
        setWarningModalOpen(null);
        handleLoadingBarOnDriver(driverHandelardata.driver_id, false);
        setSplitConfirmbtn(false)
        return;
      }
      const payload = {
        movement_id: routeData?.movementData?.load_id,
        split_location_id: locationcodeData?.code,
        terminal_id: terminalHandelardata ? terminalHandelardata?.terminal_id : defaultterminal[0]?.terminal_id
      }
      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }

      let response = await createSplit(payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={splitAssignDriverviewclick} errormssg={str} /></div>) });
        setSplitConfirmbtn(false);
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Spilt order has been created successfully.");
      }
      setWarningModalOpen(null);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setdriverviewmodal(false);
      setlocationViewModal(false);
      setsplitviewmodal(false);
      setSplitConfirmbtn(false);
      setIsSplitButton(null);
    } catch (err) {
      console.log(err)
      setWarningModalOpen(null);
      handleLoadingBarOnDriver(driverHandelardata.driver_id, false);
      setSplitConfirmbtn(false)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While unsplit, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  const msToTime = (duration) => {
    if (duration) {
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor(duration / (1000 * 60 * 60));

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  };

  /* It is for removing complete order from planning board */
  const deleteOrdersplanningBoard = async (it, driver_id) => {
    try {
      let remove_payload = [
        ...driverState
          .filter((dri) => dri.driverId === driver_id)[0]
          ?.movements?.filter(
            (mvmt) =>
              mvmt.orderId2 == it.orderId2 &&
              mvmt.movementData.movement_type != "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.move_id,
          })),
        ...context.movementData.data
          .filter(
            (mvmt) => it.orderId2 == mvmt.order_id2 && mvmt.movement_type != "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.load_id,
          })),
      ];

      await removemultiplemovements(remove_payload);

      await updatemultiplemovements([
        // TODO: Needs to be unscheduled
        ...driverState
          .filter((dri) => dri.driverId === driver_id)[0]
          ?.movements?.filter(
            (mvmt) =>
              mvmt.orderId2 == it.orderId2 &&
              mvmt.movementData.movement_type == "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.move_id,
          })),
        ...context.movementData.data
          .filter(
            (mvmt) => it.orderId2 == mvmt.order_id2 && mvmt.movement_type == "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.load_id,
          })),
      ]);

      toast.success(
        "The Order has been removed from the Planning Board",
        "Success"
      );

      //await context. refreshDataFromAPI(payloadForPlanningBoard);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to delete orders from planning board', { autoClose: false });
      }
    }
  };

  const handleSearchInput = (e) => {
    setSearchLocationData(e.target.value);
  };

  const searchbylatlong = async (e, searchText) => {
    e.preventDefault();
    try {
      if (searchText && selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchText,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      } else if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);
        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }

    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('There is a problem in retrieving the location data', { autoClose: false });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchbylatlong(e);
    }
  };

  const filterLocationByDist = async (e) => {
    const value = Number(e.target.value);
    setSelectedDistance(value);
    try {
      if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: value
        };

        let payLoadTrimblemap = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          radius: value
        }

        try {
          const allLocationsBasedOnLatLong = await Promise.all([getTerminalbylatlong(payload), getNearByLocation(payLoadTrimblemap)]);
          let locationfromlocationTable = allLocationsBasedOnLatLong[0];
          let locationfromtrimblemap = [];
          let nearBylocations;
          if (allLocationsBasedOnLatLong[1]?.data?.status == 'success' && allLocationsBasedOnLatLong[1]?.data?.data?.length > 0) {
            allLocationsBasedOnLatLong[1]?.data?.data?.forEach((item) => {
              let obj = {};
              obj.distance = Number(item?.DistanceFromCenter?.Value)?.toFixed(2);
              obj.address1 = item?.POILocation?.Address?.StreetAddress;
              obj.city = item?.POILocation?.Address?.City;
              obj.code = null;
              obj.latitude = item?.POILocation?.Coords?.Lat;
              obj.longitude = item?.POILocation?.Coords?.Lon;
              obj.location_name = item?.POILocation?.PlaceName;
              obj.state = item?.POILocation?.Address?.State;
              obj.zip = item?.POILocation?.Address?.Zip;
              obj.label = "nearbysearch"
              if (obj.location_name && obj.address1 && obj.city) {
                if (allLocationsBasedOnLatLong[0].length > 0 && Object.keys(allLocationsBasedOnLatLong[0]?.find(({ location_name }) => location_name == obj.location_name) ?? {})?.length == 0) {
                  locationfromtrimblemap.push(obj);
                } else if (allLocationsBasedOnLatLong[0].length == 0) {
                  locationfromtrimblemap.push(obj);
                }
              }
            })
          }
          if (locationfromlocationTable.length > 0 && locationfromtrimblemap.length > 0) {
            nearBylocations = [...locationfromlocationTable, ...locationfromtrimblemap]?.sort((a, b) => Number(a.distance) - Number(b.distance))
          } else if (locationfromlocationTable.length > 0) {
            nearBylocations = [...locationfromlocationTable];
          } else if (locationfromtrimblemap.length > 0) {
            nearBylocations = [...locationfromtrimblemap]
          } else {
            nearBylocations = []
          }
          setIsLocationLoading(false);
          setLocationsDataFromMap(nearBylocations);
        } catch (err) {
          console.log(err)
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            errorMessageDisplay(err?.response?.data?.errors);
          } else {
            toast.error('There is a problem in retrieving the location data', { autoClose: false });
          }
        }
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('There is a problem in retrieving the location data', { autoClose: false });
      }
    }
  };

  const orderremoveModal = async (i, driver, order) => {
    setorderremovemodal(true);
    setdriverdetails(driver);
    setorderdetails(order);
  };

  const extraMovedelete = async (payloadWarningModal, warningDropdownValue) => {
    handleLoadingBarOnDriver(driverdetails.driverId, true);

    const driverId = driverdetails.driverId;
    const planId = extraMoveDetails?.movementData?.pickup?.plan_id;
    const movementId = extraMoveDetails.move_id;
    setdeletmovconfirmbtn(true);
    let payload = {}
    if (warningDropdownValue) {
      payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
    }

    try {
      const response = await deleteMovementFromPlan(driverId, planId, movementId, payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={extraMovedelete} errormssg={str} /></div>) });
        setdeletmovconfirmbtn(false)
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Movement deleted successfully.");
      }
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      console.log(err)
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While Deleting Movements, Please Refresh Your Screen', { autoClose: false });
      }
      handleLoadingBarOnDriver(driverdetails.driverId, false);
    }
    setExtraMoveModal(false);
    setExtramoveDetails(null);
  }

  const removeOrder = async (payloadWarningModal, warningDropdownValue) => {
    handleLoadingBarOnDriver(driverdetails.driverId, true);


    const driverId = driverdetails.driverId;
    const planId = orderdetails.plan_id;
    const movementId = orderdetails.move_id;
    setdeletmovconfirmbtn(true);
    let payload = {}
    if (warningDropdownValue) {
      payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
    }
    try {
      const response = await deleteMovementFromPlan(driverId, planId, movementId, payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={removeOrder} errormssg={str} /></div>) });
        setdeletmovconfirmbtn(false)
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
      } else {
        setSuccessResponseMssg("Order unscheduled successfully.");
      }
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      console.log(err)
      setdeletmovconfirmbtn(false);
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to remove order', { autoClose: false });
      }
      handleLoadingBarOnDriver(driverdetails.driverId, false);
    }

    setorderremovemodal(false);
  }

  // This function will be called when clicking on Add movement button
  const movementModalOpen = async (driver) => {
    handleLoadingBarOnDriver(driver.driverId, true);
    setLocationsDataFromMap([]);
    setIsAddMovTrimbleVisible(true);
    setSelectedDriverData(driver);
    setStartTime(movementTime(selectedTimezoneDate, context.plannerTimezone));
    setSelectedLocationLatitude(driver?.terminal?.bpaDriverParking?.location?.latitude ?? driver?.terminal?.latitude);
    setSelectedLocationLongitude(driver?.terminal?.bpaDriverParking?.location?.longitude ?? driver?.terminal?.longitude);
    // Getting all trailers for selected terminal
    await getAllTrailers();
  };

  // This function will be called when clicking on Save button after selecting a location
  const addmovementchangeClick = async (i, terminalname, destinationadd, latlonlocationdata, selectedDriverData) => {
    let location_data = latlonlocationdata;
    if (latlonlocationdata?.label == 'nearbysearch') {
      let response_location = await addNewLocation(latlonlocationdata);
      if (response_location?.data?.status == 'failure' && response_location?.data?.errors && response_location?.data?.errors?.length > 0) {
        errorMessageDisplay(response_location?.data?.errors);
        setIsAddMovTrimbleVisible(false)
        return
      } else if (response_location?.data?.status == 'failure') {
        errorMessageDisplay([{ message: "Something Went Wrong While Adding New Location" }]);
        setIsAddMovTrimbleVisible(false)
        return
      } else {
        location_data = response_location?.data?.data;
      }
    }
    if (!location_data?.code) {
      toast.error("Something Went Wrong, Location ID Not Found For Selectd Location");
      setIsAddMovTrimbleVisible(false)
      return;
    }
    setDestiLocation(location_data);
    setmovementforlocmap(location_data);
    setIsAddMovTrimbleVisible(false)
    setModalShow(true);
  };

  const resetCreateMovementStates = async () => {
    setModalShow(false);
    await context.refreshDataFromAPI(context.payloadForRefreshData);
    setSelectedTrailer(null);
    toast.success(
      "Movement created successfully.",
      "Success"
    );
  };

  // For creating bobtail or empty movement
  const createStops = async (payloadWarningModal, warningDropdownValue) => {
    try {
      if(!selectedMoveType?.Type){
        return toast.error("please select movement type",{autoClose:false})
      }
      setaddmovconfirmbtn(true);
      const allMovementsOfSelectedDriver = selectedDriverData.movements;
      let lastEndShiftOfDriver = {};
      let planId = "";

      // Finding if any pre scheduled orders or not.
      if (allMovementsOfSelectedDriver.length > 0) {
        // All end shifts of driver
        const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && mov.movementData.delivery.lm_location_id !== "LAYOVER").sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

        // Getting exact last end shift
        lastEndShiftOfDriver = allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1];
        planId = lastEndShiftOfDriver.movementData.delivery.plan_id;
      };

      const selectedTerminalMillisToUTC = DateTime.fromMillis(parseInt(selectedTimezoneDate)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

      const payload = {
        plan_date: selectedTerminalMillisToUTC,
        movement_type: selectedMoveType?.Type === "E" ? "empty" : "bobtail",
        trailer_id: selectedTrailer?.Trailer_ID || "",
        destination_location_id: destiLocation?.code || "",
      };

      if (["", undefined, null].includes(selectedMoveType)) {
        setaddmovconfirmbtn(false);
        return toast.error("Please select movement type.", { autoClose: false })
      }

      if (warningDropdownValue) {
        payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
      }
      const response = await createBobtailOrEmptyMovements(selectedDriverData.driverId, payload);
      if (response?.data?.status == 'warning') {
        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} payload={payload} warningMasterData={response?.data?.options ?? []} parentCallback={createStops} errormssg={str} /></div>) });
        setaddmovconfirmbtn(false);
        return
      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
        return
      } else {
        setSuccessResponseMssg("Movements created successfully")
      }
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      await resetCreateMovementStates();
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      console.log(err)
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to create stops', { autoClose: false });
      }
    }
  };

  // For closing add movement modal
  const onModalClose = () => {
    setModalShow(false);
    setLocationsDataFromMap([]);
    handleLoadingBarOnDriver(selectedDriverData.driverId, false);
    setSelectedDistance(5);
    setaddmovconfirmbtn(false)
  };

  const handleRightClickOnDriver = (e, driver) => {
    e.preventDefault();
    setSelectedDriverData(driver)
    if (showButtons) {
      setShowButtons(null)
    } else {
      if (driver.schedule.offduty.length) {
        let startTime = driver?.schedule?.offduty[0]?.logEndTime;
        setStartTime(movementTime(startTime, context.plannerTimezone))
      } else {
        const date = DateTime.local().setZone(context.plannerTimezone);

        // Set the time to 12 AM in the target timezone
        const twelveAm = date.startOf("day");

        // Get the epoch time in milliseconds
        const epochTime = twelveAm.toMillis();
        setStartTime(movementTime(epochTime, context.plannerTimezone))
      }
      setAddMovements(e.currentTarget);
    }
  }

  const handleMovetype = (e, value) => {
    setSelectedMoveType(value);
    setIsTrailerHistoryNeeded(false);
  }

  const trailerHandler = (e, value) => {
    if (value) {
      setSelectedTrailer(value);
    } else {
      setSelectedTrailer({});
      setIsTrailerHistoryNeeded(true);
    }
  };

  // ETA //
  const handledriveridChange = (e) => {
    setdriverid(e.target.value);
  };

  const handlelatitudeChange = (e) => {
    setlatitude(e.target.value);
  };

  const handleLongitudeChange = (e) => {
    setlonguitude(e.target.value);
  };

  const clearStateValues = () => {
    setdriverid();
    setlatitude();
    setlonguitude();
  };

  const openModal = (driverId) => {
    setIsModalOpen([true, driverId]);
    setdriverid(driverId);
  };

  const updateETAdata = async (e) => {
    const payload = {
      latitude: latitude,
      longitude: longuitude
    };
    if (driverid && payload.latitude && payload.longitude) {
      try {
        const updatedata = await updateETA(driverid, payload);

        if (updatedata.status === 200) {
          toast.success("Driver location updated successfully", "Success");
          clearStateValues();
          setIsModalOpen(false);
          setIsDriverVisibleOnMap(true);
          showDriverLocationOnMap(e, longuitude, latitude);
        } else {
          throw new Error("Failed to update ETA");
        }
      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to update ETA', { autoClose: false } );
        }
      } finally {
        clearStateValues();
      }
    } else {
      toast.error("Please fill all fields.", { autoClose: false });
    }
  };

  const checkMovementOnCurrentDay = function (driver) {
    const movementOnCurrentDay = driver.movements.find((mov) => mov.movementData.pickup.planned_arrive_utc >= selectedTimezoneDate || mov.movementData.pickup.planned_depart_utc >= selectedTimezoneDate || mov.movementData.delivery.planned_arrive_utc >= selectedTimezoneDate || mov.movementData.delivery.planned_depart_utc >= selectedTimezoneDate);

    return movementOnCurrentDay === undefined;
  }

  const handleRightClickTrailerRow = (e, driver, trailer) => {
    e.preventDefault();
    const movements = driver?.movements?.find((o) => o?.orderId2 === trailer?.order_id2 && o.stopData === null);

    if (isFindTrailerButtonVisible) {
      setIsFindTrailerButtonVisible(null);
    }
    else {
      if (Object.keys(movements).length > 0 && movements?.movementData?.order?.order_commodity.length > 0) {
        let commodity = movements?.movementData?.order?.order_commodity[0].CG?.commodityid.split("-");
        let commodityId = `${commodity[1].trim()} (${commodity[0].trim()})`;

        setCommodityGroupId(commodityId);
      } else {
        setCommodityGroupId("");
      }
      setIsFindTrailerButtonVisible(e.currentTarget);
    }
  };

  const handleFindTrailers = () => {
    context.setSelectedCommodityGroup(commodityGroupId);
    context.setFindTrailersBasedOnCommodity(true);
    context.settabSelected("trailers");
    setIsFindTrailerButtonVisible(null);
    localStorage.setItem("commodityGroup", commodityGroupId)
  };

  const handleCloseOrderRow = () => {
    setIsFindTrailerButtonVisible(null);
  };


  const handleRightClickorderRow = async (e, order, driver) => {
    e.preventDefault();
    setSelectedOrder(order)
    if (showCommentsOfBillingDept) {
      setShowCommentsOfBillingDept(null);
    } else {
      setShowCommentsOfBillingDept(e.currentTarget);
    }
  }

  const handleCloseorder = () => {
    setShowCommentsOfBillingDept(null)
  }

  const handleOpenBillingComments = async (order) => {
    setShowCommentsOfBillingDept(null);
    try {
      let response = await getAllDataByGenericAPI("order_comments", [{ order_id: selectedOrder?.orderId }]);
      if (response?.length > 0) {
        setcommentsData(response)
      } else {
        setcommentsData([])
      }
      setcommentOfBilligDeptOpen(true);
    }
    catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to handle Open Billing comments', { autoClose: false });
      }
    }
  }

  const handleCloseorderComments = () => {
    setcommentOfBilligDeptOpen(null);
    setSelectedOrder(null);
    setcommentsData(null);
    setcommentsMsg(null);
    setShowCommentsOfBillingDept(null);
  }

  const handlecommentsubmit = async () => {
    try {
      if (commentsMssg) {
        let payload = {
          order_id: selectedOrder?.orderId,
          comment_type: 'BD',
          messsage: commentsMssg,
          created_by: userData?.email?.split('@')[0]
        }
        let response = await addOrderComments(payload);
        setcommentsMsg("");
        if (response?.data?.status == 'failure' && response?.data?.errors) {
          let str = response?.data?.errors?.map((el) => `${el}\n`)?.join(",");
          toast.error("Connection to load master failed. Try again Later", { autoClose: false })
        } else {
          let response2 = await getAllDataByGenericAPI("order_comments", [{ order_id: selectedOrder.orderId }]);
          if (response2?.length > 0) {
            setcommentsData(response2)
          } else {
            setcommentsData([])
          }
          toast.success("Comment Sent Successfully");
        }
      }
    }
    catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Connection to load master failed. Try again Later', { autoClose: false });
      }
    }
  }

  const renderTrailer = (driver) => {
    let arr = [];

    let drivermoves = driver.filter((e) => e.stopData === null);

    if (drivermoves.length > 0) {
      let trailer = null;
      for (let i = 0; i < drivermoves.length; i++) {
        let move = drivermoves[i];
        let nextmove = i + 1 < drivermoves.length ? drivermoves[i + 1] : null;

        if (move.movementData.movement_type === "BT") {
          if (move.movementData.pickup.stop_type === "SS" && move.movementData.delivery.stop_type === "HT") {
            trailer = {
              trailerStartTime: context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc,
              trailer_id: move.movementData.trailer_id,
              order_id2: move.movementData.order_id2
            }
          } else if (move.movementData.pickup.stop_type === "DEL" && trailer?.trailerStartTime) {
            trailer.trailer_id = move?.movementData?.trailer_id;
            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_depart_utc : move.movementData.delivery.eta_departure_utc;
            arr.push(trailer);
            trailer = null
          } else if (trailer?.trailerStartTime) {
            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_arrive_utc : move.movementData.pickup.eta_arrival_utc;
            arr.push(trailer);
            trailer = null;
          }
        }
        else if (move.movementData.movement_type === "E") {
          if (!trailer) {
            trailer = {
              trailerStartTime: context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_depart_utc : move.movementData.pickup.eta_departure_utc,
              trailer_id: move.movementData.trailer_id,
              order_id2: move.movementData.order_id2,
              moveId: [move.movementData.load_id]
            }

            if (move.movementData.pickup.stop_type === "HT") {
              trailer.trailerStartTime = context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_arrive_utc : move.movementData.pickup.eta_arrival_utc;
            }
          }
          if (move.movementData.pickup.stop_type === "DEL" && (move.movementData.delivery.stop_type === "DT" || move.movementData.delivery.stop_type === "ES")) {
            trailer.trailer_id = move.movementData.trailer_id;
            if (move.movementData.delivery.stop_type === "ES") {
              trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc;
            } else {
              trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc;
            }
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer);
            trailer = null
          }
          else if (move.movementData.delivery.stop_type === "HT") {
            trailer.trailer_id = move.movementData.trailer_id;

            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc;
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer);
            trailer = null;
          }
          else if (move.movementData.delivery.stop_type === "DH") {
            trailer.trailer_id = move.movementData.trailer_id;
            let date1 = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc))
            let date2 = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc))
            trailer.trailerEndTime = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc)).plus(date1.diff(date2, 'milliseconds')).toMillis()
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer)
            trailer = null
          }
          else if (move.movementData.pickup.stop_type === "DH" && move.movementData.delivery.stop_type === "DT") {
            trailer.trailer_id = move.movementData.trailer_id;
            let date1 = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc))
            let date2 = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc))
            trailer.trailerStartTime = DateTime.fromMillis(parseInt(context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_depart_utc : move.movementData.pickup.eta_departure_utc)).minus(date1.diff(date2, 'milliseconds')).toMillis()

            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc;
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer);
            trailer = null
          }
          else if (nextmove && move.movementData.order_id2 !== nextmove.movementData.order_id2) {
            trailer.trailer_id = move.movementData.trailer_id;

            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc;
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer);
            trailer = null;
          }
          else if (["N", "NB"].includes(move.movementData.delivery.driver_load_unload.trim()) && ((nextmove && move.movementData.trailer_id !== nextmove?.movementData?.trailer_id) || ["", null, undefined]?.includes(move.movementData.trailer_id))) {
            trailer.trailer_id = move.movementData.trailer_id;

            trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc;
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            arr.push(trailer);
            trailer = null;
          }else{
            trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
          }
        }
        else if (move.movementData.movement_type === "L") {
          if (!trailer) {
            trailer = {
              trailerStartTime: context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_arrive_utc : move.movementData.pickup.eta_arrival_utc,
              trailer_id: move.movementData.trailer_id,
              order_id2: move.movementData.order_id2,
              moveId: [move.movementData.load_id]
            }
            if (move.movementData.delivery.stop_type === "ES") {
              trailer.trailer_id = move.movementData.trailer_id;

              trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_arrive_utc : move.movementData.delivery.eta_arrival_utc;
              trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
              arr.push(trailer);
              trailer = null;
            } else if (move.movementData.pickup.stop_type === "SS") {
              trailer = {
                trailerStartTime: context.plannedAcctualToggle === "left" ? move.movementData.pickup.planned_depart_utc : move.movementData.pickup.eta_departure_utc,
                trailer_id: move.movementData.trailer_id,
                order_id2: move.movementData.order_id2,
                moveId: [move.movementData.load_id]
              }
            } else if (["N", "NB"].includes(move.movementData.delivery.driver_load_unload.trim()) && move.movementData.delivery.stop_type !== 'RET' && (nextmove && move.movementData.trailer_id !== nextmove?.movementData?.trailer_id || ["", null, undefined]?.includes(move.movementData.trailer_id))) {
              trailer.trailer_id = move.movementData.trailer_id;

              trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc;
              trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
              arr.push(trailer);
              trailer = null;
            }
          } else {
            if (["N", "NB"].includes(move.movementData.delivery.driver_load_unload.trim()) && move.movementData.delivery.stop_type !== 'RET' && (nextmove && move.movementData.trailer_id !== nextmove?.movementData?.trailer_id || ["", null, undefined]?.includes(move.movementData.trailer_id))) {
              trailer.trailer_id = move.movementData.trailer_id;

              trailer.trailerEndTime = context.plannedAcctualToggle === "left" ? move.movementData.delivery.planned_depart_utc : move.movementData.delivery.eta_departure_utc;
              trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
              arr.push(trailer);
              trailer = null;
            } else {
              trailer.moveId = trailer?.moveId?.length > 0 ? [...trailer['moveId'], move.movementData.load_id] : [move.movementData.load_id]
            }
          }
        }
      }
    }
    let arr2 = arr.map((el) => { return { ...el, ['moveId']: [...new Set(el.moveId)] } })
    return arr2;
  };

  const checkForRemoveTrailer = (driver, trailer) => {
    if (trailer.trailer_id) {
      let driverMovements = driver?.movements?.filter((mv) => mv.stopData == null && (context.plannedAcctualToggle === "left" ? mv.movementData.pickup.planned_arrive_utc >= selectedTimezoneDate : mv.movementData.pickup.eta_arrival_utc >= selectedTimezoneDate) && (context.plannedAcctualToggle === "left" ? mv.movementData.pickup.planned_depart_utc < selectedTimezoneDate + dayToMillis : mv.movementData.pickup.eta_departure_utc < selectedTimezoneDate + dayToMillis));
      let driverMovementsInTrailerBlock = driverMovements?.filter((mv) => mv.stopData == null && trailer.moveId?.includes(mv.movementData.load_id))?.sort((a, b) => a.movementData.pickup.planned_arrive_utc - b.movementData.pickup.planned_arrive_utc);
      if (driverMovementsInTrailerBlock?.length > 0 && driverMovementsInTrailerBlock[0]?.movementData.pickup.actual_departure_utc !== 0) {
        return false;
      }
      if (driverMovementsInTrailerBlock?.filter((mv) => mv.movementData.pickup.stop_type == 'SP')?.length > 0) {
        return false;
      }

      return true
    } else {
      return false
    }

  }

  let driverDomUpdateFunction = async () => {
    setDriverDOM(await Promise.all(driverState?.map(async (driver, idx) => {
      return (
        <>
          <div key={idx} class='planner_main_container_outer'>
            <div className='planner_main_container_outer_image_container_right_text_wrapper'>
              <div class='planner_main_container_outer_image_container'
                aria-controls='customized-menu'
                aria-haspopup='true'
                variant='contained'
                color='primary'
                onContextMenu={(e) => {
                  handleRightClickOnDriver(
                    e,
                    driver
                  );
                }}
              >
                <div className='plannerflex'>
                  <RvHookupIcon
                    onClick={() => {
                      if (checkMovementOnCurrentDay(driver) && isEditPermission) {
                        movementModalOpen(driver);
                      }
                    }}
                    style={checkMovementOnCurrentDay(driver) ? { color: "#3b3838", cursor: "pointer" } : { color: "grey" }}
                  />

                  <MoreTimeIcon onClick={() => openModal(driver.driverId)} disabled={!isEditPermission} className="hand-pointer" style={{ color: "#3b3838" }} />
                </div>
                <PlannerBoardTooltip tooltip={
                  <DriverToolTip it={driver} setDriverLocationModal={setDriverLocationModal} setDriverLocationCord={setDriverLocationCord}/>
                }>
                  <div className='tooltip_row_block'>
                    <img src={BaselineShiping} alt='Baseline-Shiping' className='planner_left_image' style={{ cursor: "pointer" }} />
                  </div>
                </PlannerBoardTooltip>

                <p className='planner_main_container_outer_image_container_icon_text'>
                  {driver.driverId}
                </p>
              </div>
              <div className='planner_main_container_outer_image_container_right_text'>
                <p>Order</p>
                <p>Trailer</p>
                <p>Segment</p>
              </div>
            </div>

            <div class='planner_dragable_container'>
              {
                realTimeofPlanningBoard >= selectedTimezoneDate && realTimeofPlanningBoard < (selectedTimezoneDate + dayToMillis) &&
                <div className="plannerboard_realtime_bar" style={{ left: (((realTimeofPlanningBoard - (DateTime.now().setZone(plannerTimezone).startOf("day").toMillis())) / dayToMillis) * 100 + "%") }}></div>
              }
              {driver.isloading ? (
                <Stack spacing={1}>
                  <div className='loader_skeleton'>
                    <Skeleton variant='rectangular' />
                  </div>
                </Stack>
              ) : (
                <></>
              )}
              {driver?.schedule?.offduty

                ?.map((log, index) => {
                  if (
                    log.logEndTime >= selectedTimezoneDate ||
                    log.logStartTime <
                    selectedTimezoneDate + dayToMillis
                  ) {
                    const parentWidth = 100;

                    return (
                      <div
                        key={index}
                        class='planner_hos_log1'
                        style={{
                          position: "absolute",
                          left:
                            log.logStartTime >= selectedTimezoneDate
                              ? ((log.logStartTime - selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                          width: `${(((log.logEndTime <
                            selectedTimezoneDate + dayToMillis
                            ? log.logEndTime
                            : selectedTimezoneDate + dayToMillis) -
                            (log.logStartTime >= selectedTimezoneDate
                              ? log.logStartTime
                              : selectedTimezoneDate)) /
                            dayToMillis) *
                            100
                            }%`,
                          display:
                            (((log.logEndTime <
                              selectedTimezoneDate + dayToMillis
                              ? log.logEndTime
                              : selectedTimezoneDate + dayToMillis) -
                              (log.logStartTime >= selectedTimezoneDate
                                ? log.logStartTime
                                : selectedTimezoneDate)) /
                              dayToMillis) *
                              100 >
                              0
                              ? "block"
                              : "none",
                          backgroundColor: '#545b71'
                        }}
                      ></div>
                    )
                  }
                  return null
                }
                )}

              {
                driver?.ScheduleOverrideList.map((scheduleData) => (
                  scheduleData?.override?.length > 0 ?
                    (scheduleData?.override?.map((schedule, index) => {
                      if (driver?.ScheduleOverrideList.length > 0) {
                        return (
                          <div
                            key={index}
                            class='planner_hos_log2'
                            style={{
                              position: "absolute",
                              left: schedule.start_time >= 60
                                ? ((schedule.start_time * 60 * 1000) /
                                  dayToMillis) *
                                100 +
                                "%"
                                : "0%",
                              width: schedule.block_type === "Available" ? (((schedule.duration) * 60 * 1000) /
                                dayToMillis) *
                                100 + "%" : ((schedule.duration * 60 * 1000) /
                                  dayToMillis) *
                                100 + "%",
                              display: "block",
                              backgroundColor: `${schedule.block_type === "Available" ? '#BDEFD4' : '#E2ADB0'}`

                            }}
                          ></div>)
                      }

                      return null;
                    })) : (driver?.ScheduleOverrideList.map((schedule, index) => {
                      if (driver?.ScheduleOverrideList.length > 0) {
                        return (
                          <div
                            key={index}
                            class='planner_hos_log2'
                            style={{
                              position: "absolute",
                              left: schedule.start_time >= 60
                                ? ((schedule.start_time * 60 * 1000) /
                                  dayToMillis) *
                                100 +
                                "%"
                                : "0%",
                              width: ((schedule.duration * 60 * 1000) /
                                dayToMillis) *
                                100 + "%",
                              display: "block",
                              backgroundColor: `${schedule.block_type === "Available" ? '#BDEFD4' : '#E2ADB0'}`

                            }}
                          ></div>)
                      }
                      return null;
                    }))
                ))
              }

              <div className='planner_tick_container'>
                {dragset.map((it2, index) => (
                  <div
                    key={index}
                    onDrop={(e) => {
                      setTimeout(() => {
                        const divlist = document.getElementsByClassName('planner_dragable_inner');
                        for (let j = 0; j < divlist.length; j++) {
                          if (divlist[j].classList.contains('pointerevent')) {
                            divlist[j].classList.remove('pointerevent');
                          }
                        }

                      }, 10);
                      let dragData
                      if (Object.keys(context.dragData)?.length == 0) {
                        dragData = JSON.parse(localStorage.getItem('draggedData'))
                      } else {
                        dragData = context.dragData
                      }
                      if (["movements", "Shift"].includes(dragData?.dragFrom) && driver.driverId !== dragData?.dragDataItem?.driverId) {
                        context.setDragData({});
                        localStorage.removeItem("draggedData");
                        setTimeout(() => {
                          const divlist = document.getElementsByClassName('planner_dragable_inner');
                          for (let j = 0; j < divlist.length; j++) {
                            divlist[j].classList.remove('pointerevent');
                          }

                        }, 10);
                        return;
                      }

                      if (!(dragData?.dragFrom === "orders" && (dragData?.plannedOrUnplanned ? dragData?.plannedOrUnplanned == 'left' : context.plannedOrUnplanned === "left"))) {
                        handleLoadingBarOnDriver(driver.driverId, true);
                      }

                      // driver.isloading = true;
                      if (dragData?.dragFrom == ("movements" || "Shift"))
                        driverState.filter(
                          (dri) =>
                            dri.driverId ===
                            dragData?.dragDataItem.driverId
                        )[0].isloading = true;
                      e.preventDefault();
                      e.stopPropagation();
                      context.setDropData({
                        drop_to: "planner_tick_container",
                        driver_id: driver.driverId,
                        index: index,
                      });

                      setTimeout(() => {
                        const divlist = document.getElementsByClassName('planner_dragable_inner');
                        for (let j = 0; j < divlist.length; j++) {
                          divlist[j].classList.remove('pointerevent');
                        }

                      }, 10);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    className='planner_dragable_inner'
                  ></div>
                ))}
              </div>

              <div class='planner_dragable_main_block'>
                {driver?.shifts
                  ?.filter(
                    (shift) =>
                      shift.shiftEndTime >= selectedTimezoneDate ||
                      shift.shiftStartTime <
                      selectedTimezoneDate + dayToMillis
                  )
                  .map((shift, index) => (
                    <div
                      key={index}
                      class={
                        shift.shiftType === "start" && shift.movementData.pickup.location.code === "LAYOVER"
                          ? "planner_shift_color"
                          : shift.shiftType === "end" && shift.movementData.delivery.location.code === "LAYOVER"
                            ? "planner_shift_color"
                            : "planner_shift"
                      } onContextMenu={(e) => {
                        handleRightClickOnShifts(
                          e,
                          shift, driver
                        );
                        setSelectedDriverOnMovement(driver);
                      }}
                      style={{
                        position: "absolute",
                        left:
                          shift.shiftStartTime >= selectedTimezoneDate
                            ? ((shift.shiftStartTime -
                              selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((shift.shiftEndTime <
                          selectedTimezoneDate + dayToMillis
                          ? shift.shiftEndTime
                          : selectedTimezoneDate + dayToMillis) -
                          (shift.shiftStartTime >=
                            selectedTimezoneDate
                            ? shift.shiftStartTime
                            : selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((shift.shiftEndTime <
                            selectedTimezoneDate + dayToMillis
                            ? shift.shiftEndTime
                            : selectedTimezoneDate + dayToMillis) -
                            (shift.shiftStartTime >=
                              selectedTimezoneDate
                              ? shift.shiftStartTime
                              : selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                        border: `${shift.shiftType == 'start' && shift?.movementData?.pickup?.actual_departure_utc !== 0 ? "3px solid #FFFFFF" : shift.shiftType == 'end' && shift?.movementData?.delivery?.actual_departure_utc !== 0 ? "3px solid #FFFFFF" : "1px solid #444444"}`,
                      }}
                      draggable
                      onDrop={(e) => {
                        if (context.dragData?.dragFrom == "Shift" &&
                          shift?.movementData?.delivery?.stop_type == "ES") {
                          handleLoadingBarOnDriver(driver.driverId, true);
                          e.preventDefault();
                          e.stopPropagation();
                          context.setDropData({
                            drop_to: "Shift",
                            driver_id: driver.driverId,
                            movement: shift,
                          });
                        }
                      }}
                      onDragStart={(e) => {
                        setTimeout(() => {
                          const divlist = document.getElementsByClassName('planner_dragable_inner');
                          for (let j = 0; j < divlist.length; j++) {
                            divlist[j].classList.add('pointerevent');
                          }
                        }, 10);

                        context.setDragData({
                          dragFrom: "Shift",
                          dragDataItem: {
                            movement: shift,
                            driverId: driver.driverId,
                          },
                        });
                      }}
                      onDragEnd={(e) => {
                        setTimeout(() => {
                          const divlist = document.getElementsByClassName('planner_dragable_inner');
                          for (let j = 0; j < divlist.length; j++) {
                            if (divlist[j].classList.contains('pointerevent')) {
                              divlist[j].classList.remove('pointerevent');
                            }
                          }

                        }, 10);
                      }
                      }
                      onDragOver={(e) => e.preventDefault()}
                    >

                      {shift.shiftType == "start" ||
                        shift.shiftType == "end" ? (
                        <PlannerBoardTooltip
                          tooltip={
                            <ShiftToolTip it={shift} />
                          }
                        >
                          <div className="tooltip_row_block">
                            {shift.shiftType == "start" ? "SS" : "ES"}
                          </div>
                        </PlannerBoardTooltip>


                      ) : (
                        ""
                      )}
                      {/* These buttons will be shown after right clicking on shift, stops and movs based on conditions */}
                      <div className="audit_log_modal">
                        <StyledMenu
                          id='customized-menu'
                          anchorEl={showButtons}
                          // className='audit_log_modal'
                          open={Boolean(showButtons)}
                          onClose={handlerightClose4}
                        >{
                            showButtonLoader ? <CircularProgress /> :
                              <ListItemText>

                                {/* Audit log will be visible for every stops and movs on right clicking on them*/}
                                <Button
                                  className='btn-blue_schedule margin_right'
                                  onClick={(i) => auditLogModalOpenHandler()}
                                  disabled={!isEditPermission}
                                >
                                  Audit Log
                                </Button>
                                {/* Edit Actuals will be visible on clicking on Start and End shift */}
                                {editActualSSandES ? (<Button
                                  className='btn-blue_schedule margin_right'
                                  onClick={(i) =>
                                    actualeditModal(
                                      i, actualeditdatamodal
                                    )
                                  }
                                  disabled={!isEditPermission}
                                >
                                  Edit Stops
                                </Button>) : <></>}
                                {
                                  showEmptyMoveButton ? (<Button
                                    className='btn-blue_schedule margin_right'
                                    style={stop_and_movs_buttons}
                                    onClick={() => movementModalOpenforSS()}
                                    disabled={!isEditPermission}>
                                    Add Empty Movement
                                  </Button>
                                  ) : <></>
                                }
                                {/* This button will be shown on right clicking on every stops except Start and End Shift */}
                                {stopClickFlag ?
                                  <Button
                                    className='btn-blue_schedule margin_right'
                                    style={stop_and_movs_buttons}
                                    onClick={(i) =>
                                      Stopsmodal_open(
                                        i,
                                        actualeditstopsdata,
                                      )
                                    }
                                    disabled={!isEditPermission}
                                  >
                                    Edit Stops
                                  </Button> : <></>
                                }

                                {showReturnBtn?.isVisible ?
                                  <Button
                                    className='btn-blue_schedule margin_right'
                                    style={stop_and_movs_buttons}
                                    onClick={(i) => returnBtnHandler(i)}
                                    disabled={!isEditPermission}
                                  >
                                    Return
                                  </Button> : <></>
                                }


                                {/* This button will be shown on right clicking on every movement*/}

                                {/* button authorize and unauthorize  */}
                                {showAuthorizeButton ? (
                                  <Button
                                    className='btn-blue_schedule margin_right'
                                    onClick={() => authorizeButtonHandler()}
                                    disabled={!isEditPermission}
                                  >
                                    {authButtonData.movementData.authorized ? "Unauthorize Move" : "Authorize Move"}
                                  </Button>
                                ) : null}

                                {
                                  changeMoveTypeOnEmptyMov ?
                                    (
                                      <Button
                                        className='btn-blue_schedule margin_right'
                                        onClick={(i) =>
                                          Movementformodaldata(
                                            i, terminalname
                                          )
                                        }
                                        disabled={!isEditPermission}
                                      >
                                        Change Move Type
                                      </Button>
                                    ) : <></>
                                }
                                {
                                  changeMoveTypeOnBobtailMov ? (
                                    <Button
                                      className='btn-blue_schedule margin_right'
                                      onClick={(i) =>
                                        Movementformodaldata(
                                          i, terminalname
                                        )
                                      }
                                      disabled={!isEditPermission}
                                    >
                                      Change Move Type
                                    </Button>
                                  ) : <></>
                                }
                                {/* This button will be shown on right clicking on DT if next stop don't have actuals */}
                                {
                                  destChangeDHandDT ?
                                    (<Button
                                      className='btn-blue_schedule margin_right'
                                      style={stop_and_movs_buttons}
                                      onClick={() => destinationviewModalopen()}
                                      disabled={!isEditPermission}
                                    >
                                      Change Destination
                                    </Button>) : <></>
                                }
                                {
                                  addEmptyMovement ?
                                    (<Button
                                      className='btn-blue_schedule margin_right'
                                      style={stop_and_movs_buttons}
                                      onClick={() => movementModalOpenforDT()}
                                      disabled={!isEditPermission}
                                    >
                                      Add Empty Movement
                                    </Button>) : <></>
                                }
                                {
                                  isSplitButton ?
                                    <>
                                      {(selectedTimezoneDate >= (currentDate - 24 * 60 * 60 * 1000)) ? (
                                        <Button
                                          className='btn-blue_schedule'
                                          onClick={() =>
                                            Blockmodalopen(
                                              terminalname,
                                              driverIddropdown,
                                              movementlocationname
                                            )
                                          }
                                          disabled={!isEditPermission}
                                        >
                                          Split
                                        </Button>
                                      ) : <></>}
                                    </> : <></>
                                }
                                {
                                  unsplitModaldel ?
                                    (<Button
                                      className='btn-blue_schedule margin_right'
                                      onClick={() => unsplitdelhandler()}
                                      disabled={!isEditPermission}
                                    >
                                      Unsplit
                                    </Button>) : <></>
                                }
                                {
                                  unsplitModalpu ?
                                    (<Button
                                      className='btn-blue_schedule margin_right'
                                      onClick={() => unsplitpuhandler()}
                                      disabled={!isEditPermission}
                                    >
                                      Unsplit
                                    </Button>) : <></>
                                }
                              </ListItemText>
                          }
                        </StyledMenu>
                      </div>
                    </div>
                  ))}

                <div class='planner_dragable_inner_main_block'>
                  <div class='planner_draggable_main_block_row'>
                    {driver?.orders
                      ?.map((order, index) => {
                        if (
                          order.orderEndTime >= selectedTimezoneDate ||
                          order.orderStartTime <
                          selectedTimezoneDate + dayToMillis
                        ) {
                          let next
                          if (index < driver?.orders.length - 1) {
                            next = driver?.orders[index + 1];
                          }
                          let endTime
                          if (next != undefined) {
                            endTime = Math.min(order?.orderEndTime, next?.orderStartTime)
                          }
                          else {
                            endTime = order?.orderEndTime
                          }
                          return (

                            <div
                              key={index}
                              style={{
                                ...order.style,
                                position: "absolute",
                                left:
                                  order.orderStartTime >=
                                    selectedTimezoneDate
                                    ? ((order.orderStartTime -
                                      selectedTimezoneDate) /
                                      dayToMillis) *
                                    100 +
                                    "%"
                                    : "0%",
                                width: `${(((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (order.orderStartTime >=
                                    selectedTimezoneDate
                                    ? order.orderStartTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100
                                  }%`,
                                display:
                                  (((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (order.orderStartTime >=
                                      selectedTimezoneDate
                                      ? order.orderStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100 >
                                    0
                                    ? "block"
                                    : "none",
                                pointerEvents: (context.dragData ? "all" : context.dragData.dragFrom != "Shift" ? "all" : "")
                              }}
                              onContextMenu={(e) => handleRightClickorderRow(e, order, driver)}
                              className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                            >
                              {
                                <div>
                                  <StyledMenu
                                    id='customized-menu'
                                    anchorEl={showCommentsOfBillingDept}
                                    open={Boolean(showCommentsOfBillingDept)}
                                    onClose={handleCloseorder}
                                  >
                                    <ListItemText>
                                      <Button
                                        className='btn-blue_schedule margin_right'
                                        onClick={(e) => {
                                          handleOpenBillingComments(order);
                                        }}
                                        disabled={false}
                                      >
                                        Show Comment's
                                      </Button>
                                    </ListItemText>
                                  </StyledMenu>
                                </div>
                              }
                              <PlannerBoardTooltip
                                tooltip={<OrderTooltip it={driver?.movements?.find(o => o.orderId2 === order.orderId && o.type == "L") ?? driver?.movements?.find(o => o.orderId2 === order.orderId)} />}
                              >
                                <div className='tooltip_row_block'>
                                  {order.orderId}
                                </div>
                              </PlannerBoardTooltip>
                              {(order.mvmtType === "PU" || "SP") && order.actual_arrival_utc === 0 &&
                                order.actual_departure_utc === 0 && order.actual_arrival_utc1 === 0 &&
                                order.actual_departure_utc1 === 0 ? (
                                <div className='close_planning_outer'>
                                  <div
                                    className='close_planning orderidfix'
                                    style={driver.isloading ? { pointerEvents: "none" } : {}}
                                    onClick={(i) => {
                                      if (isEditPermission) {
                                        orderremoveModal(i, driver, order)
                                      }
                                      else {
                                        return toast.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
                                      }
                                    }
                                    }
                                  />
                                </div>
                              ) : (
                                <>

                                </>
                              )}
                            </div>
                          );
                        }
                        return null
                      })}


                  </div>

                  <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                    {
                      // driver.driverId === "4879" ?
                      renderTrailer(driver.movements)
                        ?.map((trailer, index) => {
                          if (
                            trailer.trailerEndTime >
                            selectedTimezoneDate ||
                            trailer.trailerStartTime <
                            selectedTimezoneDate + dayToMillis
                          ) {
                            let next
                            if (index < driver?.trailers.length - 1) {
                              next = driver?.trailers[index + 1];
                            }
                            let endTime
                            if (next != undefined) {
                              endTime = Math.min(trailer?.trailerEndTime, next?.trailerEndTime)
                            }
                            else {
                              endTime = trailer?.trailerEndTime
                            }
                            return (
                              <div
                                key={index}
                                style={{
                                  position: "absolute",
                                  left:
                                    trailer.trailerStartTime >=
                                      selectedTimezoneDate
                                      ? ((trailer.trailerStartTime -
                                        selectedTimezoneDate) /
                                        dayToMillis) *
                                      100 +
                                      "%"
                                      : "0%",
                                  width: `${(((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (trailer.trailerStartTime >=
                                      selectedTimezoneDate
                                      ? trailer.trailerStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100
                                    }%`,
                                  display:
                                    (((endTime <
                                      selectedTimezoneDate + dayToMillis
                                      ? endTime
                                      : selectedTimezoneDate +
                                      dayToMillis) -
                                      (trailer.trailerStartTime >=
                                        selectedTimezoneDate
                                        ? trailer.trailerStartTime
                                        : selectedTimezoneDate)) /
                                      dayToMillis) *
                                      100 >
                                      0
                                      ? "block"
                                      : "none",
                                  pointerEvents: (context.dragData.dragFrom != "Shift" ? "all" : "")
                                }}
                                onContextMenu={(e) => handleRightClickTrailerRow(e, driver, trailer)}
                                className='planner_draggable_main_block_row_block planner_draggable_main_block_middle_row_block'
                                onDrop={(e) => {
                                  if (context.dragData.dragFrom !== "Shift") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleLoadingBarOnDriver(driver.driverId, true);

                                    context.setDropData({
                                      drop_to: "trailer_row",
                                      driver_id: driver.driverId,
                                      trailer: trailer,
                                    });
                                  }
                                }}
                                onDragOver={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                {
                                  <div>
                                    <StyledMenu
                                      id='customized-menu'
                                      anchorEl={isFindTrailerButtonVisible}
                                      open={Boolean(isFindTrailerButtonVisible)}
                                      onClose={handleCloseOrderRow}
                                    >
                                      <ListItemText>
                                        <Button
                                          className='btn-blue_schedule margin_right'
                                          onClick={(e) => {
                                            handleFindTrailers();
                                          }}
                                          disabled={false}
                                        >
                                          Find Trailers
                                        </Button>
                                      </ListItemText>
                                    </StyledMenu>
                                  </div>
                                }
                                <PlannerBoardTooltip
                                  tooltip={<TrailerPBToolTip it={trailer} />}
                                >
                                  <div className='tooltip_row_block'>
                                    {trailer?.trailer_id}
                                  </div>
                                </PlannerBoardTooltip>
                                <div>
                                  {checkForRemoveTrailer(driver, trailer) && (
                                    <div className='close_planning_outer'>
                                      {isEditPermission ? (
                                        <div
                                          className='close_planning orderidfix'
                                          style={driver.isloading ? { pointerEvents: "none" } : {}}
                                          onClick={() => {
                                            handleOpenDialogTrailer(trailer, driver.driverId, driver);
                                          }}
                                        />
                                      ) : (
                                        <div
                                          className='close_planning orderidfix'
                                          onClick={() => {
                                            toast.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000);
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );

                          }
                          return null

                        })
                      // : ""
                    }

                    {
                      driver?.newEmptyMoveByPlanner?.map((movement, index) => {
                        if (
                          movement.endTime >= selectedTimezoneDate ||
                          movement.startTime <
                          selectedTimezoneDate + dayToMillis
                        ) {
                          let next
                          if (index < driver?.newEmptyMoveByPlanner.length - 1) {
                            next = driver?.newEmptyMoveByPlanner[index + 1];
                          }
                          let endTime
                          if (next != undefined) {
                            endTime = Math.min(movement?.endTime, next?.startTime)
                          }
                          else {
                            endTime = movement?.endTime
                          }
                          return <div
                            key={index}
                            style={{
                              position: "absolute",
                              left: movement.startTime >=
                                selectedTimezoneDate
                                ? ((movement.startTime -
                                  selectedTimezoneDate) /
                                  dayToMillis) *
                                100 +
                                "%"
                                : "0%",
                              width: `${(((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (movement.startTime >=
                                  selectedTimezoneDate
                                  ? movement.startTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100
                                }%`,
                              display:
                                (((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (movement.startTime >=
                                    selectedTimezoneDate
                                    ? movement.startTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100 >
                                  0
                                  ? "block"
                                  : "none",
                            }}
                            className='planner_draggable_main_block_row_block_cross planner_draggable_main_block_top_row_block_cross'
                          >

                            <div className='close_planning_outer'>
                              <div
                                className='close_planning orderidfix'
                                style={driver.isloading ? { pointerEvents: "none" } : {}}
                                onClick={(i) => {
                                  if (isEditPermission) {
                                    setdriverdetails(driver);
                                    setExtramoveDetails(movement);
                                    setExtraMoveModal(true);
                                  }
                                  else {
                                    return toast.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
                                  }
                                }
                                }
                              />
                            </div>
                          </div>

                        }
                        return null

                      })
                    }
                  </div>

                  <div class='planner_draggable_main_block_row'>
                    {driver?.movements

                      ?.map((movement, index) => {
                        if (
                          movement.endTime >= selectedTimezoneDate ||
                          movement.startTime <
                          selectedTimezoneDate + dayToMillis
                        ) {
                          let next
                          if (index < driver?.movements.length - 1) {
                            next = driver?.movements[index + 1];
                          }
                          let endTime
                          if (next != undefined) {
                            endTime = Math.min(movement?.endTime, next?.startTime)
                          }
                          else {
                            endTime = movement?.endTime
                          }
                          return (
                            <div
                              key={index}
                              style={{
                                ...movement.style,
                                position: "absolute",
                                left:
                                  movement.startTime >=
                                    selectedTimezoneDate
                                    ? ((movement.startTime -
                                      selectedTimezoneDate) /
                                      dayToMillis) *
                                    100 +
                                    "%"
                                    : "0%",
                                width: `${(((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (movement.startTime >=
                                    selectedTimezoneDate
                                    ? movement.startTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100
                                  }%`,
                                border: `${['L', 'E', 'BT'].includes(movement.type) && movement?.movementData?.delivery?.actual_arrival_utc !== 0 ? "3px solid #FFFFFF" :
                                  ['DEL', 'PU', 'SP', 'SD', 'DT', 'DH', 'HT', 'RET'].includes(movement.type) && movement?.movementData?.pickup?.actual_departure_utc !== 0 ? "3px solid #FFFFFF"
                                    : "0px solid #444444"}`,
                                display:
                                  (((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (movement.startTime >=
                                      selectedTimezoneDate
                                      ? movement.startTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100 >
                                    0
                                    ? "block"
                                    : "none",
                              }}
                              className='planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block'
                              aria-controls='customized-menu'
                              aria-haspopup='true'
                              variant='contained'
                              color='primary'
                              draggable
                              onContextMenu={(e) => {
                                let order_id = movement.orderId;
                                let order = driver?.orders?.filter(
                                  (order) => {
                                    return order.orderId === order_id;
                                  }
                                )[0];
                                handleRightClickOnMovements(
                                  e,
                                  movement.type,
                                  driver.driverId,
                                  order,
                                  driver,
                                  movement
                                );
                                setSelectedDriverOnMovement(driver);
                                setterminalname(movement);
                                setmovementlocationName(movement);
                                setactualeditmovedata(movement);
                              }}
                              onClick={(e) =>
                                impactviewclick(
                                  e,
                                  movement.type,
                                  movement
                                )
                              }
                              onDragStart={(e) => {
                                handleDragStart();
                                context.setDragData((prevDragData) => {
                                  const updatedDragData = {
                                    ...prevDragData,
                                    dragFrom: "movements",
                                    dragDataItem: {
                                      movement: movement,
                                      driverId: driver.driverId,
                                    },
                                  };

                                  return updatedDragData;
                                });

                                setTimeout(() => {
                                  const divlist = document.getElementsByClassName('planner_dragable_inner');
                                  for (let j = 0; j < divlist.length; j++) {
                                    divlist[j].classList.add('pointerevent');
                                  }
                                }, 10);

                              }}
                              onDragEnd={(e) => {
                                setTimeout(() => {
                                  const divlist = document.getElementsByClassName('planner_dragable_inner');
                                  for (let j = 0; j < divlist.length; j++) {
                                    if (divlist[j].classList.contains('pointerevent')) {
                                      divlist[j].classList.remove('pointerevent');
                                    }
                                  }
                                }, 10);
                                handleDragEnd();
                                context.setDragData((prevDragData) => {
                                  const updatedDragData = {
                                    ...prevDragData,
                                    dragFrom: "movements",
                                    dragDataItem: {
                                      movement: movement,
                                      driverId: driver.driverId,
                                    },
                                  };

                                  return updatedDragData;
                                });
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onDrop={(e) => {
                                if ((
                                  context.dragData?.dragFrom == "movements" &&
                                  movement.type == "SP" &&
                                  context.dragData?.dragDataItem?.movement?.type == "PU"
                                ) || (
                                    context.dragData?.dragFrom == "movements" &&
                                    movement.type == "SD" &&
                                    context.dragData?.dragDataItem?.movement?.type == "DEL"
                                  )) {
                                  // driver.isloading = true;
                                  handleLoadingBarOnDriver(driver.driverId, true);
                                  driverState.filter(
                                    (dri) =>
                                      dri.driverId ===
                                      context.dragData.dragDataItem.driverId
                                  )[0].isloading = true;
                                  e.preventDefault();
                                  e.stopPropagation();
                                  context.setDropData({
                                    drop_to: "movements",
                                    driver_id: driver.driverId,
                                    movement: movement,
                                  });
                                }
                              }}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {movement.type === "L" ||
                                movement.type === "E" || movement.type === "BT" ? (
                                <PlannerBoardTooltip
                                  tooltip={
                                    <Order2PBToolTip it={movement} />
                                  }
                                >
                                  <div className='tooltip_row_block'>
                                    {movement.type}
                                  </div>
                                </PlannerBoardTooltip>
                              ) : (
                                ""
                              )}

                              {movement.type === "DEL" ||
                                movement.type === "DT" ||
                                movement.type === "DH" ||
                                movement.type === "HT" ||
                                movement.type === "RET" ||
                                movement.type === "SD" ||
                                movement.type === "PU" ||
                                movement.type === "SP" ? (
                                renderTooltip(movement)
                              ) : (
                                null
                              )
                              }
                            </div>
                          );
                        }
                        return null

                      })}
                  </div>
                </div>
              </div>

            </div>
            <div class='planner_pm_section forum-icon-container'>
              <SendIcon color={isEditPermission ? "primary" : 'disabled'} className="planner_right_send_icon" onClick={() => sendToXmit(driver)} />
              <div>
                <div className={driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && "planner_message_alert"} >
                  {
                    driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length
                  }
                </div>
                <ForumIcon className="planner_right_message_icon" onClick={() => { driverChatModalHandler(driver) }} />
              </div>
            </div>
          </div>
          <div style={{ fontSize: "11px", paddingLeft: "100px" }}>{driver?.driver_name}</div>
        </>
      )
    })));
  }

  // Driver DOM
  useEffect(() => {
    driverDomUpdateFunction()
  }, [driverState, selectedTimezoneDate, actualeditdatamodal, durationsettime, terminalname, driverIddropdown, driverIddropdown, movementlocationname, context.dragData, showButtons, isFindTrailerButtonVisible, showCommentsOfBillingDept, showButtonLoader
  ]);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
    }
  }, [driverMessage, driverChatMessageOpen]);

  const handleCloseEditMovement = () => {
    setMOvementsEditModal(false);
    setChangeMoveTypeOnEmptyMov(null);
    setChangeMoveTypeOnBobtailMov(null);
    setdestinationViewModal(false);
    setmovementformodal(false);
    setChangemovetypeconfirmbtn(false)
  };

  const convertdatetime1 = (duration) => {
    if (duration) {
      const inputDate = DateTime.fromISO(duration, { zone: plannerTimezone });
      const formattedDate = inputDate.toFormat('yyyy-MM-dd hh:mm ZZZZ');

      return formattedDate
    } else {
      return "No Data";
    }
  };

  const handleTractorChange = (value) => {
    setSelectedTractor(value);
  };



  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div key={refreshComponent ? "main_planner_wrapper_refresh" : "main_planner_wrapper"}>
        <div class='planner_main_container_outer'>
          <div className='planner_am_section'>HRS</div>
          <div className='planner_main_container'>
            {hourArr.map((item, key) => (
              <div key={key} className='planner_main_inner_container'>
                <div className='hrs_wrapper'>
                  <p className='hrs_wrapper_time_text'>
                    {item == "0"
                      ? `12  A.M`
                      : item == "12"
                        ? `12  P.M`
                        : item > 11
                          ? `${item - 12}  P.M`
                          : `${item}  A.M`}
                  </p>
                  <div className='planner_day_time_inner'>
                    <div className='vl_plannertankwash'></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class='planner_pm_section'>HRS</div>
        </div>
        <div className='scroll_plannerboard_inner'>
          {driverDOM}
        </div>

        <div className="pagination_adjust_driver">
          {driverData.length >
            0 ? (
            <Stack spacing={2}>
              <Pagination
                onChange={handeDriverViewPagination}
                count={Math.ceil(
                  allDrivers?.length / 25
                )}
                page={context.driverPageNumber}
                color='primary'
              />
            </Stack>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* modal will open if api resonse is warning */}

      {
        warningModalOpen ? warningModalOpen : <></>
      }

      {/* actualedit modal */}
      < Modal
        show={actualeditData}
        onHide={ss_es_modalclosehandelar}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Edit Stops</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{!actualbutton ?
          <>
            <label for="typeHeadCommodityGroup">
              Actual Arrival
            </label>
            <div className="meterial_autocomplete">

              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  slotProps={{
                    textField: {
                      error: false,
                      fullWidth: true,
                      value: showValueOfActualsArrive ? selectedactualDate : null
                    },
                    actionBar: { actions: ['clear', "accept"] }
                  }}
                  orientation="landscape"
                  value={selectedactualDate}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onChange={handleactualDateChange}
                />
              </LocalizationProvider>
            </div>
            <label for="typeHeadCommodityGroup">
              Actual Depart
            </label>
            <div className="meterial_autocomplete">
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true,
                        value: showValueOfActualsDepart ? selecteddepartDate : null
                      },
                      actionBar: { actions: ['clear', "accept"] }
                    }}
                    orientation="landscape"
                    value={selecteddepartDate}
                    disabled={checkTrailerFlag}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    onChange={handledepartDateChange}
                  />
                  {checkTrailerFlag && (
                    <div className="error-message" style={{ fontSize: "12px", color: "red" }}>
                      You cannot enter the Actual Departure without assigning a Trailer
                    </div>)}
                </>
              </LocalizationProvider>
            </div>
          </> : <>To edit the actuals of this stop, the actuals of previous stops must be filled in.</>}</Modal.Body>
        <Modal.Footer>
          <Button onClick={ss_es_modalclosehandelar}>Close</Button>
          {
            !editstopconfirmbtn ?
              <Button
                onClick={(i) =>
                  saveDate()
                }
                disabled={editStopChangeflag}
              > Save
              </Button> :
              <div className="spinner_modal_footer_md">
                <CircularProgress />
              </div>
          }

        </Modal.Footer>
      </Modal>
      {/* Modal for authorize and unauthorize button */}
      {showAuthorizeButton ? (<Modal show={showAuthorizedModal} onHide={() => handleCloseAuthorizeMovement()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>
              Are you sure?</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          Are you sure to set the move from <b>{authButtonData.movementData.authorized ? "Authorize" : "Unauthorize"}</b> to <b>{authButtonData.movementData.authorized ? "Unauthorize" : "Authorize"}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAuthorizeMovement}>Close</Button>
          <Button variant="primary" onClick={handleOk}>Confirm</Button>
        </Modal.Footer>
      </Modal>) : null}

      <Modal
        show={stopsviewmodal}
        onHide={() => stopclosehandeler()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Edit Stops</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            loadunloadflag ?
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Load Unload</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={['Y', 'N', 'TL', 'NB']}
                    getOptionLabel={(option) => option}
                    value={selectedOption}
                    disabled={selectedMovementData.movementData.pickup.actual_arrival_utc > 0 || selectedMovementData.movementData.trailer_id !== null}
                    onChange={(e, option) => { setSelectedOption(option); setEditStopChangeFlag(false) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Change Load Unload Flag"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div> : <></>
          }
          {
            stopDurationChange ?
              <>
                <label for="typeHeadCommodityGroup">
                  Stop Duration
                </label>
                <div className="meterial_autocomplete">
                  <TextField
                    id="input_time"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={stopDurationChangeValue}
                    onChange={(e) => {
                      setStopDurationChangeValue(e.target.value);
                      setEditStopChangeFlag(false)
                    }
                    }
                    disabled={selectedMovementData.movementData.pickup.actual_arrival_utc > 0 || selectedMovementData.movementData.pickup.actual_departure_utc > 0}
                  />
                </div>
              </> : <></>
          }
          {
            editActualsExceptSSandES ? <>
              <label for="typeHeadCommodityGroup">
                Actual Arrival
              </label>
              <div className="meterial_autocomplete">
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true,
                        value: showValueOfActualsArrive ? actualarrival : null
                      },
                      actionBar: { actions: ['clear', "accept"] }
                    }}
                    orientation="landscape"
                    value={actualarrival}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    onChange={handleactual_stops_DateChange}
                  />
                </LocalizationProvider>
              </div>
              <label for="typeHeadCommodityGroup">
                Actual Depart
              </label>
              <div className="meterial_autocomplete">
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <>
                    <DateTimePicker
                      slotProps={{
                        textField: {
                          error: false,
                          fullWidth: true,
                          value: showValueOfActualsDepart ? actualdepart : null
                        },
                        actionBar: { actions: ['clear', "accept"] }
                      }}
                      orientation="landscape"
                      value={actualdepart}
                      disabled={checkTrailerFlag}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      onChange={handledepart_stops_DateChange}
                    />
                    {checkTrailerFlag && (
                      <div className="error-message" style={{ fontSize: "12px", color: "red" }}>
                        You cannot enter the Actual Departure without assigning a Trailer
                      </div>)}
                  </>
                </LocalizationProvider>
              </div>
            </> : <></>
          }
          {
            !loadunloadflag && !editActualsExceptSSandES ?
              <div style={{ fontSize: "16px" }}>To edit the actuals of this stop, the actuals of previous stops must be filled in.</div> : <></>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => stopclosehandeler()}>Close</Button>
          {
            !editstopconfirmbtn ?
              <Button
                onClick={(i) =>
                  stop_ChangeClick()
                }
                disabled={editStopChangeflag}
              >
                Save
              </Button> :
              <div className="spinner_modal_footer_md">
                <CircularProgress />
              </div>
          }
        </Modal.Footer>
      </Modal>
      {/* //end */}

      {/* Change movement modal for E */}
      <Modal
        show={movementformodal}
        onHide={() => handleCloseEditMovement()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Change Movement Type</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          <p>Do You Want To Change The Movement Type From {`${movementChangeTypeText}`}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleCloseEditMovement()}>Close</Button>
          {
            !changemovetypeconfirmbtn ?
              <Button
                onClick={(i) =>
                  movement_E_ChangeClick()
                }
                disabled={!isEditPermission}
              >
                Confirm
              </Button> :
              <div className="spinner_modal_footer">
                <CircularProgress />
              </div>
          }
        </Modal.Footer>
      </Modal>
      {/* //end */}

      {/* /// ORDER MODAL OPEN CLICK ON CLOSE ICON/// */}
      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        show={orderremovemodal}
        onHide={() => { setorderremovemodal(false); setdeletmovconfirmbtn(false) }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Confirmation
            {/* <h5 style={{ paddingTop: "5px" }}>Are you sure want to delete movement ?</h5> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to unschedule the order?</Modal.Body>
        <Modal.Footer>
          <Button

            onClick={() => { setorderremovemodal(false); setdeletmovconfirmbtn(false) }}
          >
            Close
          </Button>
          {!deletmovconfirmbtn ?
            <Button onClick={(i) =>
              removeOrder()
            }
              disabled={!isEditPermission}
            > Unschedule
            </Button> :
            <div className="spinner_modal_footer">
              <CircularProgress />
            </div>
          }
        </Modal.Footer>
      </Modal>
      {/* //// END/// */}

      {/* /// Extra Move MODAL OPEN CLICK ON CLOSE ICON/// */}
      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        show={extraMoveModal}
        onHide={() => { setExtraMoveModal(false); setdeletmovconfirmbtn(false) }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You want To Delete this Move?</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => { setExtraMoveModal(false); setdeletmovconfirmbtn(false) }}
          >
            Close
          </Button>
          {!deletmovconfirmbtn ?
            <Button onClick={(i) =>
              extraMovedelete()
            }
              disabled={!isEditPermission}
            > Delete
            </Button> :
            <div className="spinner_modal_footer">
              <CircularProgress />
            </div>
          }
        </Modal.Footer>
      </Modal>

      {/* ///  Destination modal for location*/}

      <Modal
        show={destinationViewModal}
        onHide={() => {
          setdestinationViewModal(false);
          resetMapRelatedData();
        }}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Change Destination</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value="5" selected>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className={`location_section ${location?.label === "nearbysearch" ? "location_identifier" : ""}`}>
                    {
                      routeData?.movementData?.pickup?.lm_location_id === location.code ? <div className="split_pickup_location_icon mt_8"></div> :
                        routeData?.movementData?.delivery?.lm_location_id === location.code ? <div className="split_delivery_location_icon mt_8"></div> : <></>
                    }
                    <div className='location_section_left' style={routeData?.movementData?.pickup?.lm_location_id !== location.code && routeData?.movementData?.delivery?.lm_location_id !== location.code ? { marginLeft: "25px" } : {}}>
                      <div className="split_nearby_location_container">
                        <div>
                          <div className='location_name'>
                            {`${location?.code ? location?.code + '-' : ''}`}{location?.location_name}
                            <br />
                            {location.address1}
                            <br />
                            {location.city},{location.state},{location.zip}
                          </div>
                          <div className='location_name'>
                            Distance: {location.distance} miles
                          </div>
                          <div className='location_section_right'></div>
                        </div>
                        <button
                          type='button'
                          class='btn-blue_schedule split_button'
                          onClick={(i) => destinationchangeClick(location)}
                          disabled={changedestconfirmbtn}
                        >
                          {location?.label == 'nearbysearch' ? 'Save Address & Select' : 'Select'}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}></div>

            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for location</p>
              <div className="split_pickup_location" style={{ marginTop: "50px" }}>
                <div className="split_pickup_location_icon"></div>
                <div>Pickup</div>
              </div>
              <div className='location_name ml_20 split_pickup_delivery_label'>
                {routeData?.movementData?.pickup?.lm_location_id}-{routeData?.movementData?.pickup?.lm_location_name}
                <br />
                {routeData?.movementData?.pickup?.address}
                <br />
                {routeData?.movementData?.pickup?.city},{routeData?.movementData?.pickup?.state},{routeData?.movementData?.pickup?.zip}
              </div>
              <div className="split_pickup_location">
                <div className="split_delivery_location_icon"></div>
                <div>Delivery</div>
              </div>
              <div className='location_name ml_20 split_pickup_delivery_label'>
                {routeData?.movementData?.delivery?.lm_location_id}-{routeData?.movementData?.delivery?.lm_location_name}
                <br />
                {routeData?.movementData?.delivery?.address}
                <br />
                {routeData?.movementData?.delivery?.city},{routeData?.movementData?.delivery?.state},{routeData?.movementData?.delivery?.zip}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setdestinationViewModal(false);
              resetMapRelatedData();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isAddMovTrimbleVisible}
        onHide={() => handleAddMovementViewModal()}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Add Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className={`location_section ${location?.label === "nearbysearch" ? "location_identifier" : ""}`}>
                    <div className='location_section_left'>
                      <div className='location_name'>
                        {`${location?.code ? location?.code + '-' : ''}`}{location?.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance: {location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      <button
                        type='button'
                        class='btn-blue_schedule'
                        onClick={(i) =>
                          addmovementchangeClick(
                            i,
                            terminalname,
                            destinationadd,
                            location,
                            selectedDriverData
                          )
                        }
                      >
                        {location?.label == 'nearbysearch' ? 'Save Address & Select' : 'Select'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}>
              </div>
            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for location</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => handleAddMovementViewModal()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //end */}
      {/* Movement Dialog Box */}
      {openMovementDialog && (
        <Modal
          size='sm'
          aria-labelledby='contained-modal-title-vcenter'
          show={openMovementDialog}
          onHide={() => setOpenMovementDialog(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Confirmation
              {/* <h5 style={{ paddingTop: "5px" }}>Are you sure want to delete movement ?</h5> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete movement ?</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => setOpenMovementDialog(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => handleCloseDialogMovement("save")} disabled={!isEditPermission}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Trailer Dialog Box */}
      {openTrailerDialog && (
        <Modal
          size="md"
          aria-labelledby='contained-modal-title-vcenter'
          show={openTrailerDialog}
          onHide={() => setOpenTrailerDialog(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Remove Trailer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
            <p>Do You Want To Remove The Trailer {`${removeTrailerBlock?.trailer_id}`} From Order {`${removeTrailerBlock?.order_id2}`}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={(e) => setOpenTrailerDialog(false)}
            >
              {" "}
              Cancel
            </Button>
            {
              !removeTrailerconfirmbtn ?
                <Button onClick={(i) =>
                  removetrailerfrommovement()
                }
                  disabled={!isEditPermission}
                > Unassign
                </Button> :
                <div className="spinner_modal_footer">
                  <CircularProgress />
                </div>
            }
          </Modal.Footer>
        </Modal>
      )}


      {driverChatMessageOpen &&
        <Modal
          //  size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={driverChatMessageOpen}
          onHide={() => {
            setDriverChatMessageOpen(false);
            resetURLAfterClosingMessageModal();
          }}
          centered
          className="driver_chat_modal"
        >

          <Modal.Header closeButton className="message-modal-header">
            <Modal.Title id='contained-modal-title-vcenter' >{`${driverToSendMessage.driverId}-${driverToSendMessage?.driver_full_name.trim()}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body ref={modalBodyRef} style={{height:"400px"}}>

            {
              driverMessage?.data?.length > 0 &&
              driverMessage?.data
                ?.sort((a, b) => DateTime.fromISO(a.sent_ts) - DateTime.fromISO(b.sent_ts))
                .map((item) => {
                  let isBPA = item.source === 'BPA';
                  return (
                    <div className={isBPA ? 'chat-right' : 'chat-left'} key={item.id}>
                      <div className="chip-container sentmsg_date">
                        <Chip label={item.message_text} className="label1" />
                        <Chip label={`${item.sender_name ?? ''} ${convertdatetime1(item.sent_ts)}`} className="sentmsg_date" />
                      </div>
                    </div>
                  );
                })
            }

          </Modal.Body>
          <Modal.Footer>
            <div className="message_footer">
              <TextField
                variant="outlined"
                placeholder="Type a message"
                value={driverEnteredMessage}
                onChange={(e) => { setDriverEnteredMessage(e.target.value) }}
                onKeyDown={(e)=>e.key === 'Enter' && driverEnteredMessage && sendDriverChatMessageHandler()}
              />
              {driverEnteredMessage ? (
                <Button variant="contained" className="sendbutton" onClick={() => { sendDriverChatMessageHandler() }} >
                  Send
                </Button>
              ) : (
                <Button variant="contained" className="sendbutton" disabled>
                  Send
                </Button>
              )

              }

            </div>
          </Modal.Footer>
        </Modal>
      }

      {openXmitDialog && (
        <Modal
          size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={openXmitDialog}
          onHide={() => { setOpenXmitDialog(false); setXmitOrdersData([]); setSelectedTractor('') }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Confirm Action
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div style={{ marginBottom: "20px", fontSize: "18px" }}>You are about to send the selected orders for the designated driver.</div>

            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Choose a tractor*</label>
              <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={tractorData.map(tractor => tractor.tractor_id)}
                  onChange={(e, option) => handleTractorChange(option)}
                  value={selectedTractor}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a tractor"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>

            {
              xmitOrdersData?.length > 0 && xmitOrdersData.map((el) => {
                return (<div className="xmit_driver">
                  <div>
                    <Checkbox checked={el.checked} onChange={(e) => handleChangeCheckbox(el)} inputProps={{ 'aria-label': 'controlled' }} />
                  </div>
                  <div>{`${formatDate(el?.startTime, el.pickuptz)}   -   ${formatDate(el?.endTime, el.deliverytz)}`}
                  </div>
                </div>)
              })
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => { setOpenXmitDialog(false); setXmitOrdersData([]); setSelectedTractor('') }}
            >
              Cancel
            </Button>
            <Button onClick={() => handleCloseDialogXmit("send", xmitDriver)} disabled={!isEditPermission}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}


      <div className='split_view_modal'>
        <Modal
          className='split_view_modal'
          show={locationViewModal}
          onHide={() => {
            setIsSplitButton(false)
            setlocationViewModal(false);
            resetMapRelatedData();
          }}
          dialogClassName="modal-width"
          // size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='tank_mapview_header'>Map View</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='split_view_container'>
              <div className='split_view_left'>
                <div className='search_section'>
                  <div className='search_field_tankwash'>
                    <form class='example'>
                      <input
                        type='text'
                        placeholder='Search..'
                        name='search2'
                        onChange={(e) => handleSearchInput(e)}
                        onKeyUp={handleKeyPress}
                      />
                      <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                        <i class='fa fa-search'></i>
                      </button>
                    </form>
                  </div>
                  <div className='split_popup_dropdown'>
                    <form>
                      <select
                        name='cars'
                        id='cars'
                        value={selectedDistance}
                        onChange={(e) => filterLocationByDist(e)}
                      >
                        <option value='5'>
                          5
                        </option>
                        {miles.map((mile, k) => (
                          <option key={k}>{mile}</option>
                        ))}
                      </select>
                    </form>
                  </div>
                </div>
                <p className='nearby_text'>
                  Nearby Locations:{locationsDataFromMap.length}
                </p>
                <div className='location_section_wrapper'>
                  {isLocationLoading ? (
                    <Stack spacing={1}>
                      <div className='loader_skeleton'>
                        <Skeleton variant='rectangular' />
                      </div>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  {locationsDataFromMap.map((location, index) => (
                    <div className={`location_section ${location?.label === "nearbysearch" ? "location_identifier" : ""}`}>
                      {
                        routeData?.movementData?.pickup?.lm_location_id === location?.code ? <div className="split_pickup_location_icon mt_8"></div> :
                          routeData?.movementData?.delivery?.lm_location_id === location?.code ? <div className="split_delivery_location_icon mt_8"></div> : <></>
                      }
                      <div className='location_section_left' style={routeData?.movementData?.pickup?.lm_location_id !== location?.code && routeData?.movementData?.delivery?.lm_location_id !== location?.code ? { marginLeft: "25px" } : {}}>
                        <div className="split_nearby_location_container">
                          <div>
                            <div className='location_name'>
                              {`${location?.code ? location?.code + '-' : ''}`}{location?.location_name}
                              <br />
                              {location.address1}
                              <br />
                              {location.city},{location.state},{location.zip}
                            </div>
                            <div className='location_name'>
                              Distance: {location.distance} miles
                            </div>
                            <div className='location_section_right'></div>
                          </div>
                          <button
                            type='button'
                            class='btn-blue_schedule split_button'
                            disabled={splitButtonClick}
                            onClick={(e) =>
                              splitviewclick(
                                location
                              )
                            }
                          >
                            {location?.label == 'nearbysearch' ? 'Save Address & Split' : 'Split'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='split_view_right_map'>
                <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}></div>
              </div>
              <div className="split_view_right_map__guide">
                <p>To do a split on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for split</p>
                <div>
                  <div className="split_pickup_location" style={{ marginTop: "50px" }}>
                    <div className="split_pickup_location_icon"></div>
                    <div>{routeData?.movementData?.pickup?.stop_type == 'PU' ? 'Pickup' : 'Split Pickup'}</div>
                    {routeData?.movementData?.pickup?.stop_type == 'PU' ?
                      <button class='btn-blue_schedule split_pickup_delivery_btn' disabled={splitButtonClick} onClick={() => splitviewclick({ code: routeData?.movementData?.pickup?.lm_location_id })}>Split</button>
                      : <></>}
                  </div>
                  <div className='location_name ml_20 split_pickup_delivery_label'>
                    {routeData?.movementData?.pickup?.lm_location_id}-{routeData?.movementData?.pickup?.lm_location_name}
                    <br />
                    {routeData?.movementData?.pickup?.address}
                    <br />
                    {routeData?.movementData?.pickup?.city},{routeData?.movementData?.pickup?.state},{routeData?.movementData?.pickup?.zip}
                  </div>
                  <div className="split_pickup_location">
                    <div className="split_delivery_location_icon"></div>
                    <div>{routeData?.movementData?.delivery?.stop_type == 'DEL' ? 'Delivery' : 'Split Delivery'}</div>
                    {routeData?.movementData?.delivery?.stop_type == 'DEL' ?
                      <button class='btn-blue_schedule split_pickup_delivery_btn' disabled={splitButtonClick} onClick={() => splitviewclick({ code: routeData?.movementData?.delivery?.lm_location_id })}>Split</button>
                      : <></>}
                  </div>
                  <div className='location_name ml_20 split_pickup_delivery_label'>
                    {routeData?.movementData?.delivery?.lm_location_id}-{routeData?.movementData?.delivery?.lm_location_name}
                    <br />
                    {routeData?.movementData?.delivery?.address}
                    <br />
                    {routeData?.movementData?.delivery?.city},{routeData?.movementData?.delivery?.state},{routeData?.movementData?.delivery?.zip}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => {
              setlocationViewModal(false);
              setIsSplitButton(false)
              resetMapRelatedData();
            }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ImpactviewModal Kendo Table */}
        <Modal
          show={impactViewModal}
          onHide={() => setImpactViewModal(false)}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Impact View
            </Modal.Title>
          </Modal.Header>
          {dataLoaded ? (
            <Modal.Body>
              <Grid
                filter={dataState.filter}
                filterable={true}
                sort={dataState.sort}
                sortable={true}
                pageable={{
                  buttonCount: 10,
                  info: true,
                  previousNext: true,
                  pageSizes: true,
                }}
                resizable={true}
                skip={dataState.skip}
                take={dataState.take}
                data={dataResult}
                onDataStateChange={dataStateChange}
              >
                {columnorder?.slice(1)?.map((it) => (
                  <GridColumn
                    key={it.id}
                    field={it.name}
                    title={it.title}
                    width={it.width}
                  />
                ))}

              </Grid>

            </Modal.Body>
          ) : (
            <div className="progress_impact">
              <ProgressBar animated now={100} />
              <div className='middle loader--text1'> </div>
            </div>
          )}
          <Modal.Footer>
            <Button onClick={(e) => setImpactViewModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
        {/* end  */}

        {/* Audit log compponent */}
        <AuditLogModalComponent ref={auditLogComponentRef} />

        {/* Audit Log Modal and Kendo Table End*/}

        {/* SPLIT TERMINAL VIEW MODAL  */}
        <Modal
          show={splitviewmodal}
          onHide={() => setsplitviewmodal(false)}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='textcolorchange'>WARNING!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Assign to another Terminal?
            <div className='meterial_autocomplete'>
              <label for='exampleFormControlInput1'>Select Terminal:</label>
              <Autocomplete
                id='combo-box-demo'
                options={terminalData}
                getOptionLabel={(option) => `${option.terminal_id} - ${option.name}`}
                onChange={terminalIdHandler}
                defaultValue={defaultterminal[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Choose a Terminal...'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              !splitConfirmBtn ?
                <Button
                  disabled={!isEditPermission}
                  onClick={(e) =>
                    splitAssignDriverviewclick()
                  }
                >
                  Confirm
                </Button> :
                <div className="spinner_modal_footer">
                  <CircularProgress />
                </div>
            }
          </Modal.Footer>
        </Modal>
        {/* END */}

        {/* MODAL FOR DRIVER DROPDOWN VIEW */}
        <Modal
          show={driverviewmodal}
          onHide={() => setdriverviewmodal(false)}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='textcolorchange'>WARNING!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Assign to another Driver?
            <div className='meterial_autocomplete'>
              <label for='exampleFormControlInput1'>Select Driver:</label>
              <Autocomplete
                id='combo-box-demo'
                options={driverdropdownData}
                getOptionLabel={(option) =>
                  `${option.driver_id} - ${option.driverfullName}`
                }
                onChange={driverIdHandler}
                defaultValue={defaultdriver[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Choose a Driver...'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {/* This modal will be opened for adding empty/bobtail after clicking on driver icon. */}
        <Modal
          show={modalShow}
          onHide={onModalClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          anchorEl={addMovements}
          open={Boolean(addMovements)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Movement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label for="typeHeadCommodityGroup">
              Movement Type *
            </label>
            <div className="meterial_autocomplete">
              <Autocomplete
                id="combo-box-demo"
                options={movementType}
                getOptionLabel={(option) => `${option?.movement}`}
                // getOptionSelected={(option, value) => option.movement === value.movement}
                onChange={handleMovetype}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a movement type..."
                    variant="outlined"
                    required={true}
                  />
                )}
              />
            </div>
            {selectedMoveType?.Type === "E" ?
              <>
                <label for="typeHeadCommodityGroup">
                  Trailer
                </label>
                <div className="meterial_autocomplete">
                  <Autocomplete
                    id="combo-box-demo"
                    options={allTrailers}
                    disabled={selectedMoveType?.Type == "BT" ? true : false}
                    getOptionLabel={(option) => `${option?.Trailer_ID}`}
                    onChange={trailerHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose a Trailer..."
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </>
              : ""
            }
            <label for="typeHeadCommodityGroup">
              Driver
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={selectedDriverData?.driverId}
                disabled={true}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Origin
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={selectedDriverData?.terminal?.terminal_fullName}
                disabled={true}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Destination
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={`${movementforlocmap?.code}-${movementforlocmap?.location_name}`}
                required={true}
                disabled={true}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Start Time
            </label>
            <div className="meterial_autocomplete">
              <form noValidate>
                <TextField
                  id="input_time"
                  type="datetime-local"
                  fullWidth
                  value={startTime}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
            {!addmovconfirmbtn ?
              <Button
                onClick={() => createStops()}
                disabled={!isEditPermission && !isTrailerHistoryNeeded}
              >
                Create
              </Button> : <div className="spinner_modal_footer">
                <CircularProgress />
              </div>
            }
          </Modal.Footer>
        </Modal>
      </div>


      <Modal
        show={isDropTrailerTrimbleVisible || isDropTrailerTrimbleVisibleSS}
        onHide={() => handleAddMovementViewModalDropTrailer()}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Add New Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className={`location_section ${location?.label === "nearbysearch" ? "location_identifier" : ""}`}>
                    <div className='location_section_left'>
                      <div className="split_nearby_location_container">
                        <div>
                          <div className='location_name'>
                            {`${location?.code ? location?.code + '-' : ''}`}{location?.location_name}
                            <br />
                            {location.address1}
                            <br />
                            {location.city},{location.state},{location.zip}
                          </div>
                          <div className='location_name'>
                            Distance: {location.distance} miles
                          </div>
                          <div className='location_section_right'></div>
                        </div>
                        <button
                          type='button'
                          class='btn-blue_schedule split_button'
                          onClick={(i) =>
                            openDroptrailerModal(location)
                          }
                        >
                          {location?.label == 'nearbysearch' ? 'Save Address & Select' : 'Select'}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}>

              </div>

            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the add the empty move along the route. Then pick a location from the list for location</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleAddMovementViewModalDropTrailer()}>Close</Button>
        </Modal.Footer>
      </Modal>


      {/* For Drop Trailer location change */}
      <Modal
        show={modalShowDropTrailer}
        onHide={onModalCloseDropTrailer}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Movement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="typeHeadCommodityGroup">
            Movement Type *
          </label>
          <div className="meterial_autocomplete">
            <Autocomplete
              id="combo-box-demo"
              options={movementType}
              getOptionLabel={(option) => `${option?.movement}`}
              onChange={handleMovetype}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose a movement type..."
                  variant="outlined"
                  required={true}
                />
              )}
            />
          </div>
          {selectedMoveType?.Type === "E" ?
            <>
              <label for="typeHeadCommodityGroup">
                Trailer
              </label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={allTrailers}
                  getOptionLabel={(option) => `${option?.Trailer_ID}`}
                  onChange={handleDropTrailerDropTrailer}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a Trailer..."
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </> : ""}

          <label for="typeHeadCommodityGroup">
            Driver
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={true}
              fullWidth
              defaultValue={selectedDriverOnMovement?.driverId}
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Origin
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={true}
              fullWidth
              defaultValue={
                dropTrailerData?.type === "SS"
                  ? (
                    dropTrailerData?.lm_location_name && dropTrailerData?.city
                      ? `${dropTrailerData.lm_location_name}-${dropTrailerData.city}`
                      : dropTrailerData?.city
                  )
                  : (
                    dropTrailerData?.movementData?.pickup?.location_name && dropTrailerData?.movementData?.pickup?.city
                      ? `${dropTrailerData.movementData.pickup.location_name}-${dropTrailerData.movementData.pickup.city}`
                      : dropTrailerData?.movementData?.pickup?.city
                  )
              }
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Destination
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              defaultValue={`${movementforlocmapForDropTrailer?.code}-${movementforlocmapForDropTrailer?.location_name}`}
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Start Time
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                value={startTimeDropTrailer}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
          <label for="typeHeadCommodityGroup">
            End Time
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                value={EndTimeDropTrailer}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            !addmovconfirmbtn ?
              <Button
                onClick={() => dropTrailerHandler()}
                disabled={!isEditPermission}
              >
                Create
              </Button> : <div className="spinner_modal_footer">
                <CircularProgress />
              </div>
          }
        </Modal.Footer>
      </Modal>

      {/* eta */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            ETA
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label htmlFor='tankwashcode'>driver id</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter driver id'
              size='small'
              value={driverid}
              onChange={handledriveridChange}
              disabled
            />
          </div>
          <label htmlFor='tankwashcode'>latitude</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter latitude'
              size='small'
              onChange={handlelatitudeChange}
            />
          </div>
          <label htmlFor='tankwashcode'>longitude</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter longitude'
              size='small'
              onChange={handleLongitudeChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button type='submit' onClick={updateETAdata} >Save</Button>

        </Modal.Footer>
      </Modal>


      {
        <Modal
          //  size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={commentOfBilligDeptOpen}
          onHide={handleCloseorderComments}
          centered
          className="driver_chat_modal"
        >

          <Modal.Header closeButton className="message-modal-header">
            <Modal.Title id='contained-modal-title-vcenter' >Order - {`${selectedOrder ? selectedOrder?.orderId : ""}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "250px", overflowY: "auto" }}>

            {
              commentsData?.length > 0 ?
                commentsData?.sort((a, b) => DateTime.fromISO(a?.created_on) - DateTime.fromISO(b?.created_on))
                  .map((item) => {
                    return (
                      <div className='chat-left' key={item.id}>
                        <div className="chip-container sentmsg_date">
                          <Chip label={item?.messsage} className="label1" />
                          <Chip label={`${item?.created_by} | ${convertdatetime1(item?.created_on)}`} className="sentmsg_date" />
                        </div>
                      </div>
                    );
                  }) : <div style={{ textAlign: "center", color: "grey", marginTop: "90px", fontSize: "18px" }}>No Comment's To Show</div>
            }

          </Modal.Body>
          <Modal.Footer>
            <div className="message_footer">
              <TextField
                variant="outlined"
                placeholder="Type a message"
                value={commentsMssg}
                onChange={(e) => setcommentsMsg(e.target.value)}

              />

              <Button variant="contained" className="sendbutton" disabled={!commentsMssg} onClick={() => handlecommentsubmit()} >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      }

<Modal
        show={openDriverLocationModal}
        onHide={() => {setDriverLocationModal(false);setDriverLocationCord(null)}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Driver CURRENT LOCATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="map" style={{ height: "400px", width: "100%", }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => {setDriverLocationModal(false);setDriverLocationCord(null)}}>Close</Button>
        </Modal.Footer>
      </Modal>

      {
        showReturnModal?.isVisible ? (
          <TrimbleMapsForReturn
            showReturnModal={showReturnModal}
            setShowReturnModal={setShowReturnModal}
            setShowReturnBtn={setShowReturnBtn}
            selectedDriverData={selectedDriverData}
            selectedMovementData={selectedMovementData}
            driverCurrentLocation={driverCurrentLocation}
            errorMessageDisplay={errorMessageDisplay}
            setWarningModalOpen={setWarningModalOpen}
            handleLoadingBarOnDriver={handleLoadingBarOnDriver}
            returnActualArrival={returnActualArrival}
            setReturnActualArrival={setReturnActualArrival}
            selectedTimezoneDate={selectedTimezoneDate}
            selectedPlanningProfile={selectedPlanningProfile}
          />
        ) : ("")
      }
    </>
  );
};

export default PlannerBoardDriversection;
